<style>
  table thead tr th {
    font-weight: 500 !important;
  }
</style>
<div class="row">
  <div class="col-md-10 offset-md-1">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <h5 style="float: left;padding: .75rem 1.25rem">Job Location Configuration
          <span style="font-size: 12px;" *ngIf="jobLocationConfigurationList.length > 0">(Total
            {{jobLocationConfigurationList.length}} States)</span>
        </h5>
        <button (click)="OpenPopupForCreate()" style="float: right; margin-top: 5px;" type="button"
          class="btn btn-custom mr-1">
          <i class="fa fa-plus"></i> Add New </button>
      </div>
      <div>
        <table class="table table-striped">
          <thead class="custom-table-header-color">
            <tr>
              <th>#.</th>
              <th style="width: 14%;">State</th>
              <th>Code</th>
              <th>Image</th>
              <th>Status</th>
              <th>Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="jobLocationConfigurationList.length > 0; else elseBlock">
              <tr *ngFor="let d of jobLocationConfigurationList; index as i">
                <td><b>{{ i +1}}</b></td>
                <td>
                  <p>{{ d.state}}</p>
                </td>
                <td>
                  <p>{{ d.code }}</p>
                </td>
                <td>
                  <p>
                    <ng-container *ngIf="d.image">
                      <img src="{{ d.image }}" width="100" height="100" />
                    </ng-container>
                  </p>
                </td>
                <td>
                  <p class="text-bold"
                    [ngClass]="{ 'text-green': d.status == jobLocationConfigurationFileStatus.Active, 'text-danger': d.status == jobLocationConfigurationFileStatus.Disabled }">
                    {{ d.status == jobLocationConfigurationFileStatus.Active? 'Active' : 'Disabled' }}</p>
                </td>
                <td>
                  <p>{{ d.created | date : 'MMM dd yyyy hh:mm a' }}</p>
                </td>
                <td width="10%">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-sliders"></i>
                    </button>
                    <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <button class="dropdown-item" (click)="ChangeStatus(d.status,d.id)">Change Status to {{ d.status
                        == jobLocationConfigurationFileStatus.Active? 'Disabled' : 'Active' }}</button>
                      <button class="dropdown-item" (click)="Delete(i,d.id)">Delete</button>
                      <button class="dropdown-item" (click)="OpenPopup(d.id,d.state,d.code,d.image)">Edit</button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #elseBlock>
              <tr>
                <td colspan="10"></td>
              </tr>
              <tr>
                <td colspan="10" class="text-center">No Record Found......</td>
              </tr>
              <tr>
                <td colspan="10"></td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div [ngStyle]="{'display':displayStyle}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Edit Job Location Configuration</h5>
        <button (click)="ClosePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <label for="">State</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter State Name" type="text" [(ngModel)]="updateModel.state"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Code</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Code Number" type="text" [(ngModel)]="updateModel.code"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Image</label>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-8">
                <input (change)="onFileChanged($event)" accept="image/*" autocomplete="off" placeholder="Enter Image"
                  type="file" class="form-control" />
              </div>
              <div class="col-md-4">
                <ng-container *ngIf="updateModel.image">
                  <img src="{{ updateModel.image }}" width="50" height="50" />
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="Update()" type="button" class="btn btn-custom">Update</button>
      </div>
    </div>
  </div>
</div>

<div [ngStyle]="{'display':displayStyle2}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add Job Location Configuration</h5>
        <button (click)="ClosePopupForCreate()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <label for="">State</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter State Name" type="text" [(ngModel)]="createModel.state"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Code</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Code" type="text" [(ngModel)]="createModel.code"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Image</label>
          </div>
          <div class="col-md-6">
            <input (change)="onFileChanged($event)" accept="image/*" autocomplete="off" placeholder="Enter Image"
              type="file" class="form-control" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="Add()" type="button" class="btn btn-custom">Add</button>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
