import { Component, Input, OnInit } from '@angular/core';
import { EmployeePayrollHourReport } from 'src/app/model/employeepayrollhours.model';

@Component({
  selector: 'app-payrollemployeehourlist',
  templateUrl: './payrollemployeehourlist.component.html',
  styleUrls: ['./payrollemployeehourlist.component.css']
})
export class PayrollemployeehourlistComponent implements OnInit {

  @Input() employeePayrollHoursLists: EmployeePayrollHourReport[];

 @Input() employeeTotalFullDays = 0;
 @Input() employeeTotalFullDayHoliday = 0;
 @Input() employeeTotalRegular = 0;
 @Input() employeeTotalRegularHoliday = 0;
 @Input() employeeTotalRegularHolidayOT = 0;
 @Input() employeeTotalOT = 0;
 @Input() employeeStartDate = '';
 @Input() employeeEndDate = '';

  constructor() {
    // this.GetData();
  }
  ngOnInit(): void {

  }
  // GetData(): void {
  //   if (this.employeePayrollHoursLists !== undefined && this.employeePayrollHoursLists.length > 0) {
  //     this.employeePayrollHoursLists.forEach(ele => {
  //       this.employeeTotalFullDays = this.employeeTotalFullDays + (ele.totalFullDay + ele.totalFullDayHoliday);
  //       this.employeeTotalRegular = this.employeeTotalRegular + (ele.totalRegular + ele.totalHoliday);
  //       this.employeeTotalOT = this.employeeTotalOT + ele.totalOT;
  //       this.employeeStartDate = ele.startDate;
  //       this.employeeEndDate = ele.endDate;
  //     });
  //   }
  // }
  Round(num): number {
    return Math.round(num);
  }

}
