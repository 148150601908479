import { DOCUMENT, formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { EmployeeFileStatus } from 'src/app/enum/employeefilestatus.enum';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { EmployeeAdpFileConfiguration } from '../../model/employeeadpfileconfiguration.model';

@Component({
  selector: 'app-employeefileconfiguration',
  templateUrl: './employeefileconfiguration.component.html',
  styleUrls: ['./employeefileconfiguration.component.css']
})
export class EmployeefileconfigurationComponent implements OnInit {

  employeeFileConfigurationList: EmployeeAdpFileConfiguration[] = [];
  BreakException = {};
  employeeFileStatus = EmployeeFileStatus;
  displayStyle = 'none';
  displayStyle2 = 'none';
  updateModel: { id: number, employeeName: string, fileNo: number, bonus: number, tempRate: number, rate1: number, rate2: number } = {
    id: 0,
    employeeName: '',
    fileNo: 0,
    bonus: null,
    tempRate: null,
    rate1: null,
    rate2: null
  };
  createModel: { employeeName: string, fileNo: number, bonus: number, tempRate: number, rate1: number, rate2: number } = {
    employeeName: '',
    fileNo: 0,
    bonus: 0,
    tempRate: 0,
    rate1: null,
    rate2: null
  };
  constructor(private auth: AuthService, private toastr: ToastrService, private spinner: SpinnerService,
    private repo: RepositoryService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(LOCALE_ID) private locale: string) { }


  ngOnInit(): void {
    this.GetAllEmployeeFileConfiguration();
  }

  OpenPopup(id: number, employeeName: string, fileNo: number, bonus: number, tempRate: number, rate1: number, rate2: number ): void {
    this.updateModel.employeeName = employeeName;
    this.updateModel.fileNo = fileNo;
    this.updateModel.bonus = bonus;
    this.updateModel.tempRate = tempRate;
    this.updateModel.rate1 = rate1;
    this.updateModel.rate2 = rate2;
    this.updateModel.id = id;
    this.displayStyle = 'block';
  }
  ClosePopup(): void {
    this.displayStyle = 'none';
  }

  OpenPopupForCreate(): void {
    this.displayStyle2 = 'block';
  }
  ClosePopupForCreate(): void {
    this.displayStyle2 = 'none';
  }

  GetAllEmployeeFileConfiguration(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/EmployeeFileConfiguration/GetAll')
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.employeeFileConfigurationList = data['data'];
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  Update(): void {
    if (this.updateModel.id === 0 || this.updateModel.employeeName === '' || this.updateModel.fileNo === 0 || this.updateModel.fileNo.toString() === '') {
      this.toastr.error('Employee Name and File No is required');
      return;
    }
    this.spinner.showSpinner();
    this.repo.update('api/EmployeeFileConfiguration/Update', this.updateModel.id, this.updateModel)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.employeeFileConfigurationList.forEach((ele, i) => {
              if (ele.id === this.updateModel.id) {
                ele.employeeName = this.updateModel.employeeName;
                ele.fileNo = this.updateModel.fileNo;
                ele.bonus = this.updateModel.bonus;
                ele.tempRate = this.updateModel.tempRate;
                ele.rate1 = this.updateModel.rate1;
                ele.rate2 = this.updateModel.rate2;
                this.toastr.success('Updated Successfully');
                this.ClosePopup();
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  AddNewEmployeeFile(): void {
    if (this.createModel.employeeName === '' || this.createModel.fileNo === 0 || this.createModel.fileNo.toString() === '' || this.createModel.fileNo.toString() === '0') {
      this.toastr.error('Employee Name and File No is required');
      return;
    }
    this.spinner.showSpinner();
    this.repo.create('api/EmployeeFileConfiguration/insert', this.createModel)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.employeeFileConfigurationList.push(data['data']);
            this.toastr.success('Added Successfully');
            this.createModel = { employeeName: '', fileNo: 0, bonus: 0, tempRate: 0, rate1: 0, rate2: 0 };
            this.ClosePopupForCreate();
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  onFileChanged(event): void {
    this.spinner.showSpinner();
    const selectedFile: File = (event.target.files[0] as File);
    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);
    this.repo.file('api/EmployeeFileConfiguration/ImportEmployeeFileNo', formData)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.employeeFileConfigurationList = data['data'];
            this.toastr.success(data['returnMessage'][0]);
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  Delete(index: number, id: number): void {
    this.spinner.showSpinner();
    this.repo.delete('api/EmployeeFileConfiguration/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.employeeFileConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.employeeFileConfigurationList.splice(index, 1);
                this.spinner.hideSpinner();
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  ChangeStatus(status: EmployeeFileStatus, id: number): void {
    this.spinner.showSpinner();
    this.repo.getData('api/EmployeeFileConfiguration/Disable', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.employeeFileConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.employeeFileConfigurationList[i].status = (status === this.employeeFileStatus.Disabled ?
                  this.employeeFileStatus.Active : this.employeeFileStatus.Disabled);
                this.toastr.success(data['returnMessage'][0]);
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
}
