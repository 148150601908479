import { DOCUMENT } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { StatusType } from 'src/app/enum/statustype.enum';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { JobLocationConfiguration } from '../../model/Joblocationconfiguration.model';

@Component({
  selector: 'app-joblocationconfiguration',
  templateUrl: './joblocationconfiguration.component.html',
  styleUrls: ['./joblocationconfiguration.component.css']
})
export class JoblocationconfigurationComponent implements OnInit {

  jobLocationConfigurationList: JobLocationConfiguration[] = [];
  BreakException = {};
  jobLocationConfigurationFileStatus = StatusType;
  displayStyle = 'none';
  displayStyle2 = 'none';
  selectedFile: File;
  updateModel: { id: number, state: string, code: string, image: string } = {
    id: 0,
    state: '',
    code: '',
    image: ''
  };
  createModel: { state: string, code: string } = {
    state: '',
    code: ''
  };
  constructor(private auth: AuthService, private toastr: ToastrService, private spinner: SpinnerService,
    private repo: RepositoryService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(LOCALE_ID) private locale: string) { }


  ngOnInit(): void {
    this.GetAllTravelJobLocationConfiguration();
  }

  OpenPopup(id: number, state: string, code: string, image: string): void {
    this.updateModel.image = image;
    this.updateModel.state = state;
    this.updateModel.code = code;
    this.updateModel.id = id;
    this.displayStyle = 'block';
  }
  ClosePopup(): void {
    this.displayStyle = 'none';
  }

  OpenPopupForCreate(): void {
    this.displayStyle2 = 'block';
  }
  ClosePopupForCreate(): void {
    this.displayStyle2 = 'none';
  }

  GetAllTravelJobLocationConfiguration(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/TravelJobLocationConfiguration/GetAll')
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.jobLocationConfigurationList = data['data'];
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  Update(): void {
    if (this.updateModel.id === 0 || this.updateModel.state === '' || this.updateModel.code === '') {
      this.toastr.error('State and Code is required');
      return;
    }
    if (this.selectedFile == undefined) {
      this.toastr.error('Please upload state image');
      return;
    }

    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    formData.append('state', this.updateModel.state);
    formData.append('code', this.updateModel.code);
    formData.append('id', this.updateModel.id.toString());

    this.spinner.showSpinner();
    this.repo.fileUpdate('api/TravelJobLocationConfiguration/Update', formData)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.jobLocationConfigurationList.forEach((ele, i) => {
              if (ele.id === this.updateModel.id) {
                ele.state = this.updateModel.state;
                ele.code = this.updateModel.code;
                ele.image = this.updateModel.image;
                this.toastr.success('Updated Successfully');
                this.ClosePopup();
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  onFileChanged(event): void {
    this.selectedFile = (event.target.files[0] as File);
  }
  Add(): void {
    if (this.createModel.state === '' || this.createModel.code === '') {
      this.toastr.error('State and Code is required');
      return;
    }
    if (this.selectedFile == undefined) {
      this.toastr.error('Please upload state image');
      return;
    }
    this.spinner.showSpinner();
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    formData.append('state', this.createModel.state);
    formData.append('code', this.createModel.code);

    this.repo.file('api/TravelJobLocationConfiguration/insert', formData)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.jobLocationConfigurationList.push(data['data']);
            this.toastr.success('Added Successfully');
            this.createModel = { state: '', code: '' };
            this.ClosePopupForCreate();
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }


  Delete(index: number, id: number): void {
    this.spinner.showSpinner();
    this.repo.delete('api/TravelJobLocationConfiguration/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.jobLocationConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.jobLocationConfigurationList.splice(index, 1);
                this.spinner.hideSpinner();
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  ChangeStatus(status: StatusType, id: number): void {
    this.spinner.showSpinner();
    this.repo.getData('api/TravelJobLocationConfiguration/Disable', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.jobLocationConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.jobLocationConfigurationList[i].status = (status === this.jobLocationConfigurationFileStatus.Disabled ?
                  this.jobLocationConfigurationFileStatus.Active : this.jobLocationConfigurationFileStatus.Disabled);
                this.toastr.success(data['returnMessage'][0]);
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
}
