<style>
  .modal-dialog{
    max-width: 600px !important;
  }
  table thead tr th {
    font-weight: 500 !important;
  }
</style>
<div class="row">
  <div class="col-md-12">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <h5 style="float: left;padding: .75rem 1.25rem">Event Configuration
          <span style="font-size: 12px;" *ngIf="eventConfigurationList.length > 0">(Total
            {{eventConfigurationList.length}} Configuration)</span>
        </h5>
        <button (click)="OpenPopupForCreate()" style="float: right; margin-top: 5px;" type="button"
          class="btn btn-custom mr-1">
          <i class="fa fa-plus"></i> Add New </button>
      </div>
      <div>
        <table class="table table-striped">
          <thead class="custom-table-header-color">
            <tr>
              <th>#.</th>
              <th style="width: 14%;">Event Date</th>
              <th>Type</th>
              <th>Place</th>
              <th>Location</th>
              <th>Status</th>
              <th>Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="eventConfigurationList.length > 0; else elseBlock">
              <tr *ngFor="let d of eventConfigurationList; index as i">
                <td><b>{{ i +1}}</b></td>
                <td>
                  <p>{{ d.date | date : 'MMM dd yyyy hh:mm a'}}</p>
                </td>
                <td>
                  <p>{{ getEventType(d.type) }}</p>
                </td>
                <td>
                  <p>{{ d.place }}</p>
                </td>
                <td>
                  <p>{{ d.location }}</p>
                </td>
                <td>
                  <p class="text-bold"
                    [ngClass]="{ 'text-green': d.status == eventStatus.Active, 'text-danger': d.status == eventStatus.Disabled }">
                    {{ d.status == eventStatus.Active? 'Active' : 'Disabled' }}</p>
                </td>
                <td>
                  <p>{{ d.created | date : 'MMM dd yyyy hh:mm a' }}</p>
                </td>
                <td width="10%">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-sliders"></i>
                    </button>
                    <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <button class="dropdown-item" (click)="ChangeStatus(d.status,d.id)">Change Status to {{ d.status
                        == eventStatus.Active? 'Disabled' : 'Active' }}</button>
                      <button class="dropdown-item" (click)="Delete(i,d.id)">Delete</button>
                      <button class="dropdown-item"
                        (click)="OpenPopup(d.id,d.date,d.place,d.location,d.type)">Edit</button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #elseBlock>
              <tr>
                <td colspan="10"></td>
              </tr>
              <tr>
                <td colspan="10" class="text-center">No Record Found......</td>
              </tr>
              <tr>
                <td colspan="10"></td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div [ngStyle]="{'display':displayStyle}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Edit Event Configuration</h5>
        <button (click)="ClosePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <label for="">Event Date</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Event Date" type="text"
              [(ngModel)]="updateModel.date" class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Place</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Place" type="text" [(ngModel)]="updateModel.place"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Location</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Location" type="text" min="1" [(ngModel)]="updateModel.location"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Event Type</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Type" type="number" min="1"
              [(ngModel)]="updateModel.type" class="form-control" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="Update()" type="button" class="btn btn-custom">Update</button>
      </div>
    </div>
  </div>
</div>

<div [ngStyle]="{'display':displayStyle2}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add Event Configuration</h5>
        <button (click)="ClosePopupForCreate()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-3">
            <label for="">Event Date</label>
          </div>
          <div class="col-md-9">
            <input id="datetimepicker1" autocomplete="off" placeholder="Enter Event Date" type="text"
              [(ngModel)]="createModel.date" class="form-control datetimepicker" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-3">
            <label for="">Place</label>
          </div>
          <div class="col-md-9">
            <input autocomplete="off" placeholder="Enter Place" type="text" [(ngModel)]="createModel.place"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-3">
            <label for="">Location</label>
          </div>
          <div class="col-md-9">
            <input autocomplete="off" placeholder="Enter Location" type="text" [(ngModel)]="createModel.location"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-3">
            <label for="">Event Type</label>
          </div>
          <div class="col-md-9">
            <select [(ngModel)]="createModel.type" class="form-control" >
              <option selected="selected">Select Event Type</option>
              <ng-container *ngFor="let enum of eventType | keyvalue">
              <option *ngIf="isEnumName(enum)">
                 {{ enum.value}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="AddNewEventConfiguration()" type="button" class="btn btn-custom">Add</button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
