import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StatusType } from 'src/app/enum/statustype.enum';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { ServiceConfiguration } from "src/app/model/serviceconfiguration.model";
import { first } from 'rxjs/operators';
import { DurationType } from 'src/app/enum/durationtype.enum';

@Component({
  selector: 'app-serviceconfiguration',
  templateUrl: './serviceconfiguration.component.html',
  styleUrls: ['./serviceconfiguration.component.css']
})

export class ServiceconfigurationComponent implements OnInit {

  serviceConfigurationList: ServiceConfiguration[] = [];
  BreakException = {};
  serviceStatus = StatusType;
  durationType = DurationType;
  displayStyle = 'none';
  displayStyle2 = 'none';
  updateModel: { id: number, category: string, name: string, price: number, durationType: DurationType, isMeridiem: boolean } = {
    id: 0,
    name: null,
    category: "",
    price: null,
    isMeridiem: false,
    durationType: DurationType.Fifteen
  };
  createModel: { name: string, category: string, price: number, durationType: DurationType, isMeridiem: boolean } = {
    name: null,
    category: "",
    price: null,
    isMeridiem: false,
    durationType: DurationType.Fifteen
  };
  constructor(private toastr: ToastrService, private spinner: SpinnerService, private repo: RepositoryService) { }

  ngOnInit(): void {
    this.GetAllServiceConfiguration();
  }

  OpenPopup(id: number, category: string, name: string, price: number, durationType: DurationType, isMeridiem: boolean): void {
    this.updateModel.name = name;
    this.updateModel.price = price;
    this.updateModel.category = category;
    this.updateModel.isMeridiem = isMeridiem;
    this.updateModel.durationType = durationType;
    this.updateModel.id = id;
    this.displayStyle = 'block';
  }
  ClosePopup(): void {
    this.displayStyle = 'none';
  }
  OpenPopupForCreate(): void {
    this.displayStyle2 = 'block';
  }
  ClosePopupForCreate(): void {
    this.displayStyle2 = 'none';
  }
  GetAllServiceConfiguration(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/ServiceConfiguration/GetAll', 0)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.serviceConfigurationList = data['data'];
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  Update(): void {
    if (this.updateModel.id === 0 || this.updateModel.category === null || this.updateModel.name === null || this.updateModel.price === 0) {
      this.toastr.error('All fields are required');
      return;
    }
    this.spinner.showSpinner();
    this.repo.update('api/ServiceConfiguration/Update', this.updateModel.id, this.updateModel)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.serviceConfigurationList.forEach((ele, i) => {
              if (ele.id === this.updateModel.id) {
                ele.name = this.updateModel.name;
                ele.price = this.updateModel.price;
                ele.category = this.updateModel.category;
                ele.durationType = this.updateModel.durationType;
                ele.isMeridiem = this.updateModel.isMeridiem;
                this.toastr.success('Updated Successfully');
                this.ClosePopup();
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  AddNewServiceConfiguration(): void {

    if (this.createModel.category === null || this.createModel.name === null || this.createModel.price === 0) {
      this.toastr.error('All fields are required');
      return;
    }
    this.spinner.showSpinner();
    this.repo.create('api/ServiceConfiguration/insert', this.createModel)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.serviceConfigurationList.push(data['data']);
            this.toastr.success('Added Successfully');
            this.createModel = { category: "", name: null, price: 0.0, durationType: DurationType.Fifteen, isMeridiem: false };
            this.ClosePopupForCreate();
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  Delete(index: number, id: number): void {
    this.spinner.showSpinner();
    this.repo.delete('api/ServiceConfiguration/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.serviceConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.serviceConfigurationList.splice(index, 1);
                this.spinner.hideSpinner();
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  ChangeStatus(status: StatusType, id: number): void {
    this.spinner.showSpinner();
    this.repo.getData('api/ServiceConfiguration/Disable', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.serviceConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.serviceConfigurationList[i].status = (status === this.serviceStatus.Disabled ?
                  this.serviceStatus.Active : this.serviceStatus.Disabled);
                this.toastr.success(data['returnMessage'][0]);
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  isEnumName(val, type: boolean) {
    if (type)
      return !isNaN(Number(val.key));
    return isNaN(Number(val.key));
  }
  GetActualPrice(price: number, durationType: DurationType) {
    switch (durationType) {
      case DurationType.Fifteen:
        return price / 4;
        break;
      case DurationType.Thirty:
        return price / 2;
        break;
      case DurationType.Sixty:
        return price * 1;
        break;
      case DurationType.Ninety:
        return price * 1.5;
        break;
      case DurationType.OneTwenty:
        return price * 2;
        break;

      default:
        break;
    }
  }
}
