import { DOCUMENT } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { CheckListConfigurationByCategory } from 'src/app/model/checklistconfiguration.model';
import { RepositoryService } from '../../shared/services/repository.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { ScrolltoviewService } from '../../shared/services/scrolltoview.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-aideevaluation',
  templateUrl: './aideevaluation.component.html',
  styleUrls: ['./aideevaluation.component.css'],
})
export class AideevaluationComponent implements OnInit {
  submitted = false;
  registerForm: FormGroup;
  checkListCategories: CheckListConfigurationByCategory[];
  evaluatedClients: any;
  evaluatedClient: any;
  constructor(public authService: AuthService, private formBuilder: FormBuilder, private toastr: ToastrService,
    private spinner: SpinnerService, private scroll: ScrolltoviewService, private repo: RepositoryService
  ) { }

  ngOnInit(): void {
    this.GetNewClientsEvaluatedByNurse();
    this.registerForm = this.formBuilder.group({
      homeClientProfileId: ['', [Validators.required]],
      UserId: [this.authService.GetUserId(), [Validators.required]],
      reason: ['', Validators.maxLength(200)],
      reasonType: [''],
      reasonMainType: [''],
      dressCode: [''],
      dressCodeComment: ['', Validators.maxLength(28)],
      punctuality: [''],
      punctualityComment: ['', Validators.maxLength(28)],
      reporting: [''],
      reportingComment: ['', Validators.maxLength(28)],
      rapport: [''],
      rapportComment: ['', Validators.maxLength(28)],
      bathing: [''],
      bathingComment: ['', Validators.maxLength(28)],
      transfer: [''],
      transferComment: ['', Validators.maxLength(28)],
      ambulation: [''],
      ambulationComment: ['', Validators.maxLength(28)],
      nutrition: [''],
      nutritionComment: ['', Validators.maxLength(28)],
      living: [''],
      livingComment: ['', Validators.maxLength(28)],
      safety: [''],
      safetyComment: ['', Validators.maxLength(28)],
      comment: ['', Validators.maxLength(100)],
      skillQ1: [''],
      skillQ2: [''],
      skillQ3: [''],
      skillQ4: [''],
      skillQ5: [''],
      skillQ6: [''],
      skillQ7: [''],
      skillQ8: [''],
      skillQ9: [''],
      skillQ10: [''],
      skillQ11: [''],
      skillQ12: [''],
      skillQ13: [''],
      skillQ14: [''],
      skillQ15: [''],
      skillQ16: [''],
      skillQ17: [''],
      skillQ18: [''],
      skillQ19: [''],
      skillQ20: [''],
      skillQ21: [''],
      skillQ22: [''],
      skillQ23: [''],
      skillQ24: [''],
      skillQOther: ['', Validators.maxLength(50)],
      evalutionQ1: [''],
      evalutionQ2: [''],
      evalutionQ3: [''],
      evalutionQ4: [''],
      evalutionQ5: ['']
    });
  }
  get f() {
    return this.registerForm.controls;
  }

  GetNewClientsEvaluatedByNurse(): void {
    this.spinner.showSpinner();
    this.repo
      .getData('api/InitialNursingClientEvaluation/GetAllClients')
      .subscribe({
        next: (res) => {
          if (res['returnStatus'] == true) {
            this.evaluatedClients = res['data'];
          }
          this.spinner.hideSpinner();
        },
        error: (err) => {
          this.spinner.hideSpinner();
        },
      });
  }
  GetNewClientDetailById(val: number): void {
    if (val == undefined) alert('Client is required');
    this.evaluatedClient = this.evaluatedClients.find((x) => x.id == val);
  }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.scroll.scrollToElement(document.getElementsByClassName('target')[0]);
      return;
    }
    this.GetResonTypeValues();
    this.spinner.showSpinner();
    this.repo
      .create('api/InitialClientAideEvaluation/Insert', this.registerForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data['returnStatus'] == true) {
            this.toastr.success(data['returnMessage'][0], 'Success');
            this.onReset();
          } else {
            this.toastr.error(data['returnMessage'][0], 'Error');
          }
          this.spinner.hideSpinner();
        },
        (error) => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        }
      );
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.registerForm.get('homeClientProfileId').setValue('');
    this.registerForm.get('UserId').setValue(this.authService.GetUserId());
    for (let index = 1; index <= 5; index++) {
      this.registerForm.get('evalutionQ' + index).setValue('');
    }
    for (let index = 1; index <= 24; index++) {
      this.registerForm.get('skillQ' + index).setValue('');
    }
    this.evaluatedClient = null;
    this.scroll.scrollToElement(document.getElementsByClassName('target')[0]);
  }
  // return a function that check string has value or not


  public SkillLevel(val: number) {
    if (val === 1)
      return [
        { name: 'Hand Washing / Infection Control' },
        { name: 'Bed Bath / Tub / Shower / Sponge Bath' },
        { name: 'Oral Hygiene' },
        { name: 'Stand / Walk / Breaking Fall' },
        { name: 'Occupied Bed / Positioning' },
        { name: 'Bedpan / Urinal Helpless / Helping' },
        { name: 'Transfer To Wheelchair Or Chair' },
        { name: 'Meds' },
        { name: 'Backrub / Nail Care' },
        { name: 'Hydraulic Lift / Hoyer Lift' },
        { name: 'Clean Dressing Change' },
        { name: 'Intake / Output' },
      ];
    else
      return [
        { name: 'Elastic Support Stockings' },
        { name: 'Weighing Patient' },
        { name: 'Assisting With Feeding' },
        { name: 'Assisting With Dressing' },
        { name: 'Shaving Client' },
        { name: 'Shampoo Setup' },
        { name: 'Emergency Response' },
        { name: 'Assisting With Mobility Equipment' },
        { name: 'Canes, Walkers, etc.' },
        { name: 'Indwelling Catheter Emptying Bag ' },
        { name: 'Condom Catheter / Straight Catheter' },
        { name: 'Monitoring vital Signs' },
      ];
  }
  public EvalutionQuestion() {
    return [
      { name: 'my aide arrives on the time and works the scheduled hours' },
      { name: 'my aide is dressed appropriately when he/she arrives' },
      { name: 'my aide is respectful and treats me with dignity' },
      { name: 'my aide performs all taks on the care plan satisfactory' },
      { name: 'i am satisfied with my aide' },
    ];
  }
  GetResonTypeValues() {
    let reasonType = [];
    for (let index = 0; index < 5; index++) {
      if (document.getElementsByName('reasonMainType')[index]['checked'] == true)
        reasonType.push(document.getElementsByName('reasonMainType')[index]['value']);
    }
    this.registerForm.controls.reasonType.setValue(reasonType);
  }
  public evalutionQ(val): string {
    return "evalutionQ" + val;
  }
}
