import { DOCUMENT, formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Employee } from 'src/app/model/employee.model';
import { EmployeeHourReport } from 'src/app/model/employeehoursheet.model';
import { Job } from 'src/app/model/job.model';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { TimeSheetTokenService } from 'src/app/shared/services/timesheettoken.service';
import * as FileSaver from 'file-saver';
import { ClientBillingConfiguration } from 'src/app/model/clientbillingconfiguration.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-employeetimesheet',
  templateUrl: './employeetimesheet.component.html',
  styleUrls: ['./employeetimesheet.component.css'],
})
export class EmployeetimesheetComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  public employeeList: Employee[];
  public jobList: Job[];
  dateList: any;
  employeeHoursList: EmployeeHourReport[];
  clientBillingConfigurationList: ClientBillingConfiguration[];
  clientBillingConfigurationUpdatedList: ClientBillingConfiguration[];

  date;
  currentDate;
  priorDate;
  currentWeek;
  showCustomDateRange: boolean;
  employeeStartDate = '';
  employeeEndDate = '';
  totalRegular = 0;
  totalOT = 0;
  totalHoliday = 0;
  totalHolidayOT = 0;
  totalHomeCare = 0;
  totalFullDay = 0;
  eventsSubject: Subject<ClientBillingConfiguration[]> = new Subject<ClientBillingConfiguration[]>();

  constructor(@Inject(LOCALE_ID) private locale: string,
    private timesheet: TimeSheetTokenService, private formBuilder: FormBuilder, private repo: RepositoryService,
    private toastr: ToastrService, private spinner: SpinnerService,
    // tslint:disable-next-line: variable-name
    private _renderer2: Renderer2,
    // tslint:disable-next-line: variable-name
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {
    this.DateDropDowns();
    this.GetEmployees();
    this.GetJobs();
    this.registerForm = this.formBuilder.group({
      employees: ['0'],
      sites: ['0'], //[Validators.required]
      startDate: [''],
      endDate: [''],
      access_Token: [''],
      definedDate: [formatDate(this.currentDate = new Date(), 'MM/dd/yyyy', this.locale)]
    });
  }
  get f() { return this.registerForm.controls; }

  onSubmit(): void {
    this.submitted = true;
    if (this.showCustomDateRange && !this.CheckDateTimeCondition((document.getElementById('datetimepicker1') as HTMLInputElement).value,
      (document.getElementById('datetimepicker2') as HTMLInputElement).value)) {
      return;
    }
    this.registerForm.controls.employees.setValue((document.getElementById('employees') as HTMLInputElement).value);
    // if ((document.getElementById('sites') as HTMLInputElement).value === '0') {
    //   this.registerForm.controls.sites.setValue('');
    // }
    // else {
    this.registerForm.controls.sites.setValue((document.getElementById('sites') as HTMLInputElement).value);
    // }
    if (this.registerForm.invalid) {
      return;
    }
    this.RunReport();

  }
  CheckDateTimeCondition(start: string, end: string): boolean {
    if (start === '') {
      this.toastr.warning('Start Date Required', 'Required');
      return false;
    }
    else {
      this.registerForm.controls.startDate.setValue((document.getElementById('datetimepicker1') as HTMLInputElement).value);
    }
    if (end === '') {
      this.toastr.warning('End Date Required', 'Oops');
      return false;
    }
    else {
      this.registerForm.controls.endDate.setValue((document.getElementById('datetimepicker2') as HTMLInputElement).value);
    }
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (endDate < startDate) {
      this.toastr.warning('End Date Must be greater then Start Date', 'Oops');
      return false;
    }
    else {
      return true;
    }

  }
  ngAfterViewInit(): void {
    // this.RunDateTimePicker();
    this.InitSelect2();
  }

  onReset(): void {
    this.submitted = false;
    this.registerForm.reset();
  }
  public GetEmployees(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/Employee/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.employeeList = res['data'];
          }
          else {
            // this.timesheet.GetToken();
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.spinner.hideSpinner();
          // this.timesheet.GetToken();
        }
      });
  }
  public GetJobs(): void {
    this.repo.getData('api/Job/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.jobList = res['data'];
          }
          else {
            //   this.timesheet.GetToken();
          }
        },
        error: err => {
          //  this.timesheet.GetToken();
        }
      });
  }
  InitSelect2(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $(".select2").select2();
          });
        }
    `;
    this._renderer2.appendChild(this._document.body, script);
  }
  DateDropDowns(): void {
    this.date = new Date();
    this.currentWeek = new Date(this.date.setDate(this.date.getDate() - this.date.getDay()));
    const currentWeek2 = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 6));
    const priorWeek2 = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() - 1));
    const priorWeek = new Date(this.date.setDate(this.date.getDate() - this.date.getDay()));

    this.dateList = [
      {
        date: formatDate(this.currentDate = new Date(), 'MM/dd/yyyy', this.locale),
        showDate: 'Current day ' + formatDate(this.currentDate = new Date(), '(MMM dd)', this.locale)
      },
      {
        date: formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), 'MM/dd/yyyy', this.locale),
        showDate: 'Prior day ' + formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), '(MMM dd)', this.locale)
      },
      {
        date: formatDate(this.currentWeek, 'MM/dd/yyyy', this.locale) + '-' +
          formatDate(currentWeek2, 'MM/dd/yyyy', this.locale),
        showDate: 'Current week ' + formatDate(this.currentWeek, '(MMM dd', this.locale) + ' - ' +
          formatDate(currentWeek2, 'MMM dd)', this.locale)
      },
      {
        date: formatDate(priorWeek, 'MM/dd/yyyy', this.locale) + '-' +
          formatDate(priorWeek2, 'MM/dd/yyyy', this.locale),
        showDate: 'Prior week ' + formatDate(priorWeek, '(MMM dd', this.locale) + ' - ' +
          formatDate(priorWeek2, 'MMM dd)', this.locale)
      },
      {
        date: 'Custom date range',
        showDate: 'Custom date range'
      }

    ];
  }
  RunDateTimePicker(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('.datetimepicker').datetimepicker({
              format: 'MM/DD/YYYY',
              keepOpen:false,
              stepping: 5,
              //sideBySide:true
             // debug: true
            });
          });
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
  DestroyDateTimePicker(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('.datetimepicker').data("DateTimePicker").destroy();
          });
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
  ChangeDateType(value: string): void {
    if (value === 'Custom date range') {
      this.RunDateTimePicker();
      this.showCustomDateRange = true;
    } else {
      this.DestroyDateTimePicker();
      this.showCustomDateRange = false;
    }
  }

  RunReport(): void {
    this.spinner.showSpinner();
    if (this.clientBillingConfigurationUpdatedList)
      this.clientBillingConfigurationUpdatedList = null;
    this.registerForm.controls.access_Token.setValue(localStorage.getItem('access_token'));
    if (localStorage.getItem('access_token') === '') {
      this.toastr.warning('some thing went wrong');
      return;
    }
    this.GetAllClientBillings();
    this.repo.create('api/Report/GetEmployeeHoursReport', this.registerForm.value)
      .subscribe({
        next: res => {
          if (res['returnStatus'] === true) {
            this.employeeHoursList = res['data'];
            this.employeeStartDate = '';
            this.employeeEndDate = '';
            if (this.employeeHoursList !== undefined && this.employeeHoursList.length > 0) {
              this.employeeStartDate = this.employeeHoursList[0].startDate;
              this.employeeEndDate = this.employeeHoursList[0].endDate;
              this.totalRegular = 0;
              this.totalOT = 0;
              this.totalFullDay = 0;
              this.totalHomeCare = 0;
              this.totalHoliday = 0;
              this.totalHolidayOT = 0;
              this.employeeHoursList.forEach(ele => {
                this.totalRegular = this.totalRegular + ele.totalHours + (- ele.totalFullDay - ele.totalHoliday - ele.totalHolidayOT - ele.totalHomeCare - ele.totalOT);
                this.totalFullDay = this.totalFullDay + ele.totalFullDay;
                this.totalHomeCare = this.totalHomeCare + ele.totalHomeCare;
                this.totalHoliday = this.totalHoliday + ele.totalHoliday;
                this.totalHolidayOT = this.totalHolidayOT + ele.totalHolidayOT;
                this.totalOT = this.totalOT + (ele.totalOT - ele.totalHolidayOT);
                this.eventsSubject.next(this.clientBillingConfigurationUpdatedList);
              });
            }
          }
          else if (res['returnStatus'] === false && res['returnMessage'][0] === 'Unauthorized') {
            // this.timesheet.GetToken();
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.toastr.error('Something went worng');
          this.spinner.hideSpinner();
          // this.timesheet.GetToken();
        }
      });
  }
  GetAllClientBillings() {
    this.repo.getData('api/ClientBillingConfiguration/GetAll')
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.clientBillingConfigurationList = res['data'];
          }
        },
        error: err => {
        }
      });
  }
  GenerateQuickBookCSV(t: any): void {
    this.spinner.showSpinner();
    var obj = {
      filterModel: this.registerForm.value,
      clientBillingConfigurations: this.clientBillingConfigurationUpdatedList
    }
    this.repo.create('api/Report/GetEmployeeHoursReportAsCSV', obj)
      .subscribe({
        next: res => {
          this.downloadExcel(res['data']['file'], res['data']['fileName']);
          // if (res['returnStatus'] === false && res['returnMessage'][0] === 'Unauthorized') {
          //   this.timesheet.GetToken();
          //}
          this.spinner.hideSpinner();
        },
        error: err => {
          // this.downloadExcel(err.error.text);
          this.spinner.hideSpinner();
          if (err.error.returnMessage[0] === 'Unauthorized') {
            //   this.timesheet.GetToken();
          } else {
            this.toastr.error(err.error.returnMessage);
          }
          // this.timesheet.GetToken();
        }
      });
  }
  downloadExcel(buffer: any, fileName: string): void {
    if (buffer === undefined) {
      return;
    }
    const data: Blob = new Blob([atob(buffer)], { type: 'text/csv' });
    FileSaver.saveAs(data, fileName);
  }
  clientBillingConfigurationsUpdate(data: ClientBillingConfiguration[]) {
    this.clientBillingConfigurationUpdatedList = data;

  }
}

