import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/model/user.model';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { UserType } from '../../enum/usertype.enum';
import { UserStatus } from '../../enum/userstatus.enum';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.css']
})
export class UserlistComponent implements OnInit {

  usersList: User[] = [];
  userType = UserType;
  userStatus = UserStatus;
  BreakException = {};
  constructor(private auth: AuthService, private toastr: ToastrService, private spinner: NgxSpinnerService,
    private repo: RepositoryService) { }

  ngOnInit(): void {
    this.GetAllUser();
  }

  GetAllUser() {
    this.repo.getData('api/User/GetAll')
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.usersList = data['data'];
            this.usersList.forEach((ele, i) => {
              if (ele.id === this.auth.GetUserId()) {
                this.usersList.splice(i, 1);
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }

  ChangeUserStatus(status: UserStatus, id: number) {
    this.repo.getData('api/User/Disable', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.usersList.forEach((ele, i) => {
              if (ele.id === id) {
                this.usersList[i].status = (status === this.userStatus.Disabled ? this.userStatus.Active : this.userStatus.Disabled);
                this.toastr.success(data['returnMessage'][0], 'Success');
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });

  }
  ChangeUserType(type: UserType, id: number) {
    this.repo.getData('api/User/ChangeType', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.usersList.forEach((ele, i) => {
              if (ele.id === id) {
                this.usersList[i].type = (type === this.userType.Admin ? this.userType.User : this.userType.Admin);
                this.toastr.success(data['returnMessage'][0], 'Success');
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });

  }

  DeleteUser(index: number, id: number) {
    this.repo.delete('api/User/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.usersList.forEach((ele, i) => {
              if (ele.id === id) {
                this.usersList.splice(index, 1);
                this.toastr.success(data['returnMessage'][0], 'Success');
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });

  }

  GetUserType(val:any){
    return UserType[val];
  }
  CopyPassword(index: number, userId: number) {
    const passwordObj = {
      UserId: userId,
      AdminId: this.auth.GetUserId()
    };
    this.repo.create('api/User/CopyPassword', passwordObj)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.copyToClipboard(data['data'].password);
            this.toastr.success('Password Copied!');
          }
        },
        error => {
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
}
