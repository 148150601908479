import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { CheckListConfiguration } from '../../model/checklistconfiguration.model';
import { CheckListConfigurationStatus } from '../../enum/checklistconfiguration.enum';

@Component({
  selector: 'app-checklistconfiguration',
  templateUrl: './checklistconfiguration.component.html',
  styleUrls: ['./checklistconfiguration.component.css']
})

export class ChecklistconfigurationComponent implements OnInit {

  checkListConfigurationList: CheckListConfiguration[] = [];
  checkListConfigurationStatus = CheckListConfigurationStatus;

  BreakException = {};
  displayStyle = 'none';
  displayStyle2 = 'none';
  updateModel: { id: number, name: string } = {
    id: 0,
    name: ''
  };
  createModel: { name: string } = {
    name: ''
  };
  constructor(private auth: AuthService, private toastr: ToastrService, private spinner: SpinnerService,
    private repo: RepositoryService) { }


  ngOnInit(): void {
    this.GetAllcheckListConfiguration();
  }

  OpenPopup(id: number, name: string): void {
    this.updateModel.name = name;
    this.updateModel.id = id;
    this.displayStyle = 'block';
  }
  ClosePopup(): void {
    this.displayStyle = 'none';
  }

  OpenPopupForCreate(): void {
    this.displayStyle2 = 'block';
  }
  ClosePopupForCreate(): void {
    this.displayStyle2 = 'none';
  }

  GetAllcheckListConfiguration(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/CheckListConfiguration/GetAll')
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.checkListConfigurationList = data['data'];
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  Update(): void {
    if (this.updateModel.id === 0 || this.updateModel.name === '') {
      this.toastr.error('Name is required');
      return;
    }
    this.spinner.showSpinner();
    this.repo.update('api/CheckListConfiguration/Update', this.updateModel.id, this.updateModel)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.checkListConfigurationList.forEach((ele, i) => {
              if (ele.id === this.updateModel.id) {
                ele.name = this.updateModel.name;
                this.toastr.success('Updated Successfully');
                this.ClosePopup();
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  AddNewCheckList(): void {
    if (this.createModel.name === '') {
      this.toastr.error('Name is required');
      return;
    }
    this.spinner.showSpinner();
    this.repo.create('api/CheckListConfiguration/insert', this.createModel)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.checkListConfigurationList.push(data['data']);
            this.toastr.success('Added Successfully');
            this.createModel = { name: '' };
            this.ClosePopupForCreate();
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  Delete(index: number, id: number): void {
    this.spinner.showSpinner();
    this.repo.delete('api/CheckListConfiguration/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.checkListConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.checkListConfigurationList.splice(index, 1);
                this.spinner.hideSpinner();
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  ChangeStatus(status: CheckListConfigurationStatus, id: number): void {
    this.spinner.showSpinner();
    this.repo.getData('api/CheckListConfiguration/Disable', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.checkListConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.checkListConfigurationList[i].status = (status === this.checkListConfigurationStatus.Disabled ?
                  this.checkListConfigurationStatus.Active : this.checkListConfigurationStatus.Disabled);
                this.toastr.success(data['returnMessage'][0]);
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
}
