<style>
  table thead tr th {
    font-weight: 500 !important;
  }
</style>
<div class="row">
  <div class="col-md-10 offset-md-1">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <h5 style="float: left;padding: .75rem 1.25rem">Holiday Configuration</h5>
        <button (click)="fileInput.click()" style="float: right; margin-top: 5px;" type="button"
          class="btn btn-custom mr-1">
          <i class="fa fa-upload"></i> Import With Excel</button>
        <input accept=".xlsx,.xls" (change)="onFileChanged($event)" #fileInput class="uploadfile" type="file"
          style="display: none;">
      </div>
      <div class="">
        <table class="table table-striped">
          <thead class="custom-table-header-color">
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Day Of Week</th>
              <th scope="col">Holiday Name</th>
              <th scope="col">Status
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="holidayConfigurationList.length > 0; else elseBlock">
              <tr *ngFor="let d of holidayConfigurationList; index as i">
                <td>
                  <p class="">{{ d.date | date: 'MM/dd/yyyy'}}</p>
                </td>
                <td>
                  <p class="">{{ d.dateOfWeek }}</p>
                </td>
                <td>
                  <p class="">{{ d.holidayName }}</p>
                </td>
                <td>
                  <p class="text-bold"
                    [ngClass]="{ 'text-green': d.status == userStatus.Active, 'text-danger': d.status == userStatus.Disabled }">
                    {{ d.status == userStatus.Active? 'Active' : 'Disabled' }}</p>
                </td>
                <td width="10%">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-sliders"></i>
                    </button>
                    <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <!-- <button class="dropdown-item" (click)="ChangeUserType(d.status,d.id)">Change Type to {{ d.type == userType.Admin ? 'User' : 'Admin' }}</button> -->
                      <button class="dropdown-item" (click)="ChangeStatus(d.status,d.id)">Change Status to {{ d.status
                        == userStatus.Active? 'Disabled' : 'Active' }}</button>
                      <button class="dropdown-item" (click)="Delete(i,d.id)">Delete</button>
                      <button class="dropdown-item" (click)="OpenPopup(d.id,d.date,d.dateOfWeek)">Edit</button>
                      <!-- <button *ngIf="auth.isSuperAdminUser" class="dropdown-item" (click)="CopyPassword(i,d.id)">Copy Password</button> -->
                    </div>

                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #elseBlock>
              <tr>
                <td colspan="10"></td>
              </tr>
              <tr>
                <td colspan="10" class="text-center">No Record Found......</td>
              </tr>
              <tr>
                <td colspan="10"></td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- <div
  class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="staticBackdropLabel">GeeksForGeeks</h4>
        <button (click)="closePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>One Stop Solution for all CS problems</p>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger"
                (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div> -->
<!-- Modal -->
<div [ngStyle]="{'display':displayStyle}" class="modal"
  tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Edit Holiday Configuration</h5>
        <button (click)="ClosePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-3">
            <label for="">Date</label>
          </div>
          <div class="col-md-8">
              <input id="datetimepicker" autocomplete="off" placeholder="Select Date" type="text"
                [(ngModel)]="updateModel.date" class="form-control datetimepicker"/>
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-3">
            <label for="">Day Of Week</label>
          </div>
          <div class="col-md-8">
            <select class="form-control" [(ngModel)]="updateModel.dateOfWeek">
              <option  *ngFor="let item of weekDays | keyvalue" value="{{item.value}}">{{item.value}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button (click)="ClosePopup()" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button (click)="Update()" type="button" class="btn btn-custom">Update</button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
