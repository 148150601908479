import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { RepositoryService } from '../../shared/services/repository.service';
import { AuthService } from 'src/app/auth/auth.service';
import { first } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  returnUrl: string;
  constructor(private router: Router, public authService: AuthService, private formBuilder: FormBuilder, private repo: RepositoryService,
    private route: ActivatedRoute, private toastr: ToastrService, private spinner: SpinnerService) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required]], //, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
      password: ['', [Validators.required]],
    });
    this.authService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() { return this.registerForm.controls; }

  onSubmit(): void {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.spinner.showSpinner();
    this.authService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.spinner.hideSpinner();
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.spinner.hideSpinner();
          if (error != undefined)
            this.toastr.error(error.error.returnMessage[0], 'Error');
        });

  }

}
