<style>
  .modal-dialog {
    max-width: 600px !important;
  }

  table thead tr th {
    font-weight: 500 !important;
  }
</style>
<div class="row">
  <div class="col-md-12">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <h5 style="float: left;padding: .75rem 1.25rem">Service Configuration
          <span style="font-size: 12px;" *ngIf="serviceConfigurationList.length > 0">(Total
            {{serviceConfigurationList.length}} Configuration)</span>
        </h5>
        <button (click)="OpenPopupForCreate()" style="float: right; margin-top: 5px;" type="button"
          class="btn btn-custom mr-1">
          <i class="fa fa-plus"></i> Add New </button>
      </div>
      <div>
        <table class="table table-striped">
          <thead class="custom-table-header-color">
            <tr>
              <th>#.</th>
              <th>Category</th>
              <th>Name</th>
              <th>Price</th>
              <th>Duration Type</th>
              <th>Actual Price</th>
              <th>Meridiem Required</th>
              <th>Status</th>
              <th>Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="serviceConfigurationList.length > 0; else elseBlock">
              <tr *ngFor="let d of serviceConfigurationList; index as i">
                <td><b>{{ i +1}}</b></td>
                <td>
                  <p>{{ d.category }}</p>
                </td>
                <td>
                  <p>{{ d.name }}</p>
                </td>
                <td>
                  <p>{{ d.price | currency : 'USD' : true : '1.2-2' }}</p>
                </td>
                <td>
                  <p>{{ d.durationType }}</p>
                </td>
                <td>
                  <p>{{ GetActualPrice(d.price,d.durationType)| currency : 'USD' : true : '1.2-2' }}</p>
                </td>
                <td>
                  <p>{{ d.isMeridiem? 'Yes':'No' }}</p>
                </td>
                <td>
                  <p class="text-bold"
                    [ngClass]="{ 'text-green': d.status == serviceStatus.Active, 'text-danger': d.status == serviceStatus.Disabled }">
                    {{ d.status == serviceStatus.Active? 'Active' : 'Disabled' }}</p>
                </td>
                <td>
                  <p>{{ d.created | date : 'MMM dd yyyy hh:mm a' }}</p>
                </td>
                <td width="10%">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-sliders"></i>
                    </button>
                    <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <button class="dropdown-item" (click)="ChangeStatus(d.status,d.id)">Change Status to {{ d.status
                        == serviceStatus.Active? 'Disabled' : 'Active' }}</button>
                      <button class="dropdown-item" (click)="Delete(i,d.id)">Delete</button>
                      <button class="dropdown-item"
                        (click)="OpenPopup(d.id,d.category,d.name,d.price,d.durationType,d.isMeridiem)">Edit</button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #elseBlock>
              <tr>
                <td colspan="10"></td>
              </tr>
              <tr>
                <td colspan="10" class="text-center">No Record Found......</td>
              </tr>
              <tr>
                <td colspan="10"></td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div [ngStyle]="{'display':displayStyle}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Edit Service Configuration</h5>
        <button (click)="ClosePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <label for="">Category</label>
          </div>
          <div class="col-md-6">
            <select class="form-control" [(ngModel)]="updateModel.category">
              <option value="" selected>Please Select Category</option>
              <option value="Suite Services">Suite Services</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="">Service Name</label>
          </div>
          <div class="col-md-6">
            <input placeholder="Enter Service Name" type="text" [(ngModel)]="updateModel.name" class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Price</label>
          </div>
          <div class="col-md-6">
            <input placeholder="Enter Price" type="text" [(ngModel)]="updateModel.price" class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Duration Type</label>
          </div>
          <div class="col-md-6">
            <select [(ngModel)]="updateModel.durationType" class="form-control">
              <ng-container *ngFor="let item of durationType| keyvalue">
                <option *ngIf="isEnumName(item)" [selected]="item.key == updateModel.durationType" [value]="item.value">
                  {{item.value}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Meridiem Required</label>
          </div>
          <div class="col-md-6">
            <input type="checkbox" [(ngModel)]="updateModel.isMeridiem" class="form-check-input-custom" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="Update()" type="button" class="btn btn-custom">Update</button>
      </div>
    </div>
  </div>
</div>

<div [ngStyle]="{'display':displayStyle2}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add Service Configuration</h5>
        <button (click)="ClosePopupForCreate()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <label for="">Category</label>
          </div>
          <div class="col-md-6">
            <select class="form-control" [(ngModel)]="createModel.category">
              <option value="" selected>Please Select Category</option>
              <option value="Suite Services">Suite Services</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label for="">Name</label>
          </div>
          <div class="col-md-6">
            <input placeholder="Enter Service Name" type="text" [(ngModel)]="createModel.name" class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Price</label>
          </div>
          <div class="col-md-6">
            <input placeholder="Enter Price" type="number" min="1" [(ngModel)]="createModel.price"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Duration Type</label>
          </div>
          <div class="col-md-6">
            <select [(ngModel)]="createModel.durationType" class="form-control">
              <ng-container *ngFor="let item of durationType| keyvalue">
                <option *ngIf="isEnumName(item,false)" [selected]="15" [value]="item.value">
                  {{item.value}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Meridiem Required</label>
          </div>
          <div class="col-md-6">
            <input type="checkbox" [(ngModel)]="createModel.isMeridiem" class="form-check-input-custom" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="AddNewServiceConfiguration()" type="button" class="btn btn-custom">Add</button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
