import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { buffer } from 'rxjs/operators';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { InitialClientChecklist } from '../../model/InitialClientChecklist.model';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-initialnursingevaluation',
  templateUrl: './initialnursingevaluation.component.html',
  styleUrls: ['./initialnursingevaluation.component.css'],
})
export class InitialnursingevaluationComponent implements OnInit {
  registerForm: FormGroup;
  clientList: [];
  checkList: InitialClientChecklist[];
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private repo: RepositoryService,
    private toastr: ToastrService,
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.GetClients();
    this.registerForm = this.formBuilder.group({
      clientId: [[], [Validators.required]],
      checkListId: [],
    });
  }
  get f() {
    return this.registerForm.controls;
  }

  GetClientName(val: number) {
    return this.clientList.filter((x) => x['id'] == val)[0]['name'];
  }
  public GetClients(): void {
    this.spinner.showSpinner();
    this.repo
      .getData('api/InitialNursingClientEvaluation/GetAllClients')
      .subscribe({
        next: (res) => {
          if (res['returnStatus'] == true) {
            this.clientList = res['data'];
            this.spinner.hideSpinner();
          }
        },
        error: (err) => {
          this.spinner.hideSpinner();
        },
      });
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.GetInitialClientChecklistEvaluationHomeProfileId(
      this.registerForm.controls.clientId.value
    );
  }

  GeneratePDF(val?: number): void {
    this.registerForm.controls.checkListId.setValue(val);
    this.spinner.showSpinner();
    this.repo
      .create(
        'api/Report/GetInitialNursingReportAsPDF',
        this.registerForm.value
      )
      .subscribe({
        next: (res) => {
          this.downloadPDF(res['data']['file'], res['data']['fileName']);
          this.spinner.hideSpinner();
        },
        error: (err) => {
          this.spinner.hideSpinner();
        },
      });
  }

  GetInitialClientChecklistEvaluationHomeProfileId(val: number) {
    this.spinner.showSpinner();
    this.repo
      .getData('api/InitialClientChecklistEvaluation/GetByHomeProfileId', val)
      .subscribe({
        next: (res) => {
          if (res['returnStatus'] == true) {
            if (res['returnMessage'][0] == 'Record not found.') {
              this.toastr.warning('No checklist history found, PDF download without checklist', '', { timeOut: 15000 });
              this.GeneratePDF();
              return;
            }
            this.checkList = res['data'];
            if (this.checkList.length == 1) this.GeneratePDF();
            if (this.checkList.length > 1) this.spinner.hideSpinner();
          }
        },
        error: (err) => {
          if (err.error.returnMessage[0] == 'Record not found') {
            this.toastr.warning('No checklist history found, PDF download without checklist', '', { timeOut: 15000 });
            this.GeneratePDF();
            return;
          }
          this.spinner.hideSpinner();
        },
      });
  }

  downloadPDF(buffer: string, fileName: string): void {
    if (buffer === undefined) {
      return;
    }
    const byteArray = new Uint8Array(
      atob(buffer)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    const data: Blob = new Blob([byteArray], { type: 'application/pdf' });
    FileSaver.saveAs(data, fileName);
    // let pdfUrl = window.URL.createObjectURL(data);

    // var PDF_link = document.createElement('a');
    // PDF_link.href = pdfUrl;

    // window.open(pdfUrl, '_blank');
    //   TO DOWNLOAD PDF TO YOUR COMPUTER
    // PDF_link.download = 'TestFile.pdf';
    // PDF_link.click();
  }
}
