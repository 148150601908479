import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Job } from 'src/app/model/job.model';
import { QuickBookConfiguration } from 'src/app/model/quickbookconfiguration.model';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-quickbookconfiguration',
  templateUrl: './quickbookconfiguration.component.html',
  styleUrls: ['./quickbookconfiguration.component.css']
})

export class QuickbookconfigurationComponent implements OnInit {

  quickBookConfigurationList: QuickBookConfiguration[] = [];
  BreakException = {};
  jobList: Job[];
  displayStyle = 'none';
  displayStyle2 = 'none';
  invoiceRegisterForm: FormGroup;
  registerForm: FormGroup;
  registerForm2: FormGroup;
  submitted = false;
  submitted2 = false;
  submitted3 = false;
  siteNameList: any = [];
  quickBookPriceConfigurations: FormArray;
  quickBookConfigurationId: number;
  disabledButton = false;
  subTypeType = 'other';
  // subTypes: { key: string; value: string }[] = [{ key: 'WeekDay', value: 'Week Day' },
  // { key: 'Weekend', value: 'Weekend' }, { key: 'Cancel', value: 'Cancel' }];


  constructor(private formBuilder: FormBuilder, private auth: AuthService, private toastr: ToastrService, private spinner: SpinnerService,
              private repo: RepositoryService, private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) { }

  ngAfterViewInit(): void {
    this.InitSelect2();
  }
  ngOnInit(): void {
    this.invoiceRegisterForm = this.formBuilder.group({
      pattren: ['', [Validators.required]],
    });

    this.registerForm = this.formBuilder.group({
      siteName: ['', [Validators.required]],
      term: ['', [Validators.required]],
      allowOnlinePayment: [true],
      quickBookPriceConfigurations: this.formBuilder.array([])
    });

    this.registerForm2 = this.formBuilder.group({
      siteName: ['', [Validators.required]],
      term: ['', [Validators.required]],
      allowOnlinePayment: [''],
      quickBookPriceConfigurations: this.formBuilder.array([])
    });
    this.GetAllQuickBookConfiguration();
    this.GetInvoiceNumber();
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      price: ['1', [Validators.required]],
      type: ['', [Validators.required]],
      subType: ['', [Validators.required]]
    });
  }
  addItem(): void {
    this.quickBookPriceConfigurations = this.registerForm.get('quickBookPriceConfigurations') as FormArray;
    this.quickBookPriceConfigurations.push(this.createItem());
  }
  removeItem(index: number): void {
    this.quickBookPriceConfigurations.removeAt(index);
  }
  addItemUpdate(): void {
    this.quickBookPriceConfigurations = this.registerForm2.get('quickBookPriceConfigurations') as FormArray;
    this.quickBookPriceConfigurations.push(this.createItem());
  }
  removeItemUpdate(index: number): void {
    this.quickBookPriceConfigurations.removeAt(index);
  }

  get f() { return this.registerForm.controls; }
  get u() { return this.registerForm2.controls; }
  get p() { return this.invoiceRegisterForm.controls; }

  onSubmitInvoiceNumber(): void {
    this.submitted3 = true;
    if (this.invoiceRegisterForm.invalid) {
      return;
    }
    this.spinner.showSpinner();
    this.repo.update('api/PattrenConfiguration/Update', 1, this.invoiceRegisterForm.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.toastr.success(data['returnMessage'][0]);
            this.spinner.hideSpinner();
            this.submitted3 = false;
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  OpenPopup(): void {
    // this.GetCommunityNames();
    this.GetJobs();
    if (this.quickBookPriceConfigurations === undefined || this.quickBookPriceConfigurations.length === 0) {
      this.addItem();
    }
    this.displayStyle = 'block';
  }
  ClosePopup(): void {
    // this.quickBookPriceConfigurations.value.forEach((e, i) => {
    //   this.removeItem(i);
    // });
    while (this.quickBookPriceConfigurations.length !== 0) {
      this.quickBookPriceConfigurations.removeAt(0);
    }
    this.submitted = false;
    this.displayStyle = 'none';
  }

  OpenPopupForUpdate(model: QuickBookConfiguration): void {
    // this.GetCommunityNames();
    // if (model.quickBookPriceConfigurations.length === 0 && this.quickBookPriceConfigurations === undefined || this.quickBookPriceConfigurations.length === 0) {
    //   this.addItemUpdate();
    // }
    this.quickBookConfigurationId = model.id;
    this.registerForm2.controls.siteName.setValue(model.siteName);
    this.registerForm2.controls.term.setValue(model.term);
    this.registerForm2.controls.allowOnlinePayment.setValue(model.allowOnlinePayment);

    model.quickBookPriceConfigurations.forEach(e => {
      this.quickBookPriceConfigurations = this.registerForm2.get('quickBookPriceConfigurations') as FormArray;
      const data: FormGroup = this.formBuilder.group({
        id: e.id,
        price: e.price,
        type: e.type,
        subType: e.subType
      });
      this.quickBookPriceConfigurations.push(data);
      //   (<FormArray>this.registerForm2.controls.quickBookPriceConfigurations).push(this.formBuilder.group({
      //     id: e.id,
      //     price: e.price,
      //     type: e.type
      //   }));
    });
    this.displayStyle2 = 'block';
  }
  ClosePopupForUpdate(): void {
    while (this.quickBookPriceConfigurations.length !== 0) {
      this.quickBookPriceConfigurations.removeAt(0);
    }
    // this.quickBookPriceConfigurations.value.forEach((e, i) => {
    //   this.removeItemUpdate(i);
    // });
    this.submitted2 = false;
    this.displayStyle2 = 'none';
  }

  GetAllQuickBookConfiguration(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/QuickBookConfiguration/GetAll')
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.quickBookConfigurationList = data['data'];
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  GetInvoiceNumber(): void {
    this.repo.getData('api/PattrenConfiguration/GetById?Id=1')
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] === true) {
            this.invoiceRegisterForm.controls.pattren.setValue(data['data']['pattren']);
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }

  GetQuickBookConfigurationById(id: number): void {
    this.spinner.showSpinner();
    this.repo.getData('api/QuickBookConfiguration/GetById?Id', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] === true) {
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  onSubmit(): void {
    this.submitted = true;
    this.registerForm.controls.siteName.setValue((<HTMLInputElement>document.getElementById('siteName')).value);
    if (this.registerForm.invalid) {
      return;
    }
    this.spinner.showSpinner();
    this.repo.create('api/QuickBookConfiguration/Insert', this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.toastr.success('Configuration Added Successfully');
            this.onReset();
            this.ClosePopup();
            this.GetAllQuickBookConfiguration();
          }
          else {
            this.toastr.error(data['ReturnMessage']);
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  Update(): void {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    this.spinner.showSpinner();
    this.repo.update('api/QuickBookConfiguration/Update', this.quickBookConfigurationId, this.registerForm2.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] === true) {
            // this.quickBookConfigurationList.forEach((ele, i) => {
            //   if (ele.id === this.quickBookConfigurationId) {
            //     ele.siteName = this.registerForm2.controls.siteName.value;
            //     ele.term = this.registerForm2.controls.term.value;
            this.GetAllQuickBookConfiguration();
            this.onResetUpdate();
            this.ClosePopupForUpdate();
            this.spinner.hideSpinner();
            this.toastr.success('Updated Successfully');
            // throw this.BreakException;
            //   }
            // });
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  Delete(index: number, id: number): void {
    this.spinner.showSpinner();
    this.repo.delete('api/QuickBookConfiguration/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] === true) {
            this.quickBookConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.quickBookConfigurationList.splice(index, 1);
                this.spinner.hideSpinner();
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  GetCommunityNames(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/ShiftRequestConfiguration/GetCommunityNames', 0)
      .subscribe({
        next: res => {
          if (res['returnStatus'] === true) {
            this.siteNameList = res['data'];
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.toastr.error(err.error.returnMessage[0]);
          this.spinner.hideSpinner();
        }
      });
  }

  public GetJobs(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/Job/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] === true) {
            this.jobList = res['data'];
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.spinner.hideSpinner();
        }
      });
  }
  onReset(): void {
    this.submitted = false;
    this.quickBookPriceConfigurations.value.forEach((e, i) => {
      this.removeItem(i);
    });
    this.registerForm.reset();
  }
  onResetUpdate(): void {
    this.submitted2 = false;
    // this.quickBookPriceConfigurations.value.forEach((e, i) => {
    //   this.removeItemUpdate(i);
    // });
    while (this.quickBookPriceConfigurations.length !== 0) {
      this.quickBookPriceConfigurations.removeAt(0);
    }
    this.registerForm2.reset();
  }

  // FindDuplicate(type?, subType?): boolean {
  //    this.GetSubType(type);
  //   const myArray = this.registerForm.controls.quickBookPriceConfigurations.value;
  //   const isDuplicate = myArray.filter(data => data.type === type && type != null);
  //   // const isDuplicate = myArray.filter(data => data.type === type && data.subType && type != null && subType != null);
  //   if (isDuplicate.length > 1) {
  //     this.disabledButton = true;
  //     this.toastr.error('Role already selected');
  //     return true;
  //   } else {
  //     this.disabledButton = false;
  //     return false;
  //   }
  // }
  InitSelect2(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            // Initialize select2
            $("select").select2({ width: '100%' });
          });
          (function($) {
            var Defaults = $.fn.select2.amd.require('select2/defaults');
            $.extend(Defaults.defaults, {
              dropdownPosition: 'auto'
            });
            var AttachBody = $.fn.select2.amd.require('select2/dropdown/attachBody');
            var _positionDropdown = AttachBody.prototype._positionDropdown;
            AttachBody.prototype._positionDropdown = function() {
              var $window = $(window);
              var isCurrentlyAbove = this.$dropdown.hasClass('select2-dropdown--above');
              var isCurrentlyBelow = this.$dropdown.hasClass('select2-dropdown--below');
              var newDirection = null;
              var offset = this.$container.offset();
              offset.bottom = offset.top + this.$container.outerHeight(false);
              var container = {
                  height: this.$container.outerHeight(false)
              };
              container.top = offset.top;
              container.bottom = offset.top + container.height;
              var dropdown = {
                height: this.$dropdown.outerHeight(false)
              };
              var viewport = {
                top: $window.scrollTop(),
                bottom: $window.scrollTop() + $window.height()
              };
              var enoughRoomAbove = viewport.top < (offset.top - dropdown.height);
              var enoughRoomBelow = viewport.bottom > (offset.bottom + dropdown.height);
              var css = {
                left: offset.left,
                top: container.bottom
              };
              // Determine what the parent element is to use for calciulating the offset
              var $offsetParent = this.$dropdownParent;
              // For statically positoned elements, we need to get the element
              // that is determining the offset
              if ($offsetParent.css('position') === 'static') {
                $offsetParent = $offsetParent.offsetParent();
              }
              var parentOffset = $offsetParent.offset();
              css.top -= parentOffset.top
              css.left -= parentOffset.left;
              var dropdownPositionOption = this.options.get('dropdownPosition');
              if (dropdownPositionOption === 'above' || dropdownPositionOption === 'below') {
                newDirection = dropdownPositionOption;
              } else {
                if (!isCurrentlyAbove && !isCurrentlyBelow) {
                  newDirection = 'below';
                }
                if (!enoughRoomBelow && enoughRoomAbove && !isCurrentlyAbove) {
                  newDirection = 'above';
                } else if (!enoughRoomAbove && enoughRoomBelow && isCurrentlyAbove) {
                  newDirection = 'below';
                }
              }
              if (newDirection == 'above' ||
              (isCurrentlyAbove && newDirection !== 'below')) {
                  css.top = container.top - parentOffset.top - dropdown.height;
              }
              if (newDirection != null) {
                this.$dropdown
                  .removeClass('select2-dropdown--below select2-dropdown--above')
                  .addClass('select2-dropdown--' + newDirection);
                this.$container
                  .removeClass('select2-container--below select2-container--above')
                  .addClass('select2-container--' + newDirection);
              }
              this.$dropdownContainer.css(css);
            };
          })(window.jQuery);
        }
    `;
    this._renderer2.appendChild(this._document.body, script);
  }
  // GetSubType(val: string): void {
  //   if (val === 'Home Care Services') {
  //     this.subTypes = [{ key: 'HomeCare', value: 'Home Care' }];
  //   }
  //   else if (val === '24 Hour Care') {
  //     this.subTypes = [{ key: '24HourDay', value: '24 Hour Day' }];
  //   }
  //   else {
  //    this.subTypes = [{ key: 'WeekDay', value: 'Week Day' }, { key: 'Weekend', value: 'Weekend' }, { key: 'Cancel', value: 'Cancel' }];
  //   }
  // }
}
