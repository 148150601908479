import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-shiftrequestconfiguration',
  templateUrl: './shiftrequestconfiguration.component.html',
  styleUrls: ['./shiftrequestconfiguration.component.css']
})
export class ShiftrequestconfigurationComponent implements OnInit {

  shiftRequestConfigurationList: any = [];
  registerForm: FormGroup;
  submitted = false;
  BreakException: {};
  displayStyle = 'none';
  updateModel: { id: number, communityName: string, shiftRole: string, shiftType: string, shiftHour: string } = {
    id: 0,
    communityName: '',
    shiftRole: '',
    shiftType: '',
    shiftHour: ''
  };
  constructor(
    private formBuilder: FormBuilder, private repo: RepositoryService,
    private toastr: ToastrService, private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      communityName: ['', [Validators.required]],
      shiftRole: ['', [Validators.required]],
      shiftType: ['', [Validators.required]],
      shiftHour: ['', [Validators.required]]
    });
    this.GetAll();
  }
  get f() { return this.registerForm.controls; }

  onSubmit(): void {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.Save();

  }
  ngAfterViewInit(): void {
  }

  onReset(): void {
    this.submitted = false;
    this.registerForm.reset();
  }


  Save(): void {
    this.spinner.showSpinner();
    this.repo.create('api/ShiftRequestConfiguration/Insert', this.registerForm.value)
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.shiftRequestConfigurationList.push(res['data']);
            this.toastr.success('Added Successfully');
          }

          this.spinner.hideSpinner();
        },
        error: err => {
          this.toastr.error("Something went worng")
          this.spinner.hideSpinner();
        }
      });
  }
  GetAll(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/ShiftRequestConfiguration/GetAll')
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.shiftRequestConfigurationList = res['data'];
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.toastr.error("Something went worng")
          this.spinner.hideSpinner();
        }
      });
  }
  Delete(index: number, id: number): void {
    this.repo.delete('api/ShiftRequestConfiguration/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.shiftRequestConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.shiftRequestConfigurationList.splice(index, 1);
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  OpenPopup(id: number, communityName: string, shiftRole: string, shiftType: string, shiftHour: string): void {
    this.updateModel.communityName = communityName;
    this.updateModel.shiftRole = shiftRole;
    this.updateModel.shiftType = shiftType;
    this.updateModel.shiftHour = shiftHour;
    this.updateModel.id = id;
    this.displayStyle = 'block';
  }
  ClosePopup(): void {
    this.displayStyle = 'none';
  }
  Update(): void {
    if (this.updateModel.id === 0 ||
       this.updateModel.communityName === '' ||
       this.updateModel.shiftHour === '') {
      this.toastr.error('All fields are required');
      return;
    }
    this.spinner.showSpinner();
    this.repo.update('api/ShiftRequestConfiguration/Update', this.updateModel.id, this.updateModel)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] === true) {
            this.shiftRequestConfigurationList.forEach((ele, i) => {
              if (ele.id === this.updateModel.id) {
                ele.communityName = this.updateModel.communityName;
                ele.shiftRole = this.updateModel.shiftRole;
                ele.shiftType = this.updateModel.shiftType;
                ele.shiftHour = this.updateModel.shiftHour;
                this.toastr.success(data['returnMessage'][0]);
                this.ClosePopup();
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
}

