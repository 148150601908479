<style>
  table thead tr th {
    font-weight: 500 !important;
  }
</style>
<div class="row">
  <div class="col-md-10 offset-md-1">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <h5 style="float: left;padding: .75rem 1.25rem">Employee ADP File Number Configuration
          <span style="font-size: 12px;" *ngIf="employeeFileConfigurationList.length > 0">(Total
            {{employeeFileConfigurationList.length}} Employees)</span>
        </h5>
        <button (click)="fileInput.click()" style="float: right; margin-top: 5px;" type="button"
          class="btn btn-custom mr-1">
          <i class="fa fa-upload"></i> Import With Excel</button>
        <input accept=".xlsx,.xls" (change)="onFileChanged($event)" #fileInput class="uploadfile" type="file"
          style="display: none;">
        <button (click)="OpenPopupForCreate()" style="float: right; margin-top: 5px;" type="button"
          class="btn btn-custom mr-1">
          <i class="fa fa-plus"></i> Add New </button>
      </div>
      <div>
        <table class="table table-striped">
          <thead class="custom-table-header-color">
            <tr>
              <th>#.</th>
              <th style="width: 14%;">Employee Name</th>
              <th>ADP File Number #</th>
              <th>Bonus</th>
              <th>Temporary Rate</th>
              <th>Rate 1</th>
              <th>Rate 2</th>
              <th>Status</th>
              <th>Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="employeeFileConfigurationList.length > 0; else elseBlock">
              <tr *ngFor="let d of employeeFileConfigurationList; index as i">
                <td><b>{{ i +1}}</b></td>
                <td>
                  <p>{{ d.employeeName}}</p>
                </td>
                <td>
                  <p>{{ d.fileNo }}</p>
                </td>
                <td>
                  <p>{{ d.bonus }}</p>
                </td>
                <td>
                  <p>{{ d.tempRate }}</p>
                </td>
                <td>
                  <p *ngIf="d.rate1">{{ d.rate1 }}$</p>
                </td>
                <td>
                  <p *ngIf="d.rate2">{{ d.rate2 }}$</p>
                </td>
                <td>
                  <p class="text-bold"
                    [ngClass]="{ 'text-green': d.status == employeeFileStatus.Active, 'text-danger': d.status == employeeFileStatus.Disabled }">
                    {{ d.status == employeeFileStatus.Active? 'Active' : 'Disabled' }}</p>
                </td>
                <td>
                  <p>{{ d.created | date : 'MMM dd yyyy hh:mm a' }}</p>
                </td>
                <td width="10%">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-sliders"></i>
                    </button>
                    <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <button class="dropdown-item" (click)="ChangeStatus(d.status,d.id)">Change Status to {{ d.status
                        == employeeFileStatus.Active? 'Disabled' : 'Active' }}</button>
                      <button class="dropdown-item" (click)="Delete(i,d.id)">Delete</button>
                      <button class="dropdown-item"
                        (click)="OpenPopup(d.id,d.employeeName,d.fileNo,d.bonus,d.tempRate,d.rate1,d.rate2)">Edit</button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #elseBlock>
              <tr>
                <td colspan="10"></td>
              </tr>
              <tr>
                <td colspan="10" class="text-center">No Record Found......</td>
              </tr>
              <tr>
                <td colspan="10"></td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div [ngStyle]="{'display':displayStyle}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Edit Employee ADP File Number Configuration</h5>
        <button (click)="ClosePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <label for="">Employee Name</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Employee Name" type="text"
              [(ngModel)]="updateModel.employeeName" class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">File Number #</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter File Number" type="number" [(ngModel)]="updateModel.fileNo"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Bonus<span> (optional)</span></label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Bonus" type="number" min="1" [(ngModel)]="updateModel.bonus"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Temp Rate<span> (optional)</span></label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Temp Rate" type="number" min="1"
              [(ngModel)]="updateModel.tempRate" class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Rate 1<span> (optional)</span></label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Rate 1" type="number" min="1" [(ngModel)]="updateModel.rate1"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Rate 2<span> (optional)</span></label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Rate 2" type="number" min="1" [(ngModel)]="updateModel.rate2"
              class="form-control" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="Update()" type="button" class="btn btn-custom">Update</button>
      </div>
    </div>
  </div>
</div>

<div [ngStyle]="{'display':displayStyle2}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add Employee ADP File Number Configuration</h5>
        <button (click)="ClosePopupForCreate()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <label for="">Employee Name</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Employee Name" type="text"
              [(ngModel)]="createModel.employeeName" class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">File Number #</label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter File Number" type="number" [(ngModel)]="createModel.fileNo"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Bonus<span> (optional)</span></label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Bonus" type="number" [(ngModel)]="createModel.bonus"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Temp Rate <span> (optional)</span></label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Temp Rate" type="number" [(ngModel)]="createModel.tempRate"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Rate 1<span> (optional)</span></label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Rate 1" type="number" min="1" [(ngModel)]="createModel.rate1"
              class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-4">
            <label for="">Rate 2<span> (optional)</span></label>
          </div>
          <div class="col-md-6">
            <input autocomplete="off" placeholder="Enter Rate 2" type="number" min="1" [(ngModel)]="createModel.rate2"
              class="form-control" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="AddNewEmployeeFile()" type="button" class="btn btn-custom">Add</button>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
