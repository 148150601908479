import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrolltoviewService {

  constructor() { }

  scrollToElement($element): void {
    if ($element != null || $element != undefined)
      $element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
  }
}
