import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { StatusType } from 'src/app/enum/statustype.enum';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { JobConfiguration } from '../../model/jobconfiguration.model';


@Component({
  selector: 'app-jobconfiguration',
  templateUrl: './jobconfiguration.component.html',
  styleUrls: ['./jobconfiguration.component.css']
})
export class JobconfigurationComponent implements OnInit {

  jobConfigurationList: JobConfiguration[] = [];
  jobConfiguration: JobConfiguration;
  BreakException = {};
  jobStatus = StatusType;
  displayStyle = 'none';


  constructor(private toastr: ToastrService, private spinner: SpinnerService,
    private repo: RepositoryService) {

  }

  ngOnInit(): void {
    this.GetAlljobConfiguration();
  }

  OpenPopup(job:JobConfiguration ): void {
    this.jobConfiguration  = job;
    this.displayStyle = 'block';
  }
  ClosePopup(): void {
    this.displayStyle = 'none';
  }

  GetAlljobConfiguration(): void {

    this.spinner.showSpinner();
    this.repo.getData('api/TravelJob/GetAll')
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.jobConfigurationList = data['data'];
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }

  onFileChanged(event): void {
    this.spinner.showSpinner();
    const selectedFile: File = (event.target.files[0] as File);
    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);
    this.repo.file('api/TravelJob/ImportTravelJob', formData)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.jobConfigurationList = data['data'];
            this.toastr.success(data['returnMessage'][0]);
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  Delete(index: number, id: number): void {
    this.spinner.showSpinner();
    this.repo.delete('api/TravelJob/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.jobConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.jobConfigurationList.splice(index, 1);
                this.spinner.hideSpinner();
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  ChangeStatus(status: StatusType, id: number): void {
    this.spinner.showSpinner();
    this.repo.getData('api/TravelJob/Disable', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.jobConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.jobConfigurationList[i].status = (status === this.jobStatus.Disabled ?
                  this.jobStatus.Active : this.jobStatus.Disabled);
                this.toastr.success(data['returnMessage'][0]);
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
}
