import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserType } from '../enum/usertype.enum';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
      if (route.data.roles && route.data.roles.indexOf(currentUser.data.type) === -1 && currentUser.data.type != UserType.Nurse) {

        this.router.navigate(['/']);
        return false;
      }
      if (route.data.roles && route.data.roles.indexOf(currentUser.data.type) === -1 && currentUser.data.type == UserType.Nurse) {
        this.router.navigate(['/medication-form-request']);
        return true;
      }
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
