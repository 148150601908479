import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ScrolltoviewService } from 'src/app/shared/services/scrolltoview.service';
import { HomeClientProfile } from "../../model/homeclientprofile.model";
import { RepositoryService } from '../../shared/services/repository.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-medicationform',
  templateUrl: './medicationform.component.html',
  styleUrls: ['./medicationform.component.css']
})
export class MedicationformComponent implements OnInit {
  homeClientProfile: HomeClientProfile;
  BreakException = {};
  displayStyle = 'none';
  registerForm: FormGroup;
  submitted = false;
  homeClientMedicationDrugProfiles: FormArray;
  isIndependentLiving: boolean = false;


  constructor(private router: Router,private formBuilder: FormBuilder, private toastr: ToastrService, private spinner: SpinnerService,
    private scroll: ScrolltoviewService, private repo: RepositoryService, private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {
    this.HomeClientForm();
    this.addItem(5);
  }

  HomeClientForm() {
    return this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      dOB: ['', [Validators.required]],
      sSN: ['', [Validators.required]],
      allergies: [''],
      pastMedicalHistory: ['',Validators.maxLength(100)],
      reasonForCare: [''],
      hospitalOfChoice: [''],
      religiousAffiliation: [''],
      pastor: [''],
      powerOfAttorney: [''],
      powerOfAttorneyAddress: [''],
      powerOfAttorneyPhone: [''],
      primaryCareMD: [''],
      primaryCareMDAddress: [''],
      primaryCareMDPhone: [''],
      primaryCareMDFax: [''],
      specialtyMD: [''],
      specialtyMDAddress: [''],
      specialtyMDPhone: [''],
      specialtyMDFax: [''],
      caseManagerAgency: [''],
      caseManagerAgencyAddress: [''],
      caseManagerAgencyPhone: [''],
      caseManagerAgencyFax: [''],
      insuranceCoName: [''],
      insuranceCoPolicyGroup: [''],
      insuranceCoAddress: [''],
      insuranceCoPhone: [''],
      insuranceCoFax: [''],
      homeClientMedicationDrugProfiles: this.formBuilder.array([]),
      homeClientMedicationProfile: this.formBuilder.group({
        allergies: [''],
        pharmacyAddress: [''],
        pharmacyPhone: [''],
        // homeClientMedicationDrugProfiles: this.formBuilder.array([]),
      }),
      initialNursingClientEvaluation: this.formBuilder.group({
        physician: [''],
        admittedFrom: [''],
        nursesAdmissionNote: ['', [Validators.required,Validators.maxLength(800)]],
        signatureOfAssessor: [''],
        isIndependentLiving: ['false'],
        initialDate: [new Date()],
        completedDate: [new Date()],
        vitalSignHeight: [''],
        vitalSignWeight: [''],
        vitalSignTemp: [''],
        vitalSignPulse: [''],
        vitalSignRespiration: [''],
        vitalSignBPSitting: [''],
        vitalSignBPStanding: [''],
        vitalSignBPLaying: [''],
        generalAppearancePersonalHygiene: [''],
        generalAppearanceHearingAid: [''],
        generalAppearanceGlasses: [''],
        generalAppearanceEars: [''],
        generalAppearanceEyes: [''],
        generalAppearanceDrainagePresent: [''],
        generalAppearanceMentalStatus: [''],
        generalAppearanceDateOfLastPneumovax: [''],
        generalAppearanceDateOfLastFluShot: [''],
        generalAppearanceComments: [''],
        cardiacHeartRhythm: [''],
        cardiacEdema: [''],
        cardiacLocation: [''],
        cardiacPedalPulses: [''],
        cardiacComments: [''],
        respiratoryBreathSounds: [''],
        respiratoryCough: [''],
        respiratoryMucousMembranes: [''],
        respiratoryComments: [''],
        gastrointestinalAbdomen: [''],
        gastrointestinalBowelSounds: [''],
        gastrointestinalComplaints: [''],
        gastrointestinalDateOfLastBMIfKnown: [''],
        gastrointestinalComments: [''],
        genitoUrinaryBowelAndBladder: [''],
        genitoUrinaryFoleyPresent: [''],
        genitoUrinarySize: [''],
        genitoUrinaryUrineCharacteristics: [''],
        genitoUrinaryComments: [''],
        musculoskeletalGait: [''],
        musculoskeletalAmputation: [''],
        musculoskeletalAmputationLocation: [''],
        musculoskeletalSplints: [''],
        musculoskeletalSplintsLocation: [''],
        musculoskeletalAssistedDevices: [''],
        musculoskeletalAssistedDevicesOther: [''],
        musculoskeletalComments: [''],
        integumentaryTemperature: [''],
        integumentaryScalp: [''],
        integumentaryColor: [''],
        integumentaryEvidenceOfParasites: [''],
        integumentaryEvidenceOfParasitesLocation: [''],
        integumentaryComments: [''],
        painAny: ['', [Validators.required]],
        painScale: [''],
        painLocation: [''],
        painClientDescriptionOfPain: [''],
        painObjectiveIndicatorsOfPain: [''],
        painWhatMakesYourPainFeelBetter: [''],
        painWhatMakesYourPainFeelWorse: [''],
        painComments: [''],
        oralTeeth: [''],
        oralDentures: [''],
        oralGums: [''],
        oralAssessment: ['',],
        oralHygiene: [''],
        oralComments: [''],
        serviceRequest: ['', [Validators.required]],
        serviceTotalHour: ['', [Validators.required]],
        isAppropriateStaffAvailable: ['', [Validators.required]],
        serviceRequestComments: [''],
        serviceRequestEstimatedStartDate: ['', [Validators.required]],
        serviceRequestPlanCompleted: ['', [Validators.required]],
      })
    });
  }
  IsIndependantLiving(val) {
    if (JSON.parse(val))
      this.isIndependentLiving = true;
    else
      this.isIndependentLiving = false;
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      // id: [''],
      drug: [""],
      doseAndFrequency: [''],
      comments: ['']
    });
  }
  addItem(val?: number): void {
    this.homeClientMedicationDrugProfiles = this.registerForm.get('homeClientMedicationDrugProfiles') as FormArray;
    for (let index = 0; index < val; index++)
      this.homeClientMedicationDrugProfiles.push(this.createItem());

  }
  removeItem(index: number): void {
    this.homeClientMedicationDrugProfiles.removeAt(index);
  }
  get homeClientMedicationProfile() { return (<FormGroup>this.registerForm.controls.homeClientMedicationProfile).controls; }
  get initialNursingClientEvaluation() { return (<FormGroup>this.registerForm.controls.initialNursingClientEvaluation).controls; }
  get d() { return this.registerForm.get('homeClientMedicationDrugProfiles') as FormArray }
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.scroll.scrollToElement(document.getElementsByClassName("invalid-feedback")[0]);
      return;
    }
    (<FormGroup>this.registerForm.controls.homeClientMedicationProfile).setControl('homeClientMedicationDrugProfiles', this.formBuilder.array(this.registerForm.controls.homeClientMedicationDrugProfiles.value))
    this.spinner.showSpinner();
    this.repo.create('api/InitialNursingClientEvaluation/Insert', this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.toastr.success('Form Added Successfully');
            this.onReset();
             this.router.navigate(['/checklist-form-request']);
          }
          else {
            this.toastr.error(data['ReturnMessage']);
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  onReset(): void {
    this.submitted = false;
    this.homeClientMedicationDrugProfiles.value.forEach((e, i) => {
      this.removeItem(i);
    });
    this.registerForm.reset();
    this.registerForm.get('initialNursingClientEvaluation.isAppropriateStaffAvailable').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.serviceRequestPlanCompleted').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.serviceRequest').setValue('');

    this.registerForm.get('initialNursingClientEvaluation.oralTeeth').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.oralDentures').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.oralGums').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.oralAssessment').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.oralHygiene').setValue('');

    this.registerForm.get('initialNursingClientEvaluation.painAny').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.painScale').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.painLocation').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.painClientDescriptionOfPain').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.painObjectiveIndicatorsOfPain').setValue('');

    this.registerForm.get('initialNursingClientEvaluation.integumentaryTemperature').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.integumentaryScalp').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.integumentaryColor').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.integumentaryEvidenceOfParasites').setValue('');

    this.registerForm.get('initialNursingClientEvaluation.musculoskeletalGait').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.musculoskeletalAssistedDevices').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.musculoskeletalAmputation').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.musculoskeletalAmputationLocation').setValue('');

    this.registerForm.get('initialNursingClientEvaluation.genitoUrinaryBowelAndBladder').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.genitoUrinaryFoleyPresent').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.genitoUrinaryUrineCharacteristics').setValue('');

    this.registerForm.get('initialNursingClientEvaluation.gastrointestinalAbdomen').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.gastrointestinalComplaints').setValue('');

    this.registerForm.get('initialNursingClientEvaluation.respiratoryBreathSounds').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.respiratoryCough').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.respiratoryMucousMembranes').setValue('');

    this.registerForm.get('initialNursingClientEvaluation.cardiacHeartRhythm').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.cardiacEdema').setValue('');

    this.registerForm.get('initialNursingClientEvaluation.generalAppearancePersonalHygiene').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.generalAppearanceHearingAid').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.generalAppearanceEars').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.generalAppearanceEyes').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.generalAppearanceGlasses').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.generalAppearanceDrainagePresent').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.generalAppearanceMentalStatus').setValue('');

    this.registerForm.get('initialNursingClientEvaluation.isIndependentLiving').setValue('');
    this.registerForm.get('initialNursingClientEvaluation.admittedFrom').setValue('');

    this.scroll.scrollToElement(document.getElementsByClassName("target")[0]);
  }
  SetPainSectionValidator(val) {
    const isRequired = JSON.parse(val) ? Validators.required : Validators.nullValidator;
    this.registerForm.get('initialNursingClientEvaluation').get('painScale').setValidators(isRequired);
    this.registerForm.get("initialNursingClientEvaluation.painScale").updateValueAndValidity();

    this.registerForm.get('initialNursingClientEvaluation.painLocation').setValidators(isRequired);
    this.registerForm.get("initialNursingClientEvaluation.painLocation").updateValueAndValidity();

    this.registerForm.get('initialNursingClientEvaluation.painClientDescriptionOfPain').setValidators(isRequired);
    this.registerForm.get("initialNursingClientEvaluation.painClientDescriptionOfPain").updateValueAndValidity();

    this.registerForm.get('initialNursingClientEvaluation.painObjectiveIndicatorsOfPain').setValidators(isRequired);
    this.registerForm.get("initialNursingClientEvaluation.painObjectiveIndicatorsOfPain").updateValueAndValidity();

    this.registerForm.get('initialNursingClientEvaluation.painWhatMakesYourPainFeelBetter').setValidators(isRequired);
    this.registerForm.get("initialNursingClientEvaluation.painWhatMakesYourPainFeelBetter").updateValueAndValidity();

    this.registerForm.get('initialNursingClientEvaluation.painWhatMakesYourPainFeelWorse').setValidators(isRequired);
    this.registerForm.get("initialNursingClientEvaluation.painWhatMakesYourPainFeelWorse").updateValueAndValidity();
  }
  SetAmputationValidator(val) {
    const isRequired = JSON.parse(val) ? Validators.required : Validators.nullValidator;
    this.registerForm.get('initialNursingClientEvaluation').get('musculoskeletalAmputationLocation').setValidators(isRequired);
    this.registerForm.get("initialNursingClientEvaluation.musculoskeletalAmputationLocation").updateValueAndValidity();
  }
  SetSplintValidator(val) {
    const isRequired = JSON.parse(val) ? Validators.required : Validators.nullValidator;
    this.registerForm.get('initialNursingClientEvaluation').get('musculoskeletalSplintsLocation').setValidators(isRequired);
    this.registerForm.get("initialNursingClientEvaluation.musculoskeletalSplintsLocation").updateValueAndValidity();
  }
  SetEvidenceParasitesValidator(val) {
    const isRequired = JSON.parse(val) ? Validators.required : Validators.nullValidator;
    this.registerForm.get('initialNursingClientEvaluation').get('integumentaryEvidenceOfParasitesLocation').setValidators(isRequired);
    this.registerForm.get("initialNursingClientEvaluation.integumentaryEvidenceOfParasitesLocation").updateValueAndValidity();
  }
  SetFoleyPresentValidator(val) {
    const isRequired = JSON.parse(val) ? Validators.required : Validators.nullValidator;
    this.registerForm.get('initialNursingClientEvaluation').get('genitoUrinarySize').setValidators(isRequired);
    this.registerForm.get("initialNursingClientEvaluation.genitoUrinarySize").updateValueAndValidity();
  }
  SetCardiacEdemaValidator(val) {
    const isRequired = JSON.parse(val) ? Validators.required : Validators.nullValidator;
    this.registerForm.get('initialNursingClientEvaluation').get('cardiacLocation').setValidators(isRequired);
    this.registerForm.get("initialNursingClientEvaluation.cardiacLocation").updateValueAndValidity();
  }
}
