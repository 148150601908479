<div class="row" style="overflow: hidden !important;margin-left: 0px; margin-right: 0px;">
  <div class="col-md-10 offset-md-1">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <div class="row">
          <div class="col">
            <h5 style="float: left;padding: .75rem 1.25rem">Create New User</h5>
          </div>
        </div>
      <div class="card-body">
        <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>Full Name</label>
            </div>
            <div class="form-group col-md-6">
              <input autocomplete="off" formControlName="name" id="name" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                placeholder="Please Enter Full Name"/>

              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Full Name is required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>UserName</label>
            </div>
            <div class="form-group col-md-6">
              <input autocomplete="off" formControlName="userName" id="userName" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.userName.errors }"
                placeholder="Please Enter User Name"/>

              <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                <div *ngIf="f.userName.errors.required">UserName is required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>Email Address</label>
            </div>
            <div class="form-group col-md-6">
              <input autocomplete="off" formControlName="email" id="email" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                placeholder="Please Enter Email Address"/>

              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>Password</label>
            </div>
            <div class="form-group col-md-6">
              <input autocomplete="new-password" formControlName="password" class="form-control" type="password"
                placeholder="Please Enter Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                  <div *ngIf="f.password.errors.pattern">Atleast 1UpperCase, 1Lower Case, 1Number and 1Special characters required</div>
                </div>
            </div>
          </div>
          <!-- <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>Confirm Password</label>
            </div>
            <div class="form-group col-md-6">
              <input autocomplete="new-password" formControlName="confrimpasswords" class="form-control" type="password"
                placeholder="Please Enter Confrim Password"
                [ngClass]="{ 'is-invalid': submitted && f.confrimpasswords.errors }">
                <div *ngIf="submitted && f.confrimpasswords.errors" class="invalid-feedback">
                  <div *ngIf="f.confrimpasswords.errors.required">Confirm Password is required</div>
                  <div *ngIf="f.confrimpasswords.errors.mustMatch">Passwords must match</div>
                </div>
            </div>
          </div> -->
          <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>User Type</label>
            </div>
            <div class="form-group col-md-6">
            <select autocomplete="off" formControlName="type" id="client" class="form-control js-example-basic-single"
            [ngClass]="{ 'is-invalid': submitted && f.type.errors }">

            <option value="" selected>Select User Type</option>
            <option value="0" >Admin</option>
            <option value="1" >User</option>
            <option value="3" >Nurse</option>
          </select>
          <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
            <div *ngIf="f.type.errors.required">User Type is required</div>
          </div>
            </div>
          </div>
          <div class="form-row row">
            <div class="form-group col-md-10">
              <div class="text-right">
                <button style="margin-right: 10px" class="btn btn-outline-danger" type="reset" (click)="onReset()">
                  <i class="fa fa-remove"></i>
                  Cancel</button>
                <button class="btn btn-custom mr-1" style="width: 90px;">
                  <i class="fa fa-plus"></i> Create</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "fire" [fullScreen] = "true">
  <p style="color: white" > Loading... </p>
</ngx-spinner>
