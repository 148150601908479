import { Injectable } from '@angular/core';
import { Excel } from '../../model/excel.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public excelData: Excel[] = [];
  constructor() {

    if (localStorage.getItem('excelData') != null) {
      this.excelData = JSON.parse(localStorage.getItem('excelData'));
    }

  }
}
