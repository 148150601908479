import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { MeridiemType } from 'src/app/enum/meridiemtype.enum';
import { StatusType } from 'src/app/enum/statustype.enum';
import { ClientBillingConfiguration } from 'src/app/model/clientbillingconfiguration.model';
import { EmployeeHourReport } from '../../../model/employeehoursheet.model';
import { RepositoryService } from '../../services/repository.service';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-employeehourslist',
  templateUrl: './employeehourslist.component.html',
  styleUrls: ['./employeehourslist.component.css']
})
export class EmployeehourslistComponent implements OnInit {
  @Input() clientBillingConfigurations: ClientBillingConfiguration[];
  @Input() employeehours: EmployeeHourReport[];
  @Input() employeeStartDate = '';
  @Input() employeeEndDate = '';
  @Input() totalRegular = 0;
  @Input() totalHomeCare = 0;
  @Input() totalFullDay = 0;
  @Input() totalOT = 0;
  @Input() totalHoliday = 0;
  @Input() totalHolidayOT = 0;
  @Output() onclientBillingConfigurationsUpdate = new EventEmitter<any>();
  public clientBillingConfigurationsUpdate: ClientBillingConfiguration[];
  @Input() events: Observable<ClientBillingConfiguration[]>;
  private eventsSubscription: Subscription;
  statusType = StatusType;

  constructor(private repo: RepositoryService,
    private toastr: ToastrService, private spinner: SpinnerService) {

  }
  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.eventsSubscription = this.events.subscribe((data) => this.OldState());
    this.clientBillingConfigurationsUpdate = JSON.parse(JSON.stringify(this.clientBillingConfigurations)) as ClientBillingConfiguration[];
  }

  UpdateData(isChecked, index, mainIndex, type,) {
    this.clientBillingConfigurationsUpdate.forEach((e, i) => {
      if (i == mainIndex) {
        e.clientBillingDayConfigurations.forEach((ele, j) => {
          if (j == index) {
            if (isChecked) {
              this.clientBillingConfigurations.forEach((el, k) => {
                if (k == mainIndex) {
                  el.clientBillingDayConfigurations.forEach((elem, o) => {
                    if (o == index) {
                      if (this.nameof(ele, x => x.sunday) == type)
                        ele.sunday = elem.sunday;
                      else if (this.nameof(ele, x => x.monday) == type)
                        ele.monday = elem.monday;
                      else if (this.nameof(ele, x => x.tuesday) == type)
                        ele.tuesday = elem.tuesday;
                      else if (this.nameof(ele, x => x.wednesday) == type)
                        ele.wednesday = elem.wednesday;
                      else if (this.nameof(ele, x => x.thursday) == type)
                        ele.thursday = elem.thursday;
                      else if (this.nameof(ele, x => x.friday) == type)
                        ele.friday = elem.friday;
                      else if (this.nameof(ele, x => x.saturday) == type)
                        ele.saturday = elem.saturday;
                    }
                  });
                }
              });
            }
            else {
              if (this.nameof(ele, x => x.sunday) == type)
                ele.sunday = 0;
              else if (this.nameof(ele, x => x.monday) == type)
                ele.monday = 0;
              else if (this.nameof(ele, x => x.tuesday) == type)
                ele.tuesday = 0;
              else if (this.nameof(ele, x => x.wednesday) == type)
                ele.wednesday = 0;
              else if (this.nameof(ele, x => x.thursday) == type)
                ele.thursday = 0;
              else if (this.nameof(ele, x => x.friday) == type)
                ele.friday = 0;
              else if (this.nameof(ele, x => x.saturday) == type)
                ele.saturday = 0;
            }
          }
        });
      }
    });
    this.onclientBillingConfigurationsUpdate.emit(this.clientBillingConfigurationsUpdate);
  }
  AddNewValue(event: any, index, mainIndex, type) {
    var val = Number(event.target.value);
    this.clientBillingConfigurationsUpdate.forEach((e, i) => {
      if (i == mainIndex) {
        e.clientBillingDayConfigurations.forEach((ele, j) => {
          if (j == index) {
            if (this.nameof(ele, x => x.sunday) == type)
              ele.sunday = val;
            else if (this.nameof(ele, x => x.monday) == type)
              ele.monday = val;
            else if (this.nameof(ele, x => x.tuesday) == type)
              ele.tuesday = val;
            else if (this.nameof(ele, x => x.wednesday) == type)
              ele.wednesday = val;
            else if (this.nameof(ele, x => x.thursday) == type)
              ele.thursday = val;
            else if (this.nameof(ele, x => x.friday) == type)
              ele.friday = val;
            else if (this.nameof(ele, x => x.saturday) == type)
              ele.saturday = val;
          }
        });
      }
    });
    this.clientBillingConfigurations.forEach((e, i) => {
      if (i == mainIndex) {
        e.clientBillingDayConfigurations.forEach((ele, j) => {
          if (j == index) {
            if (this.nameof(ele, x => x.sunday) == type)
              ele.sunday = val;
            else if (this.nameof(ele, x => x.monday) == type)
              ele.monday = val;
            else if (this.nameof(ele, x => x.tuesday) == type)
              ele.tuesday = val;
            else if (this.nameof(ele, x => x.wednesday) == type)
              ele.wednesday = val;
            else if (this.nameof(ele, x => x.thursday) == type)
              ele.thursday = val;
            else if (this.nameof(ele, x => x.friday) == type)
              ele.friday = val;
            else if (this.nameof(ele, x => x.saturday) == type)
              ele.saturday = val;
          }
        });
      }
    });
  }
  GetMeridiemType(val) {
    if (val == null)
      return 'N/A';
    return MeridiemType[val];
  }
  nameof<T>(obj: T, expression: (x: { [Property in keyof T]: () => string }) => () => string): string {
    const res: { [Property in keyof T]: () => string } = {} as { [Property in keyof T]: () => string };

    Object.keys(obj).map(k => res[k as keyof T] = () => k);

    return expression(res)();
  }

  OldState(): void {
    this.clientBillingConfigurationsUpdate = JSON.parse(JSON.stringify(this.clientBillingConfigurations)) as ClientBillingConfiguration[];
  }
}

