<div class="row" style="margin: 0; height: 100%; overflow: hidden">
  <div class="col-12 col-md-4 bg-success vh-100 d-none .d-sm-none d-md-block">
    <div class="mainText p-5">
      <h1>Welcome to <br> <span style="font-size: 30px;">Gentle Shepherd Care</span> </h1>
      <!-- <p>Login here to access APIs</p> -->
     </div>
  </div>

  <div class="col-12 col-md-8">
     <div class="card marginAuto shadow p-4 mx-auto">
      <div class="card-body">
        <div class="d-flex justify-content-center ">
          <img src="../../../assets/lmages/Gentle-Shepherd-Care.png" style="width: 287px;height: 60px;" class="brand_logo" alt="Logo">
        </div>

        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="justify-content-center" action="" method="post">
          <div class="text-center my-4">
            <h4>Gentle <span class="text-custom">Shepherd Care<sup style="font-size:12px;top:-10px">TM</sup></span>
            </h4>
          </div>

          <div class="form-group">
            <div class="input-group my-4">
              <div class="input-group-prepend">
              <span class="input-group-text bg-success text-white">
                <i class="fa fa-user"></i>
              </span>
              </div>
              <input formControlName="username" type="text" name="username" class="form-control input_user" placeholder="username"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }">

              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Email is required</div>
                <!-- <div *ngIf="f.email.errors.email">Email must be valid</div> -->
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="input-group my-4">
              <div class="input-group-prepend">
              <span class="input-group-text bg-success text-white">
                <i class="fa fa-key"></i>
              </span>
              </div>
              <input formControlName="password" type="password" name="password" class="form-control input_pass" placeholder="password"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
              </div>
            </div>
          </div>

          <div class="text-center px-4">
            <button type="submit" class="btn btn-custom btn-lg  btn-block" id="loginBtn">Login</button>
            <a  routerLink="/forgot-password" class="forgotpasswordlink btn-link" >forgot password?</a>
          </div>

          <div id="loginStatus" class="text-danger text-center mt-3 d-none"></div>
        </form>
      </div>
    </div>
  </div>

</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "fire" [fullScreen] = "true">
  <p style="color: white" > Loading... </p>
</ngx-spinner>
