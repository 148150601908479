<div class="row">
  <div class="col-md-12 ">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <div class="row">
          <div class="col">
            <h5 style="float: left;padding: .75rem 1.25rem">Add Shift Request Configuration</h5>
          </div>
        </div>
        <div class="card-body">
          <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-row row">
              <div class="col-md-3 col-lg-3">
                <label>Facility/Community Name</label>
                <input placeholder="Enter Facility/Community Name" type="text" formControlName="communityName"
                  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.communityName.errors }" />
                <div *ngIf="submitted && f.communityName.errors" class="invalid-feedback">
                  <div *ngIf="f.communityName.errors.required">Facility/Community Name is required</div>
                </div>
              </div>
              <div class="col-md-2 col-lg-2">
                <label>Shift Role</label>
                <select formControlName="shiftRole" class="form-control js-example-basic-single select2"
                  [ngClass]="{ 'is-invalid': submitted && f.shiftRole.errors }">
                  <option value="" selected>Select Shift Role</option>
                  <option value="CNA">CNA</option>
                  <option value="Medtech">Medtech</option>
                  <option value="LPN">LPN</option>
                  <option value="Nurses">Nurses</option>
                </select>
                <div *ngIf="submitted && f.shiftRole.errors" class="invalid-feedback">
                  <div *ngIf="f.shiftRole.errors.required">Shift Role is required</div>
                </div>
              </div>
              <div class="col-md-2 col-lg-2">
                <label>Shift Type</label>
                <select formControlName="shiftType" class="form-control js-example-basic-single select2"
                  [ngClass]="{ 'is-invalid': submitted && f.shiftType.errors }">

                  <option value="" selected>Select Shift Type</option>
                  <option value="1st Shift">1st Shift</option>
                  <option value="2nd Shift">2nd Shift</option>
                  <option value="3rd Shift">3rd Shift</option>
                </select>
                <div *ngIf="submitted && f.shiftType.errors" class="invalid-feedback">
                  <div *ngIf="f.shiftType.errors.required">Shift Type is required</div>
                </div>
              </div>
              <div class="col-md-3 col-lg-3">
                <label>Shift Hour</label>
                <input placeholder="Enter Shift Hour" type="text" formControlName="shiftHour" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.shiftHour.errors }" />
                <div *ngIf="submitted && f.shiftHour.errors" class="invalid-feedback">
                  <div *ngIf="f.shiftHour.errors.required">Shift Hour is required</div>
                </div>
              </div>
              <div class="col-md-2 col-lg-2">
                <button type="submit" class="btn btn-custom" style="position: absolute;top: 30px;">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-10 offset-md-1">
    <div class="card m-3 beautifyBox">
      <div class="">
        <table class="table table-striped">
          <thead class="custom-table-header-color">
            <tr>
              <th scope="col">Facility/Community Name</th>
              <th scope="col">Shift Role</th>
              <th scope="col">Shift Type </th>
              <th scope="col">Shift Hours</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="shiftRequestConfigurationList.length > 0; else elseBlock">
              <tr *ngFor="let d of shiftRequestConfigurationList; index as i">
                <td width="40%">
                  <p class="">{{ d.communityName}}</p>
                </td>
                <td>
                  <p class="">{{ d.shiftRole}}</p>
                </td>
                <td>
                  <p class="">{{ d.shiftType }}</p>
                </td>
                <td>
                  <p class="">{{ d.shiftHour }}</p>
                </td>
                <td width="10%">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-sliders"></i>
                    </button>
                    <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <button class="dropdown-item"
                        (click)="OpenPopup(d.id,d.communityName,d.shiftRole,d.shiftType,d.shiftHour)">Edit</button>
                      <button class="dropdown-item" (click)="Delete(i,d.id)">Delete</button>
                    </div>

                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #elseBlock>
              <tr>
                <td colspan="10"></td>
              </tr>
              <tr>
                <td colspan="10" class="text-center">No Record Found......</td>
              </tr>
              <tr>
                <td colspan="10"></td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div [ngStyle]="{'display':displayStyle}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content" style="width:135%">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Edit Shift Request Configuration</h5>
        <button (click)="ClosePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-3">
            <label>Facility/Community Name</label>
          </div>
          <div class="col-md-8">
            <input id="communityName" autocomplete="off" placeholder="Enter Facility/Community Name" type="text"
              [(ngModel)]="updateModel.communityName" class="form-control" />
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-3">
            <label>Shift Role</label>
          </div>
          <div class="col-md-8">
            <select [(ngModel)]="updateModel.shiftRole" class="form-control">
              <option value="CNA">CNA</option>
              <option value="Medtech">Medtech</option>
              <option value="LPN">LPN</option>
              <option value="Nurses">Nurses</option>
            </select>
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-3">
            <label>Shift Type</label>
          </div>
          <div class="col-md-8">
            <select [(ngModel)]="updateModel.shiftType" class="form-control">
              <option value="1st Shift">1st Shift</option>
              <option value="2nd Shift">2nd Shift</option>
              <option value="3rd Shift">3rd Shift</option>
            </select>
          </div>
        </div>
        <div class="row mt5">
          <div class="col-md-3">
            <label>Shift Hour</label>
          </div>
          <div class="col-md-8">
            <input placeholder="Enter Shift Hour" type="text" [(ngModel)]="updateModel.shiftHour" class="form-control"/>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="Update()" type="button" class="btn btn-custom">Update</button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>
