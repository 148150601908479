<style>
  table thead tr th {
    font-weight: 500 !important;
  }

  .modal-dialog {
    max-width: 800px !important;
  }

  .modal-content {
    width: 800px !important;
  }
</style>
<div class="row">
  <div class="col-md-12">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <h5 style="float: left;padding: .75rem 1.25rem">Travel Job Applicants
          <span style="font-size: 12px;" *ngIf="employeeJobList.length > 0">(Total
            {{employeeJobList.length}} Applicant)</span>
        </h5>
      </div>
      <div>
        <table class="table table-striped">
          <thead class="custom-table-header-color">
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Experience</th>
              <th>Currently Employeed</th>
              <th>Job Status</th>
              <th>Applied For</th>
              <th></th>
              <th>Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="employeeJobList.length > 0; else elseBlock">
              <tr *ngFor="let d of employeeJobList; index as i">
                <td>
                  <p>{{ d.firstName}} {{ d.lastName}}</p>
                </td>
                <td>
                  <p>{{ d.email}}</p>
                </td>
                <td>
                  <p>{{ d.phoneNumber}}</p>
                </td>
                <td>
                  <p>{{ d.yearsExperience}} {{d.yearsExperience == '1'? 'year':'years'}}</p>
                </td>
                <td>
                  <p>{{ d.isEmployeed == 1 ?'Yes': 'No' }}</p>
                </td>
                <td>
                  <p>{{ GetJobStatus(d.jobStatus)}}</p>
                </td>
                <td>
                  <p class="view_more" (click)="OpenPopupJob(d.travelJob)">view job details</p>
                  <!-- <p>{{ d.travelJobId}}</p> -->
                </td>
                <td>
                  <p class="view_more" (click)="OpenPopup(d)">view more details</p>
                </td>
                <td>
                  <p>{{ d.created | date : 'MMM dd yyyy hh:mm a' }}</p>
                </td>
                <td width="10%">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-sliders"></i>
                    </button>
                    <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <!-- <button class="dropdown-item" (click)="ChangeStatus(d.status,d.id)">Change Status to {{ d.status
                        == jobStatus.Active? 'Disabled' : 'Active' }}</button> -->
                      <button class="dropdown-item" (click)="OpenPopupStatus(d.id,d.jobStatus)">Change Status</button>
                      <button class="dropdown-item" (click)="Delete(i,d.id)">Delete</button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #elseBlock>
              <tr>
                <td colspan="21"></td>
              </tr>
              <tr>
                <td colspan="21" class="text-center">No Record Found......</td>
              </tr>
              <tr>
                <td colspan="21"></td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div [ngStyle]="{'display':displayStyle}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <div class="row" style="width: 620px;">
          <div class="col-md-6">
            <h6 class="modal-title">Applicant Detail </h6>
          </div>
          <div class="col-md-6">
            <ng-container *ngIf="employeeJob">
              <span>
                Job Satus : {{ GetJobStatus(employeeJob.jobStatus) }}
              </span>
            </ng-container>
          </div>
        </div>
        <button (click)="ClosePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="employeeJob">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th style="width: 150px;">First Name</th>
                    <td>{{employeeJob.firstName}}</td>
                    <th>Last Name</th>
                    <td>{{employeeJob.lastName}}</td>
                  </tr>
                  <tr>
                    <th>Email <Address></Address>
                    </th>
                    <td>{{employeeJob.email}}</td>
                    <th>Phone Number</th>
                    <td>{{employeeJob.phoneNumber}}</td>
                  </tr>
                  <tr>
                    <th>SSN</th>
                    <td>{{employeeJob.ssn}}</td>
                    <th>City</th>
                    <td>{{employeeJob.city}}</td>
                  </tr>
                  <tr>
                    <th>State</th>
                    <td>{{employeeJob.state}}</td>
                    <th>Address</th>
                    <td>{{employeeJob.address }}</td>
                  </tr>
                  <tr>
                    <th>Experence</th>
                    <td>{{employeeJob.yearsExperience}}</td>
                    <th>Currently Employeed?</th>
                    <td>{{employeeJob.isEmployeed == 1 ? 'Yes' : 'No' }}</td>
                  </tr>
                  <tr>
                    <th>Started</th>
                    <td>{{employeeJob.whenStarted}}</td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngStyle]="{'display':displayStyle2}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Job Detail Satus :
          <ng-container *ngIf="jobConfiguration">
            <span class="text-bold"
              [ngClass]="{ 'text-green': jobConfiguration.status == jobMainStatus.Active, 'text-danger': jobConfiguration.status == jobMainStatus.Disabled }">
              {{ jobConfiguration.status == jobMainStatus.Active? 'Active' : 'Disabled' }}
            </span>
          </ng-container>
        </h5>
        <button (click)="ClosePopupJob()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="jobConfiguration">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th style="width: 150px;">Years Experience</th>
                    <td>{{jobConfiguration.yearsExperience}}</td>
                    <th>Worksite</th>
                    <td>{{jobConfiguration.worksite}}</td>
                  </tr>
                  <tr>
                    <th>Position ID</th>
                    <td>{{jobConfiguration.positionID}}</td>
                    <th>Worksite Address</th>
                    <td>{{jobConfiguration.worksiteAddress}}</td>
                  </tr>
                  <tr>
                    <th>Position Name</th>
                    <td>{{jobConfiguration.positionName}}</td>
                    <th>City</th>
                    <td>{{jobConfiguration.city}}</td>
                  </tr>
                  <tr>
                    <th>Position Type</th>
                    <td>{{jobConfiguration.positionType}}</td>
                    <th>Projected Start Date</th>
                    <td>{{jobConfiguration.projectedStartDate | date : 'MMM dd yyyy' }}</td>
                  </tr>
                  <tr>
                    <th>Position Status</th>
                    <td>{{jobConfiguration.statusType}}</td>
                    <th>Duration Amount</th>
                    <td>{{jobConfiguration.durationAmount}}</td>
                  </tr>
                  <tr>
                    <th>State</th>
                    <td>{{jobConfiguration.state}}</td>
                    <th>Start Time</th>
                    <td>{{jobConfiguration.startTime}}</td>
                  </tr>
                  <tr>
                    <th>Base Rate</th>
                    <td>{{jobConfiguration.baseRate}}$</td>
                    <th>End Time</th>
                    <td>{{jobConfiguration.endTime}}</td>
                  </tr>
                  <tr>
                    <th>Company</th>
                    <td>{{jobConfiguration.company}}</td>
                    <th>Published On</th>
                    <td>{{jobConfiguration.publishedOn| date : 'MMM dd yyyy'}}</td>
                  </tr>
                  <tr>
                    <th>Client</th>
                    <td>{{jobConfiguration.client}}</td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngStyle]="{'display':displayStyle3}" class="modal" tabindex="-1">
  <div class="modal-dialog" style="margin-top: 160px;max-width: 500px !important;">
    <!-- modal-dialog-centered -->
    <div class=" modal-content" style="width: 400px !important;">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Change Satus
        </h5>
        <button (click)="ClosePopupStatus()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <select [(ngModel)]="updateModel.jobStatus" class="form-control">
                  <ng-container *ngFor="let item of jobStatus| keyvalue">
                    <option *ngIf="isEnumName(item)" [selected]="item.key == updateModel.jobStatus" [value]="item.key">
                      {{item.value}}
                    </option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-12 mt10">
                <button type="button" (click)="UpdateStatus()" class="btn btn-block btn-custom">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
