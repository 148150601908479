<nav style="height: 70px;" class="navbar navbar-expand-lg navbar-light bg-light"
  *ngIf="isLoggedIn$ | async as isLoggedIn">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="../../assets/lmages/Gentle-Shepherd-Care.png" width="180px" />
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor02"
      aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarColor02">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="margin-left: -85;">
        <ng-container *ngIf="this.authService.isSuperAdminUser.value || this.authService.isAdminUser.value || this.authService.isUser.value">
          <li class="nav-item">
            <a routerLink="/" routerLinkActive="active" class="nav-link active" style="margin-left: 100px;"
              aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a routerLink="/review" routerLinkActive="active" class="nav-link active" aria-current="page"
              href="#">Review
              Schedule</a>
          </li>
          <li class="nav-item">
            <a target="_blank" class="nav-link active" aria-current="page" href="http://shiftrequest.gsctools.com">Shift
              Request</a>
          </li>
          <li class="nav-item">
            <a routerLink="/change-request" routerLinkActive="active" class="nav-link active" aria-current="page"
              href="#">Change Request
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/travel-applicants" routerLinkActive="active" class="nav-link active" aria-current="page"
              href="#">Applicants</a>
          </li>
        </ng-container>

        <ng-container *ngIf="this.authService.isNurseUser.value">
          <li class="nav-item">
            <a routerLink="/medication-form-request" routerLinkActive="active" class="nav-link active"
              aria-current="page" href="#">
              Medication Request
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/checklist-form-request" routerLinkActive="active" class="nav-link active"
              aria-current="page" href="#">
              Checklist Request
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/aide-evaluation-request" routerLinkActive="active" class="nav-link active"
              aria-current="page" href="#">
              Aide Evaluation Request
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="this.authService.isSuperAdminUser.value">
          <li class="nav-item dropdown active">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              User Management
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a routerLink="/create-new-user" routerLinkActive="active" class="dropdown-item" aria-current="page"
                href="#">Create New User</a>
              <a routerLink="/users-list" routerLinkActive="active" class="dropdown-item" aria-current="page"
                href="#">Users List</a>
            </div>
          </li>
        </ng-container>
        <ng-container *ngIf="this.authService.isAdminUser.value">
          <li class="nav-item dropdown active">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              Reports
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a routerLink="/employee-timesheet-report" routerLinkActive="active" class="dropdown-item"
                aria-current="page" href="#">Billing Report</a>

              <a routerLink="/va-employee-billing-report" routerLinkActive="active" class="dropdown-item"
                aria-current="page" href="#">VA Billing Report
                <span class="badge bg-custom">New</span>
              </a>

              <a routerLink="/employee-payroll-report" routerLinkActive="active" class="dropdown-item"
                aria-current="page" href="#">Payroll Report</a>

              <a routerLink="/employee-checklist-report" routerLinkActive="active" class="dropdown-item"
                aria-current="page" href="#">Checklist Report</a>

              <a routerLink="/pm-client-employee-report" routerLinkActive="active" class="dropdown-item"
                aria-current="page" href="#">PM Estimate Report
                <span class="badge bg-custom">New</span>
              </a>
              <a routerLink="/initial-nursing-evaluation-report" routerLinkActive="active" class="dropdown-item"
                aria-current="page" href="#">Initial Nursing-Evaluation Report
                <span class="badge bg-custom">New</span>
              </a>
            </div>
          </li>
        </ng-container>
      </ul>
      <ul class="navbar-nav ml-auto">
        <!-- <li class="nav-item">
          <span *ngIf="isLoggedIn">Hi {{userName$.value}}</span>
          <a style="margin-left: 30px;" (click)="onLogout()" *ngIf="isLoggedIn"
          class="btn btn-custom my-2 my-sm-0" aria-current="page" href="#">Logout</a>
        </li> -->
        <li class="nav-item dropdown active">
          <a class="nav-link dropdown-toggle btn btn-custom" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <span *ngIf="isLoggedIn">Hi {{userName$.value}}</span>
          </a>
          <div style="min-width: 100%" class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a *ngIf="this.authService.isSuperAdminUser.value" routerLink="/settings" routerLinkActive="active"
              class="dropdown-item" aria-current="page">
              <i class="fa fa-cog mr5"></i> Setting</a>

            <a (click)="onLogout()" *ngIf="isLoggedIn" class="dropdown-item" aria-current="page" href="#">
              <i class="fa fa-sign-out mr5" aria-hidden="true"></i> Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
