<div class="row noprint">
  <div class="col-md-12 ">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <div class="row">
          <div class="col">
            <h5 style="float: left;padding: .75rem 1.25rem">Employee Billing Report</h5>
            <a [routerLink]="[ '/quickbook-configuration']" target="_blank" style="float: right;margin-top: 8px;margin-right: 8px;"  class="btn btn-custom">Quick Book Configuration</a>
            <a [routerLink]="[ '/client-billing-configuration']" target="_blank" style="float: right;margin-top: 8px;margin-right: 8px;"  class="btn btn-custom">Providence Meadows Clients Configuration</a>
          </div>
        </div>
        <div class="card-body">
          <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-row row">
              <div class="col-md-4 col-lg-3">
                <label>Report Date Range</label>
                <select formControlName="definedDate" (change)="ChangeDateType(this.registerForm.value.definedDate)" id="definedDate" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.definedDate.errors }">
                  <option *ngFor="let item of dateList"  value="{{item.date}}">
                    {{item.showDate}}
                  </option>

                </select>
                <div class="row mt5" *ngIf="showCustomDateRange">
                  <div class="col-md-6">
                    <input id="datetimepicker1"  autocomplete="off" placeholder="Select Start Date" type="text"
                      formControlName="startDate" class="form-control datetimepicker"
                      [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }" />
                  </div>
                  <div class="col-md-6">
                    <input id="datetimepicker2" autocomplete="off" placeholder="Select End Date" type="text"
                      formControlName="endDate" class="form-control datetimepicker"
                      [ngClass]="{ 'is-invalid': submitted && f.endDate.errors }" />
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-lg-3">
                <label>Employees</label>
                <select formControlName="employees" id="employees" class="form-control js-example-basic-single select2"
                  [ngClass]="{ 'is-invalid': submitted && f.employees.errors }">

                  <option value="0" selected>All Employees</option>
                  <option *ngFor="let emp of employeeList"
                    value="{{emp.employee_number}}">
                    {{emp.employee_first_name}} {{emp.employee_last_name}} - {{emp.employee_number}}
                  </option>

                </select>
                <div *ngIf="submitted && f.employees.errors" class="invalid-feedback">
                  <div *ngIf="f.employees.errors.required">Employee is required</div>
                </div>
              </div>
              <div class="col-md-3 col-lg-3">
                <label>Sites</label>
                <select formControlName="sites" id="sites"
                  class="form-control select2 js-example-basic-single"
                  [ngClass]="{ 'is-invalid': submitted && f.sites.errors }">
                  <option value="0" selected>All Sites</option>
                  <option *ngFor="let job of jobList" value="{{job.job_number}}">
                    {{job.job_name}} - {{job.job_number}}
                  </option>
                </select>
                <div *ngIf="submitted && f.sites.errors" class="invalid-feedback">
                  <div *ngIf="f.sites.errors.required">Site is required</div>
                </div>
              </div>
              <div class="col-md-1 col-lg-1">
                <button type="submit" class="btn btn-custom" style="position: absolute;top: 30px;">Run Report</button>
              </div>
              <div class="col-md-1 col-lg-1">
                <button type="button" (click)="GenerateQuickBookCSV(f)" class="btn btn-custom" style="position: absolute;top: 30px;">Generate CSV</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="employeeHoursList">
  <app-employeehourslist [events]="eventsSubject.asObservable()"  (onclientBillingConfigurationsUpdate)="clientBillingConfigurationsUpdate($event)" [clientBillingConfigurations]="clientBillingConfigurationList" [employeehours]="employeeHoursList" [employeeStartDate]="employeeStartDate" [employeeEndDate]="employeeEndDate" [totalRegular]="totalRegular" [totalOT]="totalOT" [totalHoliday]="totalHoliday" [totalHolidayOT]="totalHolidayOT" [totalHomeCare]="totalHomeCare" [totalFullDay]="totalFullDay"></app-employeehourslist>
</ng-container>
<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner> -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
  </ngx-spinner>





