<style>
  table thead tr th {
    font-weight: 500 !important;
  }
  .modal-dialog{
    max-width: 800px !important;
  }
  .modal-content{
    width: 800px !important;
  }
  .view_more{
    font-size: 13px;
    color: #7e54c6;
    cursor: pointer;
  }
</style>
<div class="row">
  <div class="col-md-12">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <h5 style="float: left;padding: .75rem 1.25rem">Job Configuration
          <span style="font-size: 12px;" *ngIf="jobConfigurationList.length > 0">(Total
            {{jobConfigurationList.length}} Jobs)</span>
        </h5>
        <button (click)="fileInput.click()" style="float: right; margin-top: 5px;" type="button"
          class="btn btn-custom mr-1">
          <i class="fa fa-upload"></i> Import With Excel</button>
        <input accept=".xlsx,.xls" (change)="onFileChanged($event)" #fileInput class="uploadfile" type="file"
          style="display: none;">
        <!-- <button (click)="OpenPopupForCreate()" style="float: right; margin-top: 5px;" type="button"
          class="btn btn-custom mr-1">
          <i class="fa fa-plus"></i> Add New </button> -->
      </div>
      <div>
        <table class="table table-striped">
          <thead class="custom-table-header-color">
            <tr>
              <th>Position ID</th>
              <th>Position Name</th>
              <th>Position Type</th>
              <th>Position Status</th>
              <th>Position State</th>
              <th>Base Rate</th>
              <th>Client</th>
              <th>Status</th>
              <th>Created</th>
              <th></th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="jobConfigurationList.length > 0; else elseBlock">
              <tr *ngFor="let d of jobConfigurationList; index as i">
                <td>
                  <p>{{ d.positionID}}</p>
                </td>
                <td>
                  <p>{{ d.positionName}}</p>
                </td>
                <td>
                  <p>{{ d.positionType}}</p>
                </td>
                <td>
                  <p>{{ d.statusType}}</p>
                </td>
                <td>
                  <p>{{ d.state}}</p>
                </td>
                <td>
                  <p>{{ d.baseRate}}$</p>
                </td>
                <td>
                  <p>{{ d.client}}</p>
                </td>
                <td>
                  <p class="text-bold"
                    [ngClass]="{ 'text-green': d.status == jobStatus.Active, 'text-danger': d.status == jobStatus.Disabled }">
                    {{ d.status == jobStatus.Active? 'Active' : 'Disabled' }}</p>
                </td>
                <td>
                  <p>{{ d.created | date : 'MMM dd yyyy hh:mm a' }}</p>
                </td>
                <td>
                  <p class="view_more" (click)="OpenPopup(d)" >view more details</p>
                </td>
                <td width="10%">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-sliders"></i>
                    </button>
                    <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <button class="dropdown-item" (click)="ChangeStatus(d.status,d.id)">Change Status to {{ d.status
                        == jobStatus.Active? 'Disabled' : 'Active' }}</button>
                      <button class="dropdown-item" (click)="Delete(i,d.id)">Delete</button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #elseBlock>
              <tr>
                <td colspan="21"></td>
              </tr>
              <tr>
                <td colspan="21" class="text-center">No Record Found......</td>
              </tr>
              <tr>
                <td colspan="21"></td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div [ngStyle]="{'display':displayStyle}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Job Detail Satus :
          <ng-container *ngIf="jobConfiguration">
            <span class="text-bold"
                    [ngClass]="{ 'text-green': jobConfiguration.status == jobStatus.Active, 'text-danger': jobConfiguration.status == jobStatus.Disabled }">
                    {{ jobConfiguration.status == jobStatus.Active? 'Active' : 'Disabled' }}
                  </span>
          </ng-container>
         </h5>
        <button (click)="ClosePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="jobConfiguration">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th style="width: 150px;">Years Experience</th>
                  <td>{{jobConfiguration.yearsExperience}}</td>
                  <th>Worksite</th>
                  <td>{{jobConfiguration.worksite}}</td>
                </tr>
                <tr>
                  <th>Position ID</th>
                  <td>{{jobConfiguration.positionID}}</td>
                  <th>Worksite Address</th>
                  <td>{{jobConfiguration.worksiteAddress}}</td>
                </tr>
                <tr>
                  <th>Position Name</th>
                  <td>{{jobConfiguration.positionName}}</td>
                  <th>City</th>
                  <td>{{jobConfiguration.city}}</td>
                </tr>
                <tr>
                  <th>Position Type</th>
                  <td>{{jobConfiguration.positionType}}</td>
                  <th>Projected Start Date</th>
                  <td>{{jobConfiguration.projectedStartDate | date : 'MMM dd yyyy' }}</td>
                </tr>
                <tr>
                  <th>Position Status</th>
                  <td>{{jobConfiguration.statusType}}</td>
                  <th>Duration Amount</th>
                  <td>{{jobConfiguration.durationAmount}}</td>
                </tr>
                <tr>
                  <th>State</th>
                  <td>{{jobConfiguration.state}}</td>
                  <th>Start Time</th>
                  <td>{{jobConfiguration.startTime}}</td>
                </tr>
                <tr>
                  <th>Base Rate</th>
                  <td>{{jobConfiguration.baseRate}}$</td>
                  <th>End Time</th>
                  <td>{{jobConfiguration.endTime}}</td>
                </tr>
                <tr>
                  <th>Company</th>
                  <td>{{jobConfiguration.company}}</td>
                  <th>Published On</th>
                  <td>{{jobConfiguration.publishedOn| date : 'MMM dd yyyy'}}</td>
                </tr>
                <tr>
                  <th>Client</th>
                  <td>{{jobConfiguration.client}}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          </div>
        </div>
    </div>
  </div>
</div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
