<div class="row noprint">
  <div class="col-md-12 ">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <div class="row">
          <div class="col">
            <h5 style="float: left;padding: .75rem 1.25rem">Initial Nursing Evaluation Report</h5>
          </div>
        </div>
        <div class="card-body">
          <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-row row">
              <!-- <div class="col-md-4 col-lg-4">
                <label>Report Date Range</label>
                <select formControlName="definedDate" (change)="ChangeDateType(this.registerForm.value.definedDate)" id="definedDate" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.definedDate.errors }">
                  <option *ngFor="let item of dateList"  value="{{item.date}}">
                    {{item.showDate}}
                  </option>

                </select>
                <div class="row mt5" *ngIf="showCustomDateRange">
                  <div class="col-md-6">
                    <input id="datetimepicker1"  autocomplete="off" placeholder="Select Start Date" type="text"
                      formControlName="startDate" class="form-control datetimepicker"
                      [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }" />
                  </div>
                  <div class="col-md-6">
                    <input id="datetimepicker2" autocomplete="off" placeholder="Select End Date" type="text"
                      formControlName="endDate" class="form-control datetimepicker"
                      [ngClass]="{ 'is-invalid': submitted && f.endDate.errors }" />
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-lg-2">
                <label>Employees</label>
                <select formControlName="employees" id="employees" class="form-control 0s-example-basic-single select2"
                  [ngClass]="{ 'is-invalid': submitted && f.employees.errors }">

                  <option value="0" selected>All Employees</option>
                  <option *ngFor="let emp of employeeList"
                    value="{{emp.employee_number}}">
                    {{emp.employee_first_name}} {{emp.employee_last_name}} - {{emp.employee_number}}
                  </option>

                </select>
                <div *ngIf="submitted && f.employees.errors" class="invalid-feedback">
                  <div *ngIf="f.employees.errors.required">Employee is required</div>
                </div>
              </div> -->
              <div class="col-md-3 col-lg-3">
                <label>New Clients</label>
                <select formControlName="clientId" id="clientId" class="form-control select2 0s-example-basic-single"
                  [ngClass]="{ 'is-invalid': submitted && f.clientId.errors }">
                  <option value="" selected>Please Select</option>
                  <option *ngFor="let client of clientList" value="{{client.id}}">
                    {{client.name}}
                  </option>
                </select>
                <div *ngIf="submitted && f.clientId.errors" class="invalid-feedback">
                  <div *ngIf="f.clientId.errors.required">Site is required</div>
                </div>
              </div>
              <div class="col-md-1 col-lg-1">
                <button type="submit" class="btn btn-custom" style="position: absolute;top: 30px;">Run Report</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="checkList && checkList.length > 1" class="row" style="margin-top: 20px;">
  <div class="col-md-10 col-sm-12 offset-md-1">
    <div class="row">
      <!-- <div class="col-3 text-bold">Home Client Checklist History: {{checkList.length}} </div> -->
    </div>
    <!-- <ng-container *ngFor="let client of checkList; index as 0"> -->
    <div class="accordion mt10" id="accordionExample1">
      <div class="card">
        <div class="card-header" [id]="'heading' + 0">
          <div class="row">
            <div class="col-md-5">
              <h6 class="text-bold">Checklist History</h6>
            </div>
            <div class="col-md-6">
              <h6><span class="text-bold">Client Name:</span> {{GetClientName(f.clientId.value)}}</h6>
            </div>
          </div>
        </div>
        <div [id]="'collapse' + 0" class="show collapse" [attr.aria-labelledby]="'heading' + 0"
          data-parent="#accordionExample1">
          <div class="row mt5">
            <div class="col-md-12">
              <div class="card-body">
                <table class="table" style="font-size: 14px;">
                  <thead class="">
                    <tr>
                      <th>Agency Administrator Name</th>
                      <th>Requested By</th>
                      <th>Initiated Date</th>
                      <th>Weekly Hours</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of checkList">
                      <td>{{data.agencyAdministratorName}}</td>
                      <td>{{data.requestedBy}}</td>
                      <td>{{data.initiatedDate | date : 'MMM dd yyyy'}}</td>
                      <td>{{data.totalHours}}</td>
                      <td>{{data.created | date : 'MMM dd yyyy hh:mm a'}}<span class="bold"> ({{data.created | TimeAgo}})</span></td>
                      <td><button style="cursor: pointer;" (click)="GeneratePDF(data.id)" class="btn btn-link">Download
                          PDF</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </ng-container> -->
  </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>
