<style>
  label span {
    color: red;
  }
</style>

<div style=" opacity: 0.9; background-color: white; margin: 1% 2%; padding: 3%;
box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;">
  <div class="row target">
    <div class="col-12 center" style="margin-bottom: 2%">
      <h4 class="section text-bold">IN HOME CLIENT - AIDE EVALUATION FORM</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-row row">
          <div class="form-group col-md-3 col-sm-6">
            <label>Client Name<span>*</span></label>
            <select (change)="GetNewClientDetailById($event.target.value)" formControlName="homeClientProfileId"
              class="form-control" type="text" [ngClass]="{
                'is-invalid': submitted && f.homeClientProfileId.errors
              }">
              <option value="" selected disabled>Please Select</option>
              <option *ngFor="let item of evaluatedClients" value="{{ item.id }}">
                {{ item.name }}
              </option>
            </select>
            <div *ngIf="submitted && f.homeClientProfileId.errors" class="invalid-feedback">
              <div *ngIf="f.homeClientProfileId.errors.required">required</div>
            </div>
          </div>
          <div *ngIf="evaluatedClient" class="form-group col-md-2 col-sm-6">
            <label>Date of Birth</label>
            <input disabled class="form-control" value="{{ evaluatedClient.dob | date : 'MMM dd yyyy' }}" />
          </div>
          <div *ngIf="evaluatedClient" class="form-group col-md-2 col-sm-6">
            <label>Phone</label>
            <p class="form-control">
              {{
              "(" +
              this.evaluatedClient.phone.substring(0, 3) +
              ") " +
              this.evaluatedClient.phone.substring(3, 6) +
              "-" +
              this.evaluatedClient.phone.substring(6, 10)
              }}
            </p>
          </div>
          <div *ngIf="evaluatedClient" class="form-group col-md-5 col-sm-6">
            <label>Address</label>
            <input disabled class="form-control" value="{{ evaluatedClient.address }}" />
          </div>
        </div>

        <div class="row mt15">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <table class="table table-bordered">
              <thead>
                <th style="width: 140px">I. Agency Policy</th>
                <th style="width: 120px">Not Observed</th>
                <th style="width: 110px">Satisfactory</th>
                <th style="width: 110px">Unsatisfactory</th>
                <th>Comments</th>
              </thead>
              <tbody>
                <tr>
                  <td>Dress Code</td>
                  <td>
                    <input class="form-control" name="dressCode" formControlName="dressCode" type="radio" value="0" />
                  </td>
                  <td>
                    <input class="form-control" name="dressCode" formControlName="dressCode" type="radio" value="1" />
                  </td>
                  <td>
                    <input class="form-control" name="dressCode" formControlName="dressCode" type="radio" value="2" />
                  </td>
                  <td>
                    <input class="form-control" formControlName="dressCodeComment" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f.dressCodeComment.errors }" />
                    <div *ngIf="submitted && f.dressCodeComment.errors" class="invalid-feedback">
                      <div *ngIf="f.dressCodeComment.errors.maxlength">max character {{f.dressCodeComment.value.length}}
                        / 28</div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Punctuality/Dependability</td>
                  <td>
                    <input class="form-control" name="punctuality" formControlName="punctuality" type="radio"
                      value="0" />
                  </td>
                  <td>
                    <input class="form-control" name="punctuality" formControlName="punctuality" type="radio"
                      value="1" />
                  </td>
                  <td>
                    <input class="form-control" name="punctuality" formControlName="punctuality" type="radio"
                      value="2" />
                  </td>
                  <td>
                    <input class="form-control" formControlName="punctualityComment" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f.punctualityComment.errors }" />
                    <div *ngIf="submitted && f.punctualityComment.errors" class="invalid-feedback">
                      <div *ngIf="f.punctualityComment.errors.maxlength">max character
                        {{f.punctualityComment.value.length}} / 28</div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Reporting/Recording</td>
                  <td>
                    <input class="form-control" name="reporting" formControlName="reporting" type="radio" value="0" />
                  </td>
                  <td>
                    <input class="form-control" name="reporting" formControlName="reporting" type="radio" value="1" />
                  </td>
                  <td>
                    <input class="form-control" name="reporting" formControlName="reporting" type="radio" value="2" />
                  </td>
                  <td>
                    <input class="form-control" formControlName="reportingComment" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f.reportingComment.errors }" />
                    <div *ngIf="submitted && f.reportingComment.errors" class="invalid-feedback">
                      <div *ngIf="f.reportingComment.errors.maxlength">max character
                        {{f.reportingComment.value.length}} / 28</div> </div>
                  </td>
                </tr>

                <tr>
                  <td style="font-weight: bold">II. Rapport Client/Family</td>
                  <td>
                    <input class="form-control" name="rapport" formControlName="rapport" type="radio" value="0" />
                  </td>
                  <td>
                    <input class="form-control" name="rapport" formControlName="rapport" type="radio" value="1" />
                  </td>
                  <td>
                    <input class="form-control" name="rapport" formControlName="rapport" type="radio" value="2" />
                  </td>
                  <td>
                    <input class="form-control" formControlName="rapportComment" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f.rapportComment.errors }" />
                    <div *ngIf="submitted && f.rapportComment.errors" class="invalid-feedback">
                      <div *ngIf="f.rapportComment.errors.maxlength">max character
                        {{f.rapportComment.value.length}} / 28</div> </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" style="font-weight: bold">
                    III. Client Personal Care
                  </td>
                </tr>
                <tr>
                  <td>Bathing/Grooming</td>
                  <td>
                    <input class="form-control" name="bathing" formControlName="bathing" type="radio" value="0" />
                  </td>
                  <td>
                    <input class="form-control" name="bathing" formControlName="bathing" type="radio" value="1" />
                  </td>
                  <td>
                    <input class="form-control" name="bathing" formControlName="bathing" type="radio" value="2" />
                  </td>
                  <td>
                    <input class="form-control" formControlName="bathingComment" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f.bathingComment.errors }" />
                    <div *ngIf="submitted && f.bathingComment.errors" class="invalid-feedback">
                      <div *ngIf="f.bathingComment.errors.maxlength">max character
                        {{f.bathingComment.value.length}} / 28</div> </div>
                  </td>
                </tr>

                <tr>
                  <td>Transfer</td>
                  <td>
                    <input class="form-control" name="transfer" formControlName="transfer" type="radio" value="0" />
                  </td>
                  <td>
                    <input class="form-control" name="transfer" formControlName="transfer" type="radio" value="1" />
                  </td>
                  <td>
                    <input class="form-control" name="transfer" formControlName="transfer" type="radio" value="2" />
                  </td>
                  <td>
                    <input class="form-control" formControlName="transferComment" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f.transferComment.errors }" />
                    <div *ngIf="submitted && f.transferComment.errors" class="invalid-feedback">
                      <div *ngIf="f.transferComment.errors.maxlength">max character
                        {{f.transferComment.value.length}} / 28</div> </div>
                  </td>
                </tr>

                <tr>
                  <td>Ambulation</td>
                  <td>
                    <input class="form-control" name="ambulation" formControlName="ambulation" type="radio" value="0" />
                  </td>
                  <td>
                    <input class="form-control" name="ambulation" formControlName="ambulation" type="radio" value="1" />
                  </td>
                  <td>
                    <input class="form-control" name="ambulation" formControlName="ambulation" type="radio" value="2" />
                  </td>
                  <td>
                    <input class="form-control" formControlName="ambulationComment" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f.ambulationComment.errors }" />
                    <div *ngIf="submitted && f.ambulationComment.errors" class="invalid-feedback">
                      <div *ngIf="f.ambulationComment.errors.maxlength">max character
                        {{f.ambulationComment.value.length}} / 28</div> </div>
                  </td>
                </tr>

                <tr>
                  <td>Nutrition</td>
                  <td>
                    <input class="form-control" name="nutrition" formControlName="nutrition" type="radio" value="0" />
                  </td>
                  <td>
                    <input class="form-control" name="nutrition" formControlName="nutrition" type="radio" value="1" />
                  </td>
                  <td>
                    <input class="form-control" name="nutrition" formControlName="nutrition" type="radio" value="2" />
                  </td>
                  <td>
                    <input class="form-control" formControlName="nutritionComment" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f.nutritionComment.errors }" />
                    <div *ngIf="submitted && f.nutritionComment.errors" class="invalid-feedback">
                      <div *ngIf="f.nutritionComment.errors.maxlength">max character
                        {{f.nutritionComment.value.length}} / 28</div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" style="font-weight: bold">
                    IV. Home Management
                  </td>
                </tr>
                <tr>
                  <td>Living Areas/Laundry</td>
                  <td>
                    <input class="form-control" name="living" formControlName="living" type="radio" value="0" />
                  </td>
                  <td>
                    <input class="form-control" name="living" formControlName="living" type="radio" value="1" />
                  </td>
                  <td>
                    <input class="form-control" name="living" formControlName="living" type="radio" value="2" />
                  </td>
                  <td>
                    <input class="form-control" formControlName="livingComment" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f.livingComment.errors }" />
                    <div *ngIf="submitted && f.livingComment.errors" class="invalid-feedback">
                      <div *ngIf="f.livingComment.errors.maxlength">max character
                        {{f.livingComment.value.length}} / 28</div>
                      </div>
                  </td>
                </tr>

                <tr>
                  <td>Safety</td>
                  <td>
                    <input class="form-control" name="safety" formControlName="safety" type="radio" value="0" />
                  </td>
                  <td>
                    <input class="form-control" name="safety" formControlName="safety" type="radio" value="1" />
                  </td>
                  <td>
                    <input class="form-control" name="safety" formControlName="safety" type="radio" value="2" />
                  </td>
                  <td>
                    <input class="form-control" formControlName="safetyComment" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f.safetyComment.errors }" />
                    <div *ngIf="submitted && f.safetyComment.errors" class="invalid-feedback">
                      <div *ngIf="f.safetyComment.errors.maxlength">max character
                        {{f.safetyComment.value.length}} / 28
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td style=" font-weight: bold; vertical-align: middle;text-align: center;">
                    Comments:
                  </td>
                  <td colspan="4">
                    <textarea class="form-control" formControlName="comment" rows="2"
                    [ngClass]="{ 'is-invalid': submitted && f.comment.errors }"></textarea>
                    <div *ngIf="submitted && f.comment.errors" class="invalid-feedback">
                      <div *ngIf="f.comment.errors.maxlength">max character
                        {{f.comment.value.length}} / 100</div>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row mt55">
          <div class="col-md-12">
            <h5 class="text-center text-bold">Skill Level Verification</h5>
            <h6 class="text-center text-bold"><span>S - Satisfactory</span> <span style="padding-left: 30px;">US -
                Unsatisfactory</span></h6>
          </div>
          <div class="col-md-12 mt-4">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <ng-container *ngFor="let item of SkillLevel(1); let i = index;">
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <span style="width: 250px">{{item.name}}</span>
                    </div>
                    <div class="col-md-4">
                      <select formControlName="skillQ{{i+1}}" class="form-control">
                        <option [selected] value="">Please Select</option>
                        <option value="1">Satisfactory</option>
                        <option value="0">Unsatisfactory</option>
                      </select>
                    </div>
                  </div>
                </ng-container>
                <div class="row mt-3">
                  <div class="col-md-6 col-sm-6">
                    <span style="width: 250px">Other</span>
                  </div>
                  <div class="col-md-6">
                    <input formControlName="skillQOther" class="form-control" type="text" />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6">
                <ng-container *ngFor="let item of SkillLevel(2); let i = index;">
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <span style="width: 250px">{{item.name}}</span>
                    </div>
                    <div class="col-md-4">
                      <select formControlName="skillQ{{i+13}}" class="form-control">
                        <option [selected] value="">Please Select</option>
                        <option value="1">Satisfactory</option>
                        <option value="0">Unsatisfactory</option>
                      </select>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt55">
          <div class="col-md-12">
            <h5 class="text-center text-bold">Client Survey / Evaluation</h5>
          </div>
          <div class="col-md-12 mt-4">
            <div class="row">
              <div class="col-md-12">
                <div *ngFor="let item of EvalutionQuestion(); let i = index;">
                  <div class="row mt-3">
                    <div class="col-md-6 col-sm-12 Offset-sm-1">
                      <span style="text-transform: uppercase;">{{item.name}}</span>
                    </div>
                    <div class="col-md-5 col-sm-12 Offset-sm-3">
                      <div class="row">
                        <div class="col-md-4 col-sm-6 offset-sm-3">
                          <select formControlName="evalutionQ{{i+1}}" class="form-control">
                            <option [selected] value="">Please Select</option>
                            <option value="2">GOOD</option>
                            <option value="1">AVERAGE</option>
                            <option value="0">POOR</option>
                          </select>
                          <!-- <div class="form-check">
                            <input class="form-check-input" name="evalutionQ{{i+1}}"
                              formControlName="evalutionQ{{i+1}}" type="radio" value="2">
                            <label class="form-check-label text-bold">GOOD</label>
                          </div> -->
                        </div>
                        <!-- <div class="col-2">
                          <div class="form-check">
                            <input class="form-check-input" name="evalutionQ{{i+1}}"
                              formControlName="evalutionQ{{i+1}}" type="radio" value="1">
                            <label class="form-check-label text-bold">AVERAGE</label>
                          </div>
                        </div> -->
                        <!-- <div class="col-2">
                          <div class="form-check">
                            <input class="form-check-input" name="evalutionQ{{i+1}}"
                              formControlName="evalutionQ{{i+1}}" type="radio" value="0">
                            <label class="form-check-label text-bold">POOR</label>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt15">
          <div class="form-group col-md-12 col-sm-12">
            <label>Reason of Visit</label>
            <textarea formControlName="reason" class="form-control" cols="2"
            [ngClass]="{ 'is-invalid': submitted && f.reason.errors }"></textarea>
            <div *ngIf="submitted && f.reason.errors" class="invalid-feedback">
              <div *ngIf="f.reason.errors.maxlength">max character {{f.reason.value.length}} / 200</div>
          </div>
        </div>
        </div>
        <div class="row mt15" style="padding-left: 21px;">
          <div class="col-md-12 col-sm-12">
            <label style="margin-right: 30px;" class="checkbox-inline text-bold">
              <input class="form-check-input" type="checkbox" name="reasonMainType" formControlName="reasonMainType"
                value="SOC In home supervision">
              SOC In home supervision
            </label>
            <label style="margin-right: 30px;" class="checkbox-inline text-bold">
              <input class="form-check-input" type="checkbox" name="reasonMainType" formControlName="reasonMainType"
                value="Skill level verification">
              Skill level verification
            </label>
            <label style="margin-right: 30px;" class="checkbox-inline text-bold">
              <input class="form-check-input" type="checkbox" name="reasonMainType" formControlName="reasonMainType"
                value="Ineffective visit/service">
              Ineffective visit/service
            </label>
            <label style="margin-right: 30px;" class="checkbox-inline text-bold">
              <input class="form-check-input" type="checkbox" name="reasonMainType" formControlName="reasonMainType"
                value="Patient progress note concern(s)">Patient progress note concern(s)
            </label>
            <label style="margin-right: 30px;" class="checkbox-inline text-bold">
              <input class="form-check-input" type="checkbox" name="reasonMainType" formControlName="reasonMainType"
                value="Follow up/scheduled supervision visit">
              Follow up/scheduled supervision visit

            </label>
          </div>
        </div>
        <div class="row mt15">
          <div class="col-md-4 offset-md-4 col-xs-12">
            <button type="submit" class="btn btn-block btn-custom">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
  <p style="color: white">Loading...</p>
</ngx-spinner>
