import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from '../auth/auth.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;
  userName$: Observable<string>;
  break: {};

  constructor(private router: Router, public authService: AuthService, @Inject(DOCUMENT) private document: Document) { }


  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.userName$ = this.authService.userName;

  }
  onLogout(): void {
    this.authService.logout();
  }
}
