<div class="row" style="margin-top: 20px;">
  <div class="col-md-10 col-sm-12 offset-md-1">
    <div *ngIf="vaClientEmployeeHours && vaClientEmployeeHours.length > 1" class="row">
      <div class="col-3 text-bold">Total Veteran Clients: {{vaClientEmployeeHours.length}} </div>
    </div>
    <ng-container *ngFor="let client of vaClientEmployeeHours; index as j">
      <div *ngIf="client.name != null && client.subTotal.length > 0" class="accordion mt10" id="accordionExample1">
        <div class="card">
          <div class="card-header" [id]="'heading' + j">
            <h2 class="mb-0">
              <a role="button" class="btn btn-link btn-block text-left" data-toggle="collapse" data-parent="#accordion"
                [href]="'#collapse' + j" aria-expanded="true" [attr.aria-controls]="'collapse' + j">
                <div class="row">
                  <div class="col-md-3">
                    <p class="text-bold btn-color-black">{{client.name}}</p>
                  </div>
                  <div class="col-md-3">
                    <p class="text-bold btn-color-black">Total TimeSheet Hours: {{GetTotal(client)}}</p>
                  </div>
                  <div class="col-md-3">
                    <p class="text-bold btn-color-black">Total Units: {{GetTotal(client,true,false)}}</p>
                  </div>
                  <div class="col-md-3">
                    <p class="text-bold btn-color-black">Total Charge: {{GetTotal(client,false,true)}}</p>
                  </div>
                </div>
              </a>
            </h2>
          </div>
          <div [id]="'collapse' + j"
            [ngClass]="{'show': vaClientEmployeeHours.length === 1,'hide': vaClientEmployeeHours.length > 1}"
            class="collapse" [attr.aria-labelledby]="'heading' + j" data-parent="#accordionExample1">
            <div class="row mt5">
              <div class="col-md-12">
                <div class="card-body">
                  <table class="table" style="font-size: 14px;">
                    <thead class="">
                      <tr>
                        <th>Employee Name</th>
                        <th>Service Date</th>
                        <th>Time Sheet Hour</th>
                        <th>Units</th>
                        <th>Charge</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of client.subTotal">
                        <td>{{data.employeeName}}</td>
                        <td>{{data.checkInDate.split(' ')[0]}}</td>
                        <td>{{data.duration}}</td>
                        <td>{{data.duration * 4}}</td>
                        <td>{{((data.duration * 4) *7.88)}}</td>
                      </tr>
                      <tr class="colorRed" style="font-weight: bold; border-top: 5px solid #222;">
                        <td>Grand Total</td>
                        <td></td>
                        <td>{{GetTotal(client)}}</td>
                        <td>{{GetTotal(client,true,false)}}</td>
                        <td>{{GetTotal(client,false,true)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- </ng-template> -->
    <!-- <ng-template #elseTemplate>
      <button class="btn btn-link btn-block text-center" type="button" data-toggle="collapse">
        <div class="beautifyBox" style="padding: 50px;">
          <h4>Record Not Found</h4>
        </div>
      </button>
    </ng-template> -->
  </div>
</div>
