<style>
  label span {
    color: red;
  }

  .op {
    font-size: 12px !important;
    font-weight: bold !important;
    opacity: 0.6 !important;
    color: black !important;
  }

  .section {
    margin: 20px 0px 20px 0px;
    font-weight: bold;
  }
</style>
<div style="opacity: 0.9;
background-color: white;
 margin: 1% 2%;
padding: 3%;
box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
">
  <div class="row target">
    <div class="col-12 center" style="margin-bottom: 2%">
      <h4 class="section">IN HOME CLIENT - PROFILE FORM</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-row row">
          <div class="form-group col-md-3 col-sm-6">
            <label>Client Name<span>*</span></label>
            <input formControlName="name" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>Phone<span>*</span></label>
            <input formControlName="phone" class="form-control" type="text" mask="(000) 000-0000"
              [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
              <div *ngIf="f.phone.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>SSN<span>*</span></label>
            <input formControlName="sSN" class="form-control" type="text" mask="000-00-0000"
              [ngClass]="{ 'is-invalid': submitted && f.sSN.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.sSN.errors" class="invalid-feedback">
              <div *ngIf="f.sSN.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>DOB<span>*</span></label>
            <input formControlName="dOB" class="form-control" type="date"
              [ngClass]="{ 'is-invalid': submitted && f.dOB.errors }" />
            <div *ngIf="submitted && f.dOB.errors" class="invalid-feedback">
              <div *ngIf="f.dOB.errors.required">required</div>
            </div>
          </div>
        </div>
        <div class="form-row row">
          <div class="form-group col-md-6 col-sm-6">
            <label>Address<span>*</span></label>
            <input formControlName="address" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.address.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
              <div *ngIf="f.address.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-6 col-sm-6">
            <label>Allergies<span></span></label>
            <input formControlName="allergies" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.allergies.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.allergies.errors" class="invalid-feedback">
              <div *ngIf="f.allergies.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-12 col-sm-6">
            <label>Past Medical History<span></span></label>
            <textarea rows="2" formControlName="pastMedicalHistory" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.pastMedicalHistory.errors }" placeholder="Please Enter....">
            </textarea>
            <div *ngIf="submitted && f.pastMedicalHistory.errors" class="invalid-feedback">
              <div *ngIf="f.pastMedicalHistory.errors.required">required</div>
              <div *ngIf="f.pastMedicalHistory.errors.maxlength">max character {{f.pastMedicalHistory.value.length}} / 100</div>
            </div>
          </div>
        </div>
        <div class="form-row row">
          <div class="form-group col-md-3 col-sm-6">
            <label>Reason For Care<span></span></label>
            <input formControlName="reasonForCare" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.reasonForCare.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.reasonForCare.errors" class="invalid-feedback">
              <div *ngIf="f.reasonForCare.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>Hospital Of Choice<span></span></label>
            <input formControlName="hospitalOfChoice" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.hospitalOfChoice.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.hospitalOfChoice.errors" class="invalid-feedback">
              <div *ngIf="f.hospitalOfChoice.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>Religious Affiliation<span></span></label>
            <input formControlName="religiousAffiliation" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.religiousAffiliation.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.religiousAffiliation.errors" class="invalid-feedback">
              <div *ngIf="f.religiousAffiliation.errors.required">required</div>
            </div>
          </div>
          <!-- <div class="form-group col-md-3 col-sm-6">
            <label>Pastor<span></span></label>
            <input formControlName="pastor" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.pastor.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.pastor.errors" class="invalid-feedback">
              <div *ngIf="f.pastor.errors.required">required</div>
            </div>
          </div> -->
        </div>
        <div class="form-row row">
          <div class="col-12">
            <h6 class="section">Power of Attorney Section</h6>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>Name Of Attorney<span></span></label>
            <input formControlName="powerOfAttorney" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.powerOfAttorney.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.powerOfAttorney.errors" class="invalid-feedback">
              <div *ngIf="f.powerOfAttorney.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-2 col-sm-6">
            <label>Phone<span></span></label>
            <input mask="(000) 000-0000" formControlName="powerOfAttorneyPhone" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.powerOfAttorneyPhone.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.powerOfAttorneyPhone.errors" class="invalid-feedback">
              <div *ngIf="f.powerOfAttorneyPhone.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-7 col-sm-6">
            <label>Address<span></span></label>
            <input formControlName="powerOfAttorneyAddress" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.powerOfAttorneyAddress.errors }"
              placeholder="Please Enter...." />
            <div *ngIf="submitted && f.powerOfAttorneyAddress.errors" class="invalid-feedback">
              <div *ngIf="f.powerOfAttorneyAddress.errors.required">required</div>
            </div>
          </div>
        </div>
        <div class="form-row row">
          <div class="col-12">
            <h6 class="section">Primary Care MD Section</h6>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>Name Of Primary Care MD<span></span></label>
            <input formControlName="primaryCareMD" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.primaryCareMD.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.primaryCareMD.errors" class="invalid-feedback">
              <div *ngIf="f.primaryCareMD.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-2 col-sm-6">
            <label>Phone<span></span></label>
            <input mask="(000) 000-0000" formControlName="primaryCareMDPhone" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.primaryCareMDPhone.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.primaryCareMDPhone.errors" class="invalid-feedback">
              <div *ngIf="f.primaryCareMDPhone.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-7 col-sm-6">
            <label>Address<span></span></label>
            <input formControlName="primaryCareMDAddress" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.primaryCareMDAddress.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.primaryCareMDAddress.errors" class="invalid-feedback">
              <div *ngIf="f.primaryCareMDAddress.errors.required">required</div>
            </div>
          </div>
          <!-- <div class="form-group col-md-3 col-sm-6">
            <label>Fax</label>
            <input formControlName="primaryCareMDFax" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.primaryCareMDFax.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.primaryCareMDFax.errors" class="invalid-feedback">
              <div *ngIf="f.primaryCareMDFax.errors.required">required</div>
            </div>
          </div> -->
        </div>
        <div class="form-row row">
          <div class="col-12">
            <h6 class="section">Specialty MD Section</h6>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>Name Of Specialty MD<span></span></label>
            <input formControlName="specialtyMD" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.specialtyMD.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.specialtyMD.errors" class="invalid-feedback">
              <div *ngIf="f.specialtyMD.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-2 col-sm-6">
            <label>Phone<span></span></label>
            <input mask="(000) 000-0000" formControlName="specialtyMDPhone" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.specialtyMDPhone.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.specialtyMDPhone.errors" class="invalid-feedback">
              <div *ngIf="f.specialtyMDPhone.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-7 col-sm-6">
            <label>Address<span></span></label>
            <input formControlName="specialtyMDAddress" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.specialtyMDAddress.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.specialtyMDAddress.errors" class="invalid-feedback">
              <div *ngIf="f.specialtyMDAddress.errors.required">required</div>
            </div>
          </div>
          <!-- <div class="form-group col-md-3 col-sm-6">
            <label>Fax</label>
            <input formControlName="specialtyMDFax" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.specialtyMDFax.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.specialtyMDFax.errors" class="invalid-feedback">
              <div *ngIf="f.specialtyMDFax.errors.required">required</div>
            </div>
          </div> -->
        </div>
        <div class="form-row row">
          <div class="col-12">
            <h6 class="section">Case Manager/Agency Section</h6>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>Name Of Case Manager/Agency<span></span></label>
            <input formControlName="caseManagerAgency" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.caseManagerAgency.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.caseManagerAgency.errors" class="invalid-feedback">
              <div *ngIf="f.caseManagerAgency.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-2 col-sm-6">
            <label>Phone<span></span></label>
            <input mask="(000) 000-0000" formControlName="caseManagerAgencyPhone" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.caseManagerAgencyPhone.errors }"
              placeholder="Please Enter...." />
            <div *ngIf="submitted && f.caseManagerAgencyPhone.errors" class="invalid-feedback">
              <div *ngIf="f.caseManagerAgencyPhone.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-7 col-sm-6">
            <label>Address<span></span></label>
            <input formControlName="caseManagerAgencyAddress" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.caseManagerAgencyAddress.errors }"
              placeholder="Please Enter...." />
            <div *ngIf="submitted && f.caseManagerAgencyAddress.errors" class="invalid-feedback">
              <div *ngIf="f.caseManagerAgencyAddress.errors.required">required</div>
            </div>
          </div>
          <!-- <div class="form-group col-md-3 col-sm-6">
            <label>Fax</label>
            <input formControlName="caseManagerAgencyFax" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.caseManagerAgencyFax.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.caseManagerAgencyFax.errors" class="invalid-feedback">
              <div *ngIf="f.caseManagerAgencyFax.errors.required">required</div>
            </div>
          </div> -->
        </div>
        <div class="form-row row">
          <div class="col-12">
            <h6 class="section">Insurance Co Name Section</h6>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>Name Of Insurance Co</label>
            <input formControlName="insuranceCoName" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.insuranceCoName.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.insuranceCoName.errors" class="invalid-feedback">
              <div *ngIf="f.insuranceCoName.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-2 col-sm-6">
            <label>Phone</label>
            <input mask="(000) 000-0000" formControlName="insuranceCoPhone" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.insuranceCoPhone.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.insuranceCoPhone.errors" class="invalid-feedback">
              <div *ngIf="f.insuranceCoPhone.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-7 col-sm-6">
            <label>Address</label>
            <input formControlName="insuranceCoAddress" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.insuranceCoAddress.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.insuranceCoAddress.errors" class="invalid-feedback">
              <div *ngIf="f.insuranceCoAddress.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>Fax</label>
            <input mask="000-000-0000" formControlName="insuranceCoFax" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.insuranceCoFax.errors }" placeholder="Please Enter...." />
            <div *ngIf="submitted && f.insuranceCoFax.errors" class="invalid-feedback">
              <div *ngIf="f.insuranceCoFax.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-3 col-sm-6">
            <label>Policy Group</label>
            <input formControlName="insuranceCoPolicyGroup" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.insuranceCoPolicyGroup.errors }"
              placeholder="Please Enter...." />
            <div *ngIf="submitted && f.insuranceCoPolicyGroup.errors" class="invalid-feedback">
              <div *ngIf="f.insuranceCoPolicyGroup.errors.required">required</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 center" style="margin-bottom: 2%">
            <h4 class="section">MEDICATION PROFILE</h4>
          </div>
        </div>
        <div formGroupName="homeClientMedicationProfile" class="form-row row">
          <div class="form-group col-md-3 col-sm-6">
            <label>Allergies<span></span></label>
            <input formControlName="allergies" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && homeClientMedicationProfile.allergies.errors }"
              placeholder="Please Enter...." />
            <div *ngIf="submitted && homeClientMedicationProfile.allergies.errors" class="invalid-feedback">
              <div *ngIf="homeClientMedicationProfile.allergies.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-2 col-sm-6">
            <label>Pharmacy Phone<span></span></label>
            <input mask="(000) 000-0000" formControlName="pharmacyPhone" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && homeClientMedicationProfile.pharmacyPhone.errors }"
              placeholder="Please Enter...." />
            <div *ngIf="submitted && homeClientMedicationProfile.pharmacyPhone.errors" class="invalid-feedback">
              <div *ngIf="homeClientMedicationProfile.pharmacyPhone.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-5 col-sm-6">
            <label>Pharmacy Address<span></span></label>
            <input formControlName="pharmacyAddress" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && homeClientMedicationProfile.pharmacyAddress.errors }"
              placeholder="Please Enter...." />
            <div *ngIf="submitted && homeClientMedicationProfile.pharmacyAddress.errors" class="invalid-feedback">
              <div *ngIf="homeClientMedicationProfile.pharmacyAddress.errors.required">required</div>
            </div>
          </div>
        </div>
        <div class="form-row row">
          <div class="addlist col-md-12" id="row_field" formArrayName="homeClientMedicationDrugProfiles"
            *ngFor="let item of registerForm.get('homeClientMedicationDrugProfiles')['controls']; let i = index;">
            <div [formGroupName]="i" class="form-row row">
              <div class="form-group col-md-3 col-sm-6">
                <label>Medication<span></span></label>
                <input formControlName="drug" class="form-control" type="text"
                  [ngClass]="{ 'is-invalid': submitted && d.controls[i].get('drug')?.errors }"
                  placeholder="Please Enter...." />
                <div *ngIf="submitted && d.controls[i].get('drug')?.errors" class="invalid-feedback">
                  <div *ngIf="d.controls[i].get('drug')?.errors?.required">required
                  </div>
                </div>
              </div>
              <div class="form-group col-md-3 col-sm-6">
                <label>Dose And Frequency<span></span></label>
                <input formControlName="doseAndFrequency" class="form-control" type="text"
                  [ngClass]="{ 'is-invalid': submitted && d.controls[i].get('doseAndFrequency')?.errors }"
                  placeholder="Please Enter...." />
                <div *ngIf="submitted && d.controls[i]?.get('doseAndFrequency')?.errors" class="invalid-feedback">
                  <div *ngIf="d.controls[i]?.get('doseAndFrequency')?.errors?.required">
                    required</div>
                </div>
              </div>
              <div class="form-group col-md-4 col-sm-6 col-xs-10">
                <label>Comments<span> </span></label>
                <textarea formControlName="comments" class="form-control" placeholder="Please Enter...."
                  [ngClass]="{ 'is-invalid': submitted && d.controls[i]?.get('comments')?.errors }" rows="2">
                </textarea>
                <div *ngIf="submitted && d.controls[i]?.get('comments')?.errors" class="invalid-feedback">
                  <div *ngIf="d.controls[i]?.get('comments')?.errors?.required">required
                  </div>
                </div>
              </div>
              <div class="form-group col-md-1 col-sm-1 col-xs-1 mt50 fs20">
                <i class="fa fa-trash text-danger" (click)="removeItem(i)" *ngIf="d.controls.length > 1"></i>
              </div>
              <div class="form-group col-md-1 col-sm-1 col-xs-1 mt50 fs20">
                <i *ngIf="d.controls.length === i +1" type="button" (click)="addItem(1)"
                  class="fa fa-plus text-success"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 center" style="margin-bottom: 2%">
            <h4 class="section">INITIAL NURSING EVALUATION & LEVEL OF CARE ASSESSMENT</h4>
          </div>
        </div>

        <div formGroupName="initialNursingClientEvaluation">
          <div class="form-row row">
            <div class="form-group col-md-4 col-sm-6">
              <label>Physician<span></span></label>
              <input formControlName="physician" class="form-control" type="text"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.physician.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.physician.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.physician.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Admitted From<span></span></label>
              <select formControlName="admittedFrom" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.admittedFrom.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Home">Home</option>
                <option value="ALF">ALF</option>
                <option value="SNF">SNF</option>
                <option value="Rehab Facility">Rehab Facility</option>
                <option value="Acute Care"> Acute Care</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.admittedFrom.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.admittedFrom.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Independent Living<span></span></label>
              <select (change)="IsIndependantLiving($event.target.value)" formControlName="isIndependentLiving"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.isIndependentLiving.errors }">
                <option value="false" selected>No</option>
                <option value="true">Yes</option>
              </select>
            </div>
          </div>
          <div *ngIf="!isIndependentLiving" style="transition: width 2s;" class="form-row row">
            <div class="col-12">
              <h6 class="section">VITAL SIGNS</h6>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Height<span></span></label>
              <input formControlName="vitalSignHeight" class="form-control" type="text"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.vitalSignHeight.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.vitalSignHeight.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.vitalSignHeight.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Weight<span></span></label>
              <input formControlName="vitalSignWeight" class="form-control" type="text"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.vitalSignWeight.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.vitalSignWeight.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.vitalSignWeight.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Temp<span></span></label>
              <input formControlName="vitalSignTemp" class="form-control" type="text"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.vitalSignTemp.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.vitalSignTemp.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.vitalSignTemp.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Pulse<span></span></label>
              <input formControlName="vitalSignPulse" class="form-control" type="text"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.vitalSignPulse.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.vitalSignPulse.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.vitalSignPulse.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Respiration<span></span></label>
              <input formControlName="vitalSignRespiration" class="form-control" type="text"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.vitalSignRespiration.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.vitalSignRespiration.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.vitalSignRespiration.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>B/P Sitting<span></span></label>
              <input formControlName="vitalSignBPSitting" class="form-control" type="text"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.vitalSignBPSitting.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.vitalSignBPSitting.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.vitalSignBPSitting.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>B/P Standing<span></span></label>
              <input formControlName="vitalSignBPStanding" class="form-control" type="text"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.vitalSignBPStanding.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.vitalSignBPStanding.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.vitalSignBPStanding.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>B/P Laying<span></span></label>
              <input formControlName="vitalSignBPLaying" class="form-control" type="text"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.vitalSignBPLaying.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.vitalSignBPLaying.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.vitalSignBPLaying.errors.required">required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="col-12">
              <h6 class="section">GENERAL APPEARANCE</h6>
            </div>
            <div class="form-group col-md-3 col-sm-4">
              <label>Personal Hygiene<span></span></label>
              <select formControlName="generalAppearancePersonalHygiene" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.generalAppearancePersonalHygiene.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Clean">Clean</option>
                <option value="Odor Present">Odor Present</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.generalAppearancePersonalHygiene.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.generalAppearancePersonalHygiene.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-4">
              <label>Hearing Aid<span></span></label>
              <select formControlName="generalAppearanceHearingAid" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.generalAppearanceHearingAid.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="R">R</option>
                <option value="L">L</option>
                <option value="Bilat">Bilat</option>
                <option value="None">None</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.generalAppearanceHearingAid.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.generalAppearanceHearingAid.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Ears<span></span></label>
              <select formControlName="generalAppearanceEars" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.generalAppearanceEars.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Clear">Clear</option>
                <option value="Cerumen Build Up">Cerumen Build Up</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.generalAppearanceEars.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.generalAppearanceEars.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Eyes<span></span></label>
              <select formControlName="generalAppearanceEyes" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.generalAppearanceEyes.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Clear">Clear</option>
                <option value="Red">Red</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.generalAppearanceEyes.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.generalAppearanceEyes.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Glasses<span></span></label>
              <select formControlName="generalAppearanceGlasses" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.generalAppearanceGlasses.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.generalAppearanceGlasses.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.generalAppearanceGlasses.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-4">
              <label>Drainage Present<span></span></label>
              <select formControlName="generalAppearanceDrainagePresent" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.generalAppearanceDrainagePresent.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.generalAppearanceDrainagePresent.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.generalAppearanceDrainagePresent.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-4">
              <label>Mental Status<span></span></label>
              <select formControlName="generalAppearanceMentalStatus" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.generalAppearanceMentalStatus.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Alert">Alert</option>
                <option value="Lethargic">Lethargic</option>
                <option value="Person">Person</option>
                <option value="Time">Time</option>
                <option value="Orientation">Orientation</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.generalAppearanceMentalStatus.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.generalAppearanceMentalStatus.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-4">
              <label>Date of Last Pneumovax<span></span></label>
              <input formControlName="generalAppearanceDateOfLastPneumovax" class="form-control" type="date"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.generalAppearanceDateOfLastPneumovax.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.generalAppearanceDateOfLastPneumovax.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.generalAppearanceDateOfLastPneumovax.errors.required">
                  required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-4">
              <label>Date of last flu shot<span></span></label>
              <input formControlName="generalAppearanceDateOfLastFluShot" class="form-control" type="date"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.generalAppearanceDateOfLastFluShot.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.generalAppearanceDateOfLastFluShot.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.generalAppearanceDateOfLastFluShot.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Comments</label>
              <textarea rows="2" formControlName="generalAppearanceComments" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.generalAppearanceComments.errors }"
                placeholder="Please Enter...."></textarea>
              <div *ngIf="submitted && initialNursingClientEvaluation.generalAppearanceComments.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.generalAppearanceComments.errors.required">required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="col-12">
              <h6 class="section">CARDIAC</h6>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Heart Rhythm<span></span></label>
              <select formControlName="cardiacHeartRhythm" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.cardiacHeartRhythm.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Regular">Regular</option>
                <option value="Irregular">Irregular</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.cardiacHeartRhythm.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.cardiacHeartRhythm.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Edema<span></span></label>
              <select (change)="SetCardiacEdemaValidator($event.target.value)" formControlName="cardiacEdema"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.cardiacEdema.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.cardiacEdema.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.cardiacEdema.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Location<span class="op"> (If Edema Found)</span></label>
              <input formControlName="cardiacLocation" class="form-control" type="text"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.cardiacLocation.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.cardiacLocation.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.cardiacLocation.errors.required">
                  required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Pedal Pulses<span></span></label>
              <input formControlName="cardiacPedalPulses" class="form-control" type="text"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.cardiacPedalPulses.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.cardiacPedalPulses.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.generalAppearanceDateOfLastFluShot.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Comments</label>
              <textarea rows="2" formControlName="cardiacComments" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.cardiacComments.errors }"
                placeholder="Please Enter...."></textarea>
              <div *ngIf="submitted && initialNursingClientEvaluation.cardiacComments.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.cardiacComments.errors.required">required</div>
              </div>
            </div>
          </div>


          <div class="form-row row">
            <div class="col-12">
              <h6 class="section">RESPIRATORY</h6>
            </div>
            <div class="form-group col-md-3 col-sm-4">
              <label>Breath Sounds<span></span></label>
              <select formControlName="respiratoryBreathSounds" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.respiratoryBreathSounds.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Wheezing">Wheezing</option>
                <option value="Rales">Rales</option>
                <option value="Rhonchi">Rhonchi</option>
                <option value="Diminished">Diminished</option>
                <option value="Absent">Absent</option>
                <option value="WNL">WNL</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.respiratoryBreathSounds.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.respiratoryBreathSounds.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-4">
              <label>Cough<span></span></label>
              <select formControlName="respiratoryCough" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.respiratoryCough.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Productive">Productive</option>
                <option value="Non-Productive">Non-Productive</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.respiratoryCough.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.respiratoryCough.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-4">
              <label>Mucous Membranes<span></span></label>
              <select formControlName="respiratoryMucousMembranes" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.respiratoryMucousMembranes.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Dry">Dry</option>
                <option value="Irritated">Irritated</option>
                <option value="Moist/Pink">Moist/Pink</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.respiratoryMucousMembranes.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.respiratoryMucousMembranes.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Comments</label>
              <textarea rows="2" formControlName="respiratoryComments" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.respiratoryComments.errors }"
                placeholder="Please Enter...."></textarea>
              <div *ngIf="submitted && initialNursingClientEvaluation.respiratoryComments.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.respiratoryComments.errors.required">required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="col-12">
              <h6 class="section">GASTROINTESTINAL</h6>
            </div>
            <div class="form-group col-md-2 col-sm-6">
              <label>Abdomen<span></span></label>
              <select formControlName="gastrointestinalAbdomen" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.gastrointestinalAbdomen.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Flat">Flat</option>
                <option value="Firm">Firm</option>
                <option value="Soft">Soft</option>
                <option value="Diminished">Diminished</option>
                <option value="Distended">Distended</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.gastrointestinalAbdomen.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.gastrointestinalAbdomen.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-4 col-sm-6">
              <label>Bowel Sounds<span></span></label>
              <input formControlName="gastrointestinalBowelSounds" class="form-control" placeholder="Please Enter...."
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.gastrointestinalBowelSounds.errors }" />
              <div *ngIf="submitted && initialNursingClientEvaluation.gastrointestinalBowelSounds.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.gastrointestinalBowelSounds.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Complaints<span></span></label>
              <select formControlName="gastrointestinalComplaints" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.gastrointestinalComplaints.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Nausea">Nausea</option>
                <option value="Vomiting">Vomiting</option>
                <option value="Diarrhea">Diarrhea</option>
                <option value="Constipation">Constipation</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.gastrointestinalComplaints.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.gastrointestinalComplaints.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Date of Last BM if Known</label>
              <input formControlName="gastrointestinalDateOfLastBMIfKnown" class="form-control" type="date"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.gastrointestinalDateOfLastBMIfKnown.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.gastrointestinalDateOfLastBMIfKnown.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.gastrointestinalDateOfLastBMIfKnown.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Comments</label>
              <textarea rows="2" formControlName="gastrointestinalComments" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.gastrointestinalComments.errors }"
                placeholder="Please Enter...."></textarea>
              <div *ngIf="submitted && initialNursingClientEvaluation.gastrointestinalComments.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.gastrointestinalComments.errors.required">required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="col-12">
              <h6 class="section">GENITO-URINARY</h6>
            </div>
            <div class="form-group col-md-2 col-sm-12">
              <label>Bowel & Bladder<span></span></label>
              <select formControlName="genitoUrinaryBowelAndBladder" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.genitoUrinaryBowelAndBladder.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Continent">Continent</option>
                <option value="Incontinent">Incontinent</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.genitoUrinaryBowelAndBladder.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.genitoUrinaryBowelAndBladder.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-6">
              <label>Foley Present<span></span></label>
              <select (change)="SetFoleyPresentValidator($event.target.value)"
                formControlName="genitoUrinaryFoleyPresent" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.genitoUrinaryFoleyPresent.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.genitoUrinaryFoleyPresent.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.genitoUrinaryFoleyPresent.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-5 col-sm-6">
              <label>Size <span class="op"> (If Foley Present)</span></label>
              <input formControlName="genitoUrinarySize" class="form-control" placeholder="Please Enter...."
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.genitoUrinarySize.errors }" />
              <div *ngIf="submitted && initialNursingClientEvaluation.genitoUrinarySize.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.genitoUrinarySize.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-4">
              <label>Urine Characteristics<span></span></label>
              <select formControlName="genitoUrinaryUrineCharacteristics" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.genitoUrinaryUrineCharacteristics.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Clear">Clear</option>
                <option value="Dark">Dark</option>
                <option value="Sediment">Sediment</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.genitoUrinaryUrineCharacteristics.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.genitoUrinaryUrineCharacteristics.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Comments</label>
              <textarea rows="2" formControlName="genitoUrinaryComments" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.genitoUrinaryComments.errors }"
                placeholder="Please Enter...."></textarea>
              <div *ngIf="submitted && initialNursingClientEvaluation.genitoUrinaryComments.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.genitoUrinaryComments.errors.required">required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="col-12">
              <h6 class="section">MUSCULOSKELETAL</h6>
            </div>
            <div class="form-group col-md-3 col-sm-12">
              <label>Gait<span></span></label>
              <select formControlName="musculoskeletalGait" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.musculoskeletalGait.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Steady">Steady</option>
                <option value="Unsteady">Unsteady</option>
                <option value="NWB">NWB</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.musculoskeletalGait.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.musculoskeletalGait.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-3 col-sm-4">
              <label>Assisted Devices<span></span></label>
              <select formControlName="musculoskeletalAssistedDevices" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.musculoskeletalAssistedDevices.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Walker">Walker</option>
                <option value="Cane">Cane</option>
                <option value="Crutches">Crutches</option>
                <option value="W/C">W/C</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.musculoskeletalAssistedDevices.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.musculoskeletalAssistedDevices.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-5 col-sm-8">
              <label>Other Devices</label>
              <input formControlName="musculoskeletalAssistedDevicesOther" class="form-control"
                placeholder="Please Enter...."
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.musculoskeletalAssistedDevicesOther.errors }" />
              <div *ngIf="submitted && initialNursingClientEvaluation.musculoskeletalAssistedDevicesOther.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.musculoskeletalAssistedDevicesOther.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Amputation<span></span></label>
              <select (change)="SetAmputationValidator($event.target.value)" formControlName="musculoskeletalAmputation"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.musculoskeletalAmputation.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.musculoskeletalAmputation.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.musculoskeletalAmputation.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-4 col-sm-8">
              <label>Amputation Location</label>
              <input formControlName="musculoskeletalAmputationLocation" class="form-control" type="text"
                placeholder="Please Enter...."
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.musculoskeletalAmputationLocation.errors }" />
              <div *ngIf="submitted && initialNursingClientEvaluation.musculoskeletalAmputationLocation.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.musculoskeletalAmputationLocation.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Splints<span></span></label>
              <select (change)="SetSplintValidator($event.target.value)" formControlName="musculoskeletalSplints"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.musculoskeletalSplints.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.musculoskeletalSplints.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.musculoskeletalSplints.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-4 col-sm-8">
              <label>Splints Location</label>
              <input formControlName="musculoskeletalSplintsLocation" class="form-control" type="text"
                placeholder="Please Enter...."
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.musculoskeletalSplintsLocation.errors }" />
              <div *ngIf="submitted && initialNursingClientEvaluation.musculoskeletalSplintsLocation.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.musculoskeletalSplintsLocation.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Comments</label>
              <textarea rows="2" formControlName="musculoskeletalComments" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.musculoskeletalComments.errors }"
                placeholder="Please Enter...."></textarea>
              <div *ngIf="submitted && initialNursingClientEvaluation.musculoskeletalComments.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.musculoskeletalComments.errors.required">required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="col-12">
              <h6 class="section">INTEGUMENTARY</h6>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Temperature<span></span></label>
              <select formControlName="integumentaryTemperature" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.integumentaryTemperature.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Hot">Hot</option>
                <option value="Warm">Warm</option>
                <option value="Cool">Cool</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.integumentaryTemperature.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.integumentaryTemperature.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Scalp<span></span></label>
              <select formControlName="integumentaryScalp" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.integumentaryScalp.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Dry">Dry</option>
                <option value="Oily">Oily</option>
                <option value="Flaking">Flaking</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.integumentaryScalp.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.integumentaryScalp.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Color<span></span></label>
              <select formControlName="integumentaryColor" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.integumentaryColor.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="WNL">WNL</option>
                <option value="Cyanotic">Cyanotic</option>
                <option value="Jaundiced">Jaundiced</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.integumentaryColor.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.integumentaryColor.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-6">
              <label>Evidence of Parasites<span></span></label>
              <select (change)="SetEvidenceParasitesValidator($event.target.value)"
                formControlName="integumentaryEvidenceOfParasites" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.integumentaryEvidenceOfParasites.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.integumentaryEvidenceOfParasites.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.integumentaryEvidenceOfParasites.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-4 col-sm-6">
              <label>Location</label>
              <input formControlName="integumentaryEvidenceOfParasitesLocation" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.integumentaryEvidenceOfParasitesLocation.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.integumentaryEvidenceOfParasitesLocation.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.integumentaryEvidenceOfParasitesLocation.errors.required">
                  required</div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Comments</label>
              <textarea rows="2" formControlName="integumentaryComments" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.integumentaryComments.errors }"
                placeholder="Please Enter...."></textarea>
              <div *ngIf="submitted && initialNursingClientEvaluation.integumentaryComments.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.integumentaryComments.errors.required">required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="col-12">
              <h6 class="section">PAIN</h6>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Are you having any pain? <span>*</span></label>
              <select (change)="SetPainSectionValidator($event.target.value)" formControlName="painAny"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.painAny.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.painAny.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.painAny.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Scale</label>
              <select formControlName="painScale" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.painScale.errors }">
                <option disabled value="" selected>Please Select</option>
                <option *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]" value="{{item}}">{{item}}</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.painScale.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.painScale.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Location<span></span></label>
              <select formControlName="painLocation" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.painLocation.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Back">Back</option>
                <option value="Neck">Neck</option>
                <option value="Bone">Bone</option>
                <option value="Chest">Chest</option>
                <option value="HA">HA</option>
                <option value="Hip">Hip</option>
                <option value="Joint">Joint</option>
                <option value="Incision">Incision</option>
                <option value="Soft Tissue">Soft Tissue</option>
                <option value="Visceral">Visceral</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.painLocation.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.painLocation.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-6">
              <label>Client description of Pain<span></span></label>
              <select formControlName="painClientDescriptionOfPain" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.painClientDescriptionOfPain.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Sharp">Sharp</option>
                <option value="Dull">Dull</option>
                <option value="Aching">Aching</option>
                <option value="Cramping">Cramping</option>
                <option value="Pinching">Pinching</option>
                <option value="Burning">Burning</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.painClientDescriptionOfPain.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.painClientDescriptionOfPain.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-6">
              <label>Objective Indicators of Pain<span></span></label>
              <select formControlName="painObjectiveIndicatorsOfPain" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.painObjectiveIndicatorsOfPain.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Moaning">Moaning</option>
                <option value="Crying">Crying</option>
                <option value="Winching">Winching</option>
                <option value="Grimacing">Grimacing</option>
                <option value="Guarding">Guarding</option>
                <option value="Frowning">Frowning</option>
                <option value="Calling Out">Calling Out</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.painObjectiveIndicatorsOfPain.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.painObjectiveIndicatorsOfPain.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-12 col-sm-12">
              <label>What makes your pain feel better?</label>
              <input formControlName="painWhatMakesYourPainFeelBetter" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.painWhatMakesYourPainFeelBetter.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.painWhatMakesYourPainFeelBetter.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.painWhatMakesYourPainFeelBetter.errors.required">
                  required</div>
              </div>
            </div>
            <div class="form-group col-md-12 ">
              <label>What makes your pain feel worse?</label>
              <input formControlName="painWhatMakesYourPainFeelWorse" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.painWhatMakesYourPainFeelWorse.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.painWhatMakesYourPainFeelWorse.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.painWhatMakesYourPainFeelWorse.errors.required">
                  required</div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Comments</label>
              <textarea rows="2" formControlName="painComments" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.painComments.errors }"
                placeholder="Please Enter...."></textarea>
              <div *ngIf="submitted && initialNursingClientEvaluation.painComments.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.painComments.errors.required">required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="col-12">
              <h6 class="section">ORAL</h6>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Teeth<span></span></label>
              <select formControlName="oralTeeth" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.oralTeeth.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Natural Teeth">Natural Teeth</option>
                <option value="Broken Teeth">Broken Teeth</option>
                <option value="Loose Teeth">Loose Teeth</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.oralTeeth.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.oralTeeth.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Dentures<span></span></label>
              <select formControlName="oralDentures" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.oralDentures.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Full">Full</option>
                <option value="Partial">Partial</option>
                <option value="Upper">Upper</option>
                <option value="Lower">Lower</option>
                <option value="None">None</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.oralDentures.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.oralDentures.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Gums<span></span></label>
              <select formControlName="oralGums" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.oralGums.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="No Gums">No Gums</option>
                <option value="Red/Swollen">Red/Swollen</option>
                <option value="Lumps/Growths">Lumps/Growths</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.oralGums.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.oralGums.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Oral Assessment<span></span></label>
              <select formControlName="oralAssessment" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.oralAssessment.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Red/White Patches">Red/White Patches</option>
                <option value="Tongue Normal">Tongue Normal</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.oralAssessment.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.oralAssessment.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-4">
              <label>Hygiene<span></span></label>
              <select formControlName="oralHygiene" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.oralHygiene.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Good">Good</option>
                <option value="Needs Improvement">Needs Improvement</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.oralHygiene.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.oralHygiene.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Comments</label>
              <textarea rows="2" formControlName="oralComments" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.oralComments.errors }"
                placeholder="Please Enter...."></textarea>
              <div *ngIf="submitted && initialNursingClientEvaluation.oralComments.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.oralComments.errors.required">required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="col-12">
              <h6 class="section">AGENCY’S ABILITY TO SERVICE CLIENT</h6>
            </div>
            <div class="form-group col-md-3 col-sm-6">
              <label>Service Request<span>*</span></label>
              <select formControlName="serviceRequest" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.serviceRequest.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="Home Maker">Home Maker</option>
                <option value="Home Health">Home Health</option>
                <option value="Aide">Aide</option>
                <option value="Companion">Companion</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.serviceRequest.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.serviceRequest.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-2 col-sm-6">
              <label>Total # Hours <span>*</span></label>
              <input formControlName="serviceTotalHour" class="form-control" type="number"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.serviceTotalHour.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.serviceTotalHour.errors" class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.serviceTotalHour.errors.required">
                  required</div>
              </div>
            </div>
            <div class="form-group col-md-4 col-sm-12">
              <label>Appropriate staff available for service requested<span>*</span></label>
              <select formControlName="isAppropriateStaffAvailable" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.isAppropriateStaffAvailable.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.isAppropriateStaffAvailable.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.isAppropriateStaffAvailable.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Comments</label>
              <textarea rows="2" formControlName="serviceRequestComments" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.serviceRequestComments.errors }"
                placeholder="Please Enter...."></textarea>
              <div *ngIf="submitted && initialNursingClientEvaluation.serviceRequestComments.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.serviceRequestComments.errors.required">required</div>
              </div>
            </div>
            <div class="form-group col-md-4 col-sm-6">
              <label>Estimated start of care date<span>*</span></label>
              <input formControlName="serviceRequestEstimatedStartDate" class="form-control" type="date"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.serviceRequestEstimatedStartDate.errors }"
                placeholder="Please Enter...." />
              <div *ngIf="submitted && initialNursingClientEvaluation.serviceRequestEstimatedStartDate.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.serviceRequestEstimatedStartDate.errors.required">
                  required</div>
              </div>
            </div>
            <div class="form-group col-md-4 col-sm-6">
              <label>Service plan completed<span>*</span></label>
              <select formControlName="serviceRequestPlanCompleted" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.serviceRequestPlanCompleted.errors }">
                <option disabled value="" selected>Please Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="submitted && initialNursingClientEvaluation.serviceRequestPlanCompleted.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.serviceRequestPlanCompleted.errors.required">required
                </div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Nurse’s admission notes<span>*</span></label>
              <textarea rows="6" formControlName="nursesAdmissionNote" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && initialNursingClientEvaluation.nursesAdmissionNote.errors }"
                placeholder="Please Enter...."></textarea>
              <div *ngIf="submitted && initialNursingClientEvaluation.nursesAdmissionNote.errors"
                class="invalid-feedback">
                <div *ngIf="initialNursingClientEvaluation.nursesAdmissionNote.errors.required">required</div>
                <div *ngIf="initialNursingClientEvaluation.nursesAdmissionNote.errors.maxlength">max character {{initialNursingClientEvaluation.nursesAdmissionNote.value.length}} / 800</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt15">
          <div class="col-md-4 offset-md-4 col-xs-12">
            <button type="submit" class="btn btn-block btn-custom">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
