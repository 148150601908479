<div class="row" style="margin-top: 20px;">
  <div class="col-md-10 col-sm-12 offset-md-1">
    <div *ngIf="employeePayrollHoursLists" class="row">
      <div class="col-md-2 text-bold">Total Employees: {{employeePayrollHoursLists.length}}</div>
      <div class="col-md-2 text-bold">Regular: {{employeeTotalRegular}}</div>
      <div class="col-md-2 text-bold">OverTime: {{employeeTotalOT}}</div>
      <div *ngIf="employeeTotalRegularHoliday > 0" class="col-md-2 text-bold">Holiday: {{employeeTotalRegularHoliday}}
      </div>
      <div *ngIf="employeeTotalRegularHolidayOT > 0" class="col-md-2 text-bold">Holiday OT:
        {{employeeTotalRegularHolidayOT}}</div>
      <!-- <div class="col-md-2 text-bold">24Hr Days: {{Round(employeeTotalFullDays)}}</div> -->
      <div *ngIf="employeeTotalFullDayHoliday > 0" class="col-md-2 text-bold">24Hr Holiday:
        {{Round(employeeTotalFullDayHoliday)}}</div>
    </div>
    <p *ngIf="employeePayrollHoursLists" class="print-only text-bold" style="display: none;">Payroll Period:
      {{employeeStartDate}} - {{employeeEndDate}}</p>
    <ng-container *ngFor="let employeePayrollHours of employeePayrollHoursLists; index as j">
      <!-- *ngIf="employeePayrollHours.employeeName != null && employeePayrollHours.subTotalSites.length > 0; then thenTemplate; else elseTemplate"> -->
      <!-- </ng-container>
    <ng-template #thenTemplate> -->

      <div *ngIf="employeePayrollHours.employeeName != null || employeePayrollHours.employeeName != ''"
        class="accordion mt10" id="accordionExample1">
        <div class="card">
          <div class="card-header" [id]="'heading' + j">
            <h2 class="mb-0">
              <a role="button" class="btn btn-link btn-block text-left" data-toggle="collapse" data-parent="#accordion"
                [href]="'#collapse' + j" aria-expanded="true" [attr.aria-controls]="'collapse' + j">
                <div class="row">
                  <div class="col-md-2">
                    <p class="text-bold btn-color-black">{{employeePayrollHours.employeeName}}</p>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-2">
                        <p class="text-center font13 text-bold btn-color-black">Total Hours:
                          {{employeePayrollHours.totalHours}} </p>
                      </div>
                      <div class="col-md-2">
                        <p class="text-center font13 text-bold btn-color-black">Regular Hours:
                          {{employeePayrollHours.totalRegular}}
                        </p>
                      </div>
                      <div class="col-md-2">
                        <p class="text-center font13 text-bold btn-color-black">Over Time:
                          {{employeePayrollHours.totalOT}} </p>
                      </div>
                      <div *ngIf="employeePayrollHours.totalHoliday > 0" class="col-md-2">
                        <p class="text-center font13 text-bold btn-color-black">Holiday:
                          {{employeePayrollHours.totalHoliday}} </p>
                      </div>
                      <div *ngIf="employeePayrollHours.totalHolidayOT > 0" class="col-md-2">
                        <p class="text-center font13 text-bold btn-color-black">Holiday OT:
                          {{employeePayrollHours.totalHolidayOT}} </p>
                      </div>

                      <ng-container *ngIf="employeePayrollHours.totalFullDay > 0">
                        <div class="col-md-2">
                          <p class="text-center font13 text-bold btn-color-black">24 Hr :
                            {{Round(employeePayrollHours.totalFullDay)}} {{employeePayrollHours.totalFullDay == 1 ?
                            'Day': 'Days'}} </p>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="employeePayrollHours.totalFullDayHoliday > 0">
                        <div class="col-md-2">
                          <p class="text-center font13 text-bold btn-color-black">24 Hr Holiday :
                            {{Round(employeePayrollHours.totalFullDayHoliday)}}
                            {{employeePayrollHours.totalFullDayHoliday == 1 ? 'Day': 'Days'}} </p>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <ng-container *ngFor="let subTotal of employeePayrollHours.subTotalSites; index as i">
                      <div class="row pt10" style="border-top: 1px solid #222">
                        <div class="col-md-4">
                          <p class="text-bold font12">{{subTotal.siteName}}</p>
                        </div>
                        <div class="col-md-2">
                          <span class="text-center font12 text-bold ">Regular Hours: {{subTotal.subTotalRegular}}
                          </span>
                        </div>
                        <div class="col-md-2">
                          <span class="text-center font12 text-bold">Overtime Hours: {{subTotal.subTotalOT}}</span>
                        </div>
                        <div *ngIf="subTotal.subTotalHoliday > 0" class="col-md-2">
                          <span class="text-center font12 text-bold">Holiday: {{subTotal.subTotalHoliday}}</span>
                        </div>
                        <div *ngIf="subTotal.subTotalHolidayOT > 0" class="col-md-2">
                          <span class="text-center font12 text-bold">Holiday OT: {{subTotal.subTotalHolidayOT}}</span>
                        </div>
                        <div *ngIf="subTotal.subTotalFullDay > 0" class="col-md-2">
                          <span class="text-center font12 text-bold">24 Hr Day: {{Round(subTotal.subTotalFullDay) +
                            (subTotal.subTotalFullDayHoliday > 0 ?Round(subTotal.subTotalFullDayHoliday):0)}}
                            {{subTotal.subTotalFullDay == 1 ? 'Day':'Days'}} </span>
                        </div>
                        <div class="col-md-6">
                          <table class="table headertable" style="font-size: 14px;">
                            <thead class="">
                              <tr>
                                <th>Role Name</th>
                                <th>Regular</th>
                                <th>Over Time</th>
                                <!-- <ng-container *ngIf="data.roleName == '24 Hour Care'"> -->
                                <!-- <th>24 Day</th> -->
                                <th>Holiday</th>
                                <th>Holiday OT</th>
                                <th style="border-left: 1px solid #dee2e6;">Total Hours</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let data of subTotal.data">
                                <td>{{data.roleName}}</td>
                                <td>{{data.regular}}</td>
                                <td>{{data.ot}}</td>
                                <!-- <ng-container *ngIf="data.roleName == '24 Hour Care'"> -->
                                <!-- <td>{{Round(data.fullDay)}}</td> -->
                                <td>{{data.fullDayHoliday > 0 ? Round(data.fullDayHoliday) : data.holiday}}</td>
                                <td>{{data.holidayOT}}</td>
                                <td style="border-left: 1px solid #dee2e6;">{{data.hours }}</td>
                                  <!-- <td style="border-left: 1px solid #dee2e6;">{{data.hours}}</td> -->
                                <!-- <td style="border-left: 1px solid #dee2e6;">{{data.hours + data.holiday  + (data.fullDayHoliday > 0 ? Round(data.fullDayHoliday) : 0) + (data.fullDay > 0 ? Round(data.fullDay) : 0)}}</td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </a>
            </h2>
          </div>
          <div [id]="'collapse' + j" class="collapse hide" [attr.aria-labelledby]="'heading' + j"
            data-parent="#accordionExample1">
            <div class="card-body">
              <ng-container *ngFor="let subTotal of employeePayrollHours.subTotalSites; index as i">
                <div class="row mt5">
                  <div class="col-md-12">
                    <div class="accordion" id="accordionExample">
                      <div class="card">
                        <div class="card-header" style="background-color: #D3E4CD !important;" [id]="'headingg' + i">
                          <h2 class="mb-0">
                            <a role="button" class="btn-link btn-color-black" data-toggle="collapse"
                              data-parent="#accordion" [href]="'#collapsee' + i" aria-expanded="true"
                              [attr.aria-controls]="'collapsee' + i">
                              <div class="row">
                                <div class="col-md-3">
                                  <h6 class="text-bold font12">{{subTotal.siteName}}</h6>
                                </div>
                                <div class="col-md-2">
                                  <p class="text-center font12 text-bold ">Regular Hours: {{subTotal.subTotalRegular}}
                                  </p>
                                </div>
                                <div class="col-md-2">
                                  <p class="text-center font12 text-bold">Overtime Hours: {{subTotal.subTotalOT}}</p>
                                </div>
                                <div *ngIf="subTotal.subTotalHoliday > 0" class="col-md-2">
                                  <p class="text-center font12 text-bold">Holiday: {{subTotal.subTotalHoliday}}</p>
                                </div>
                                <div *ngIf="subTotal.subTotalHolidayOT > 0" class="col-md-2">
                                  <p class="text-center font12 text-bold">Holiday OT: {{subTotal.subTotalHolidayOT}}</p>
                                </div>
                                <div *ngIf="subTotal.subTotalFullDay > 0" class="col-md-2">
                                  <p class="text-center font12 text-bold">24 Hr Day: {{Round(subTotal.subTotalFullDay) +
                                    (subTotal.subTotalFullDayHoliday > 0 ?Round(subTotal.subTotalFullDayHoliday):0)}}
                                    {{subTotal.subTotalFullDay == 1 ? 'Day':'Days'}} </p>
                                </div>
                              </div>
                            </a>
                          </h2>
                        </div>

                        <div data-parent="#accordionExample" [id]="'collapsee' + i" class="collapse show"
                           [attr.aria-labelledby]="'headingg' + i"
                          >
                          <div class="card-body">
                            <table class="table" style="font-size: 14px;">
                              <thead class="">
                                <tr>
                                  <th>Role Name</th>
                                  <th>Regular</th>
                                  <th>Over Time</th>
                                  <!-- <th>24 Day</th> -->
                                  <th>Holiday</th>
                                  <th>Holiday OT</th>
                                  <th style="border-left: 1px solid #dee2e6;">Total Hours</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let data of subTotal.data">
                                  <td>{{data.roleName}}</td>
                                  <td>{{data.regular}}</td>
                                  <td>{{data.ot}}</td>
                                  <!-- <td>{{Round(data.fullDay)}}</td> -->
                                  <td>{{data.fullDayHoliday > 0 ? Round(data.fullDayHoliday) : data.holiday}}</td>
                                  <td>{{data.holidayOT}}</td>
                                  <td style="border-left: 1px solid #dee2e6;">{{data.hours }}</td>
                                    <!-- <td style="border-left: 1px solid #dee2e6;">{{data.hours}}</td> -->
                                  <!-- <td style="border-left: 1px solid #dee2e6;">{{data.hours + data.holiday + (data.fullDayHoliday > 0 ? Round(data.fullDayHoliday) : 0) + (data.fullDay > 0 ? Round(data.fullDay) : 0)}}</td> -->
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- </ng-template>
    <ng-template #elseTemplate>
      <button class="btn btn-link btn-block text-center" type="button" data-toggle="collapse">
        <div class="beautifyBox" style="padding: 50px;">
          <h4>Record Not Found</h4>
        </div>
      </button>
    </ng-template> -->
  </div>
</div>
