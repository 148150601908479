import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { MustMatch } from '../../shared/helpers/mustmatch.validator';
@Component({
  selector: 'app-createuser',
  templateUrl: './createuser.component.html',
  styleUrls: ['./createuser.component.css']
})
export class CreateuserComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder, private repo: RepositoryService,
    private toastr: ToastrService, private spinner: SpinnerService) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      // confrimPasswords: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      type: ['', [Validators.required]]
    }, {
      // validator: MustMatch('password', 'confrimPasswords')
    });
  }
  get f() { return this.registerForm.controls; }

  onSubmit(): void {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.spinner.showSpinner();
    this.repo.create('api/User/Insert', this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.toastr.success('User Added Successfully', 'Success');
            this.onReset();
            this.registerForm.controls.type.setValue('');

          }
          else {
            this.toastr.error(data['ReturnMessage'], 'Error');
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  onReset(): void {
    this.submitted = false;
    this.registerForm.reset();
  }
}
