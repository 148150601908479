import { DOCUMENT, formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Job } from 'src/app/model/job.model';
import { ServiceConfiguration } from 'src/app/model/serviceconfiguration.model';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { MeridiemType } from "src/app/enum/meridiemtype.enum";
import { first } from 'rxjs/operators';
import { ClientBillingConfiguration } from "src/app/model/clientbillingconfiguration.model";
import { ClientBillingDayConfiguration } from 'src/app/model/clientbillingdayconfiguration.model';
import { StatusType } from 'src/app/enum/statustype.enum';

@Component({
  selector: 'app-clientbillingconfiguration',
  templateUrl: './clientbillingconfiguration.component.html',
  styleUrls: ['./clientbillingconfiguration.component.css']
})
export class ClientbillingconfigurationComponent implements OnInit {
  registerForm: FormGroup;
  registerForm2: FormGroup;
  statusType = StatusType;
  clientBillingDayConfigurations: FormArray;
  submitted = false;
  submitted2 = false;
  public jobList: Job[];
  meridiemType = MeridiemType;
  clientBillingConfigurations: ClientBillingConfiguration[] = [];
  clientBillingConfigurationUpdate: ClientBillingConfiguration;
  serviceConfigurationList: ServiceConfiguration[] = [];
  dateList: any;
  html: string = '';
  date;
  currentDate;
  priorDate;
  currentWeek;
  showCustomDateRange: boolean;
  showCustomDateRange2: boolean;
  displayStyle = 'none';
  isUpdate: boolean = false;
  clientBillingConfigurationGroup: any[];
  BreakException = {};


  constructor(@Inject(LOCALE_ID) private locale: string, private formBuilder: FormBuilder, private repo: RepositoryService,
    private toastr: ToastrService, private spinner: SpinnerService,
    // tslint:disable-next-line: variable-name
    private _renderer2: Renderer2,
    // tslint:disable-next-line: variable-name
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {
    this.DateDropDowns();
    this.GetJobs();
    this.registerForm = this.formBuilder.group({
      sites: [''],
    });
    this.registerForm2 = this.formBuilder.group({
      client: ['', [Validators.required]],
      room: ['', [Validators.required]],
      startDate: [''],
      endDate: [''],
      definedDate: [formatDate(this.currentDate = new Date(), 'MM/dd/yyyy', this.locale)],
      clientBillingDayConfigurations: this.formBuilder.array([])
    });
  }
  createItemUpdate(e: ClientBillingDayConfiguration, meridiemtype?: string) {
    return this.formBuilder.group({
      serviceConfigurationId: [e.serviceConfigurationId, [Validators.required]],
      name: [e.serviceConfiguration.name],
      isMeridiem: [e.serviceConfiguration.isMeridiem],
      type: [(e.serviceConfiguration.isMeridiem ? meridiemtype ? meridiemtype : e.type : '')],
      monday: [e.monday],
      tuesday: [e.tuesday],
      wednesday: [e.wednesday],
      thursday: [e.thursday],
      friday: [e.friday],
      saturday: [e.saturday],
      sunday: [e.sunday],
    });
  }
  createItem(ele: ServiceConfiguration, meridiemtype?: string): FormGroup {
    return this.formBuilder.group({
      serviceConfigurationId: [ele.id, [Validators.required]],
      name: [ele.name],
      isMeridiem: [ele.isMeridiem],
      type: [(ele.isMeridiem ? meridiemtype ? meridiemtype : '0' : '')],
      monday: [0],
      tuesday: [0],
      wednesday: [0],
      thursday: [0],
      friday: [0],
      saturday: [0],
      sunday: [0],
    });
  }
  addItem(): void {
    this.clientBillingDayConfigurations = this.registerForm2.get('clientBillingDayConfigurations') as FormArray;
    if (this.clientBillingConfigurationUpdate) { //add exist rows
      this.serviceConfigurationList.forEach(ele => {
        this.clientBillingConfigurationUpdate.clientBillingDayConfigurations.forEach(e => {
          if (!(this.clientBillingDayConfigurations.controls.some(x => x['controls'].serviceConfigurationId.value == ele.id))) {
            if (this.clientBillingConfigurationUpdate.clientBillingDayConfigurations.some(x => x.serviceConfigurationId == ele.id)) {
              if (ele.isMeridiem) {

                for (let item in MeridiemType) {
                  if (Number(item) || Number(item) == 0) {
                    if (this.clientBillingConfigurationUpdate.clientBillingDayConfigurations.some(x => x.serviceConfigurationId == ele.id && MeridiemType[x.type] == MeridiemType[item]))
                      this.clientBillingDayConfigurations.push(this.createItemUpdate(this.clientBillingConfigurationUpdate.clientBillingDayConfigurations.find(x => x.serviceConfigurationId == ele.id && MeridiemType[x.type] == MeridiemType[item]), item));
                    else
                      this.clientBillingDayConfigurations.push(this.createItem(ele, item));
                  }
                }
              }
              else
                this.clientBillingDayConfigurations.push(this.createItemUpdate(this.clientBillingConfigurationUpdate.clientBillingDayConfigurations.find(x => x.serviceConfigurationId == ele.id)));
            }
            else {
              if (ele.isMeridiem) {
                for (let item in MeridiemType) {
                  if (Number(item) || Number(item) == 0) {
                    this.clientBillingDayConfigurations.push(this.createItem(ele, item));
                  }
                }
              }
              else
                this.clientBillingDayConfigurations.push(this.createItem(ele));
            }
          }
        });
      });
    }
    else {
      //Add new rows
      if (this.serviceConfigurationList) {
        this.serviceConfigurationList.forEach(ele => {
          if (ele.isMeridiem) {
            for (let item in MeridiemType) {
              if (Number(item) || Number(item) == 0) {
                this.clientBillingDayConfigurations.push(this.createItem(ele, item.toString()));
              }
            }
          }
          else
            this.clientBillingDayConfigurations.push(this.createItem(ele));
        });
      }
    }
  }
  ClosePopup(): void {
    this.clientBillingConfigurationUpdate = null;
    this.isUpdate = false;
    this.displayStyle = 'none';
  }
  OpenPopup(val?: ClientBillingConfiguration) {
    if (val) {
      this.clientBillingConfigurationUpdate = val;
      this.registerForm2.controls.client.setValue(val.client);
      this.registerForm2.controls.room.setValue(val.room);
      this.isUpdate = true;
    }
    else {
      this.clientBillingConfigurationUpdate = null;
      this.onReset2();
    }


    this.GetServiceConfiguration();
    this.InitSelect2();
    this.displayStyle = 'block';
  }
  get f() { return this.registerForm.controls; }
  get a() { return this.registerForm2.controls; }
  get d() { return this.clientBillingDayConfigurations; }

  onSubmit(): void {
    this.submitted = true;
    if (this.showCustomDateRange && !this.CheckDateTimeCondition((document.getElementById('datetimepicker1') as HTMLInputElement).value,
      (document.getElementById('datetimepicker2') as HTMLInputElement).value)) {
      return;
    }
    if ((document.getElementById('sites') as HTMLInputElement).value === '0') {
      this.registerForm.controls.sites.setValue('');
    }
    else {
      this.registerForm.controls.sites.setValue((document.getElementById('sites') as HTMLInputElement).value);
    }
    if (this.registerForm.invalid) {
      return;
    }
    this.GetClientBillingConfiguration(this.registerForm.controls.sites.value);
  }
  CheckDateTimeCondition(start: string, end: string): boolean {
    if (start === '') {
      this.toastr.warning('Start Date Required', 'Required');
      return false;
    }
    else {
      this.registerForm.controls.startDate.setValue((document.getElementById('datetimepicker1') as HTMLInputElement).value);
    }
    if (end === '') {
      this.toastr.warning('End Date Required', 'Oops');
      return false;
    }
    else {
      this.registerForm.controls.endDate.setValue((document.getElementById('datetimepicker2') as HTMLInputElement).value);
    }
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (endDate < startDate) {
      this.toastr.warning('End Date Must be greater then Start Date', 'Oops');
      return false;
    }
    else {
      return true;
    }

  }
  CheckDateTimeCondition2(start: string, end: string): boolean {
    if (start === '') {
      this.toastr.warning('Start Date Required', 'Required');
      return false;
    }
    else {
      this.registerForm2.controls.startDate.setValue((document.getElementById('datetimepicker3') as HTMLInputElement).value);
    }
    if (end === '') {
      this.toastr.warning('End Date Required', 'Oops');
      return false;
    }
    else {
      this.registerForm2.controls.endDate.setValue((document.getElementById('datetimepicker4') as HTMLInputElement).value);
    }
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (endDate < startDate) {
      this.toastr.warning('End Date Must be greater then Start Date', 'Oops');
      return false;
    }
    else {
      return true;
    }

  }
  ngAfterViewInit(): void {
    // this.RunDateTimePicker();
    this.InitSelect2();
  }

  onReset(): void {
    this.submitted = false;
    this.registerForm.reset();
    this.registerForm.controls.sites.setValue(' ');
    this.clientBillingConfigurations = null;

  }
  onReset2(): void {
    this.submitted2 = false;
    if (this.clientBillingDayConfigurations != undefined)
      while (this.clientBillingDayConfigurations.length !== 0) {
        this.clientBillingDayConfigurations.removeAt(0);
      }
    this.registerForm2.reset();
    this.registerForm2.controls.client.setValue('');
    this.registerForm2.controls.definedDate.setValue(formatDate(this.currentDate = new Date(), 'MM/dd/yyyy', this.locale));
  }

  public GetJobs(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/Job/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.jobList = res['data'];
            this.spinner.hideSpinner();
          }
        },
        error: err => {
          this.spinner.hideSpinner();
        }
      });
  }
  GetServiceConfiguration(): void {
    if (this.serviceConfigurationList.length > 0)
      this.serviceConfigurationList = [];
    this.spinner.showSpinner();
    this.repo.getData('api/ServiceConfiguration/GetAll', 1)
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.serviceConfigurationList = res['data'];
            this.addItem();
            this.spinner.hideSpinner();
          }
        },
        error: err => {
          this.spinner.hideSpinner();
        }
      });
  }
  GetClientBillingConfiguration(val: string) {
    this.spinner.showSpinner();
    this.repo.getData('api/ClientBillingConfiguration/GetAll', val)
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            if (res['data'] == '')
              this.toastr.error(res['returnMessage'][0])
            else {
              this.clientBillingConfigurations = res['data'];
              // this.clientBillingConfigurationGroup = this.groupBy(this.clientBillingConfigurations.clientBillingDayConfigurations, 'serviceConfigurationId');
            }
            this.spinner.hideSpinner();
          }
        },
        error: err => {
          this.spinner.hideSpinner();
        }
      });
  }
  GroupData(data: ClientBillingConfiguration) {
    return this.groupBy(data.clientBillingDayConfigurations, 'serviceConfigurationId');
    // this.clientBillingConfigurationGroup =  this.groupBy(data.clientBillingDayConfigurations, 'serviceConfigurationId');
  }
  groupBy(collection, property) {
    var i = 0, val, index,
      values = [], result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1)
        result[val].push(collection[i]);
      else {
        values.push(val);
        result[val] = [];
        result[val].push(collection[i]);
      }
    }
    return result;
  }
  InitSelect2(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $(".select2").select2();
          });
        }
    `;
    this._renderer2.appendChild(this._document.body, script);
  }
  DateDropDowns(): void {
    this.date = new Date();
    this.currentWeek = new Date(this.date.setDate(this.date.getDate() - this.date.getDay()));
    const currentWeek2 = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 6));
    const priorWeek2 = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() - 1));
    const priorWeek = new Date(this.date.setDate(this.date.getDate() - this.date.getDay()));

    this.dateList = [
      {
        date: formatDate(this.currentDate = new Date(), 'MM/dd/yyyy', this.locale),
        showDate: 'Current day ' + formatDate(this.currentDate = new Date(), '(MMM dd)', this.locale)
      },
      {
        date: formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), 'MM/dd/yyyy', this.locale),
        showDate: 'Prior day ' + formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), '(MMM dd)', this.locale)
      },
      {
        date: formatDate(this.currentWeek, 'MM/dd/yyyy', this.locale) + '-' +
          formatDate(currentWeek2, 'MM/dd/yyyy', this.locale),
        showDate: 'Current week ' + formatDate(this.currentWeek, '(MMM dd', this.locale) + ' - ' +
          formatDate(currentWeek2, 'MMM dd)', this.locale)
      },
      {
        date: formatDate(priorWeek, 'MM/dd/yyyy', this.locale) + '-' +
          formatDate(priorWeek2, 'MM/dd/yyyy', this.locale),
        showDate: 'Prior week ' + formatDate(priorWeek, '(MMM dd', this.locale) + ' - ' +
          formatDate(priorWeek2, 'MMM dd)', this.locale)
      },
      {
        date: 'Custom date range',
        showDate: 'Custom date range'
      }

    ];
  }
  RunDateTimePicker(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('.datetimepicker').datetimepicker({
              format: 'MM/DD/YYYY',
              keepOpen:false,
              stepping: 5,
              //sideBySide:true
             // debug: true
            });
          });
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
  DestroyDateTimePicker(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('.datetimepicker').data("DateTimePicker").destroy();
          });
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
  ChangeDateType(value: string): void {
    if (value === 'Custom date range') {
      this.RunDateTimePicker();
      this.showCustomDateRange = true;
    } else {
      this.DestroyDateTimePicker();
      this.showCustomDateRange = false;
    }
  }
  ChangeDateTypeAdd(value: string): void {
    if (value === 'Custom date range') {
      this.RunDateTimePicker();
      this.showCustomDateRange2 = true;
    } else {
      this.DestroyDateTimePicker();
      this.showCustomDateRange2 = false;
    }
  }
  AddRecord() {
    this.submitted2 = true;
    this.registerForm2.controls.client.setValue((<HTMLInputElement>document.getElementById('client')).value);
    if (this.showCustomDateRange2 && !this.CheckDateTimeCondition2((document.getElementById('datetimepicker3') as HTMLInputElement).value,
      (document.getElementById('datetimepicker4') as HTMLInputElement).value)) {
      return;
    }
    if (this.registerForm2.invalid) {
      return;
    }
    this.spinner.showSpinner();
    this.repo.create('api/ClientBillingConfiguration/Insert', this.registerForm2.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.toastr.success(data['returnMessage'][0]);
            this.onReset();
            this.onReset2();
            this.ClosePopup();
          }
          else {
            this.toastr.error(data['returnMessage'][0]);
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }

  Delete(index: number, id: number): void {
    this.spinner.showSpinner();
    this.repo.delete('api/ClientBillingConfiguration/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.clientBillingConfigurations.forEach((ele, i) => {
              if (ele.id === id) {
                this.clientBillingConfigurations.splice(index, 1);
                this.spinner.hideSpinner();
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  isEnumName(val) {
    return !isNaN(Number(val.key));
  }
  QTMinusPlus(ico, index, day) {
    if (ico == "-" && this.d.controls[index].get(day).value == '0')
      return;

    if (ico == "-")
      this.d.controls[index].get(day).setValue((this.d.controls[index].get(day).value - 1));
    if (ico == "+")
      this.d.controls[index].get(day).setValue((this.d.controls[index].get(day).value + 1));
  }
  GetMeridiemType(val) {
    if (val == null)
      return 'N/A';
    return MeridiemType[val];
  }
  ChangeStatus(status: StatusType, id: number): void {
    this.spinner.showSpinner();
    this.repo.getData('api/ClientBillingConfiguration/Disable', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.clientBillingConfigurations.forEach((ele, i) => {
              if (ele.id === id) {
                this.clientBillingConfigurations[i].status = (status === this.statusType.Disabled ?
                  this.statusType.Active : this.statusType.Disabled);
                this.spinner.hideSpinner();
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.toastr.error(error.error.returnMessage[0]);
          this.spinner.hideSpinner();
        });

  }
}
