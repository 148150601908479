<style>
  table thead tr th {
    font-weight: 500 !important;
  }

  .modal-dialog {
    max-width: 1000px !important;
  }

  label span {
    font-size: 10px;
  }

  th span {
    font-size: 10px;
  }

  .modal-body form {
    overflow-y: scroll;
    max-height: 400px;
    overflow-x: hidden;
  }
</style>
<div class="row">
  <div class="col-md-10 offset-md-1">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <form autocomplete="off" [formGroup]="invoiceRegisterForm" (ngSubmit)="onSubmitInvoiceNumber()">
          <div class="row">
            <div class="col-md-12">
              <h5 style="float: left;padding: .75rem 1.25rem">Quick Book Invoice Number</h5>
            </div>
            <div class="col-md-3">
              <input style="margin-left: 23px" type="number" min="1" formControlName="pattren" class="form-control"
                placeholder="Please Enter Invoice Number"
                [ngClass]="{ 'is-invalid': submitted3 && p.pattren.errors }" />
              <div *ngIf="submitted3 && p.pattren.errors" class="invalid-feedback">
                <div *ngIf="p.pattren.errors.required">Invoice Number is required</div>
              </div>
            </div>
            <div class="col-md-2">
              <button type="submit" class="btn btn-custom">Update</button>
            </div>
          </div>
        </form>
        <hr />
        <h5 style="float: left;padding: .75rem 1.25rem">Quick Book Configuration</h5>
        <button (click)="OpenPopup()" style="float: right; margin-top: 5px;" type="button" class="btn btn-custom mr-1">
          <i class="fa fa-plus"></i> Add New </button>
      </div>
      <div>
        <table class="table table-striped">
          <thead class="custom-table-header-color">
            <tr>
              <th>#.</th>
              <th>Facility/Community Name</th>
              <th>Term <span>(InDays)</span></th>
              <th>Allow Online Payment</th>
              <th>Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="quickBookConfigurationList.length > 0; else elseBlock">
              <tr *ngFor="let d of quickBookConfigurationList; index as i">
                <td><b>{{ i +1}}</b></td>
                <td>
                  <p>{{ d.siteName}}</p>
                </td>
                <td>
                  <p>{{ d.term }}</p>
                </td>
                <td>
                  <p>{{ d.allowOnlinePayment? 'Yes': 'No' }}</p>
                </td>
                <td>
                  <p>{{ d.created | date : 'MMM dd yyyy' }}</p>
                </td>
                <td width="10%">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-sliders"></i>
                    </button>
                    <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <button class="dropdown-item" (click)="OpenPopupForUpdate(d)">Edit</button>
                      <button class="dropdown-item" (click)="Delete(i,d.id)">Delete</button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #elseBlock>
              <tr>
                <td colspan="10"></td>
              </tr>
              <tr>
                <td colspan="10" class="text-center">No Record Found......</td>
              </tr>
              <tr>
                <td colspan="10"></td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div [ngStyle]="{'display':displayStyle}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add Quick Book Configuration</h5>
        <button (click)="ClosePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="form-row row">
            <div class="form-group col-md-3">
              <label>Facility/Community</label>
            </div>
            <div class="form-group col-md-8">
              <select formControlName="siteName" id="siteName" class="form-control js-example-basic-single"
                [ngClass]="{ 'is-invalid': submitted && f.siteName.errors }">
                <option value="" selected>Select Facility/Community Name</option>
                <!-- <option *ngFor="let item of siteNameList" value={{item.communityName}}>{{item.communityName}}</option> -->
                <option *ngFor="let job of jobList" value="{{job.job_name}}">
                  {{job.job_name}} - {{job.job_number}}
                </option>
              </select>
              <div *ngIf="submitted && f.siteName.errors" class="invalid-feedback">
                <div *ngIf="f.siteName.errors.required">Facility/Community Name is required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="form-group  col-md-3">
              <label>Term <span>(In days)</span></label>
            </div>
            <div class="form-group col-md-8">
              <input type="number" min="1" formControlName="term" class="form-control" placeholder="Please Enter Term"
                [ngClass]="{ 'is-invalid': submitted && f.term.errors }" />
              <div *ngIf="submitted && f.term.errors" class="invalid-feedback">
                <div *ngIf="f.term.errors.required">Term is required</div>
              </div>
            </div>
          </div>
            <div class="form-row row">
              <div class="form-group  col-md-3">
                <label>Enable Online Payment</label>
              </div>
              <div class="form-group col-md-1">
                <input type="checkbox" formControlName="allowOnlinePayment" style="margin-left: 0px;" class="form-check-input"
                  [ngClass]="{ 'is-invalid': submitted && f.allowOnlinePayment.errors }" />
                <div *ngIf="submitted && f.allowOnlinePayment.errors" class="invalid-feedback">
                  <div *ngIf="f.allowOnlinePayment.errors.required">Allow Online Payment is required</div>
                </div>
              </div>
            </div>

          <!-- <div class="form-row row"> -->
          <div class="addlist" id="row_field" formArrayName="quickBookPriceConfigurations"
            *ngFor="let item of registerForm.get('quickBookPriceConfigurations')['controls']; let i = index;">
            <div [formGroupName]="i" class="form-row row">
              <div class="form-group  col-md-3">
                <label>Role / Type / Rate</label>
              </div>
              <div class="form-group col-md-2">
                <!-- (change)="FindDuplicate($event.target.value)" -->
                <select  formControlName="type" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && quickBookPriceConfigurations.controls[i].get('type')?.errors }">
                  <option value="" selected>Select Type</option>
                  <option value="CNA">CNA</option>
                  <option value="CNA SAME DAY REQUEST">CNA - Same Day Request</option>
                  <option value="CNA COVID">CNA - COVID</option>
                  <option value="CNA - Training / Orientation">CNA - Training / Orientation</option>
                  <option value="Med Tech">MEDTECH</option>
                  <option value="MedTech SAME DAY REQUEST">MEDTECH - Same Day Request</option>
                  <option value="Shift Cancel < 2 Hours - Employee on site (MEDTECH)">Shift Cancel < 2 Hours - Employee on site (MEDTECH)</option>
                  <option value="MedTech COVID">MEDTECH - COVID</option>
                  <option value="MedTech - Training / Orientation">MEDTECH - Training / Orientation</option>
                  <option value="LPN">LPN</option>
                  <option value="LPN SAME DAY REQUEST">LPN - SAME DAY REQUEST</option>
                  <option value="LNP - Training / Orientation">LNP - Training / Orientation</option>
                  <option value="RN">RN</option>
                  <option value="RN - Training / Orientation">RN - Training / Orientation</option>
                  <option value="Home Care Services">Home Care Services</option>
                  <option value="VA Home Care Services">VA Home Care Services</option>
                  <option value="HomeCare -  Training / Orientation">Home Care -  Training / Orientation</option>
                  <option value="Live In">Live In</option>
                  <option value="Live In - Training / Orientation">Live In - Training / Orientation</option>
                  <option value="InSuite Service">InSuite Service</option>
                </select>
                <div class="invalid-feedback"
                  *ngIf="submitted && quickBookPriceConfigurations.controls[i].get('type')?.errors?.required">
                  Type is required
                </div>
              </div>
              <div class="form-group col-md-2">
                <!-- (change)="FindDuplicate($event.target.value)" -->
                <select formControlName="subType" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && quickBookPriceConfigurations.controls[i].get('subType')?.errors }">
                  <option value="" selected>Select Sub Type</option>
                  <!-- <option *ngFor="let item of subTypes" value="{{item.key}}">{{item.value}}</option> -->
                  <option value="WeekDay">Week Day</option>
                  <option value="Weekend">Weekend</option>
                  <option value="Cancel">Cancel</option>
                  <option value="HomeCare">Home Care</option>
                  <option value="Live In">Live In</option>
                  <option value="InSuite">InSuite</option>
                </select>
                <div class="invalid-feedback"
                  *ngIf="submitted && quickBookPriceConfigurations.controls[i].get('subType')?.errors?.required">
                  Sub Type is required
                </div>
              </div>
              <div class="form-group col-md-2">
                <input type="number" formControlName="price" class="form-control" placeholder="Enter Rate"
                  [ngClass]="{ 'is-invalid': submitted && quickBookPriceConfigurations.controls[i].get('price')?.errors }" />
                <div *ngIf="submitted && quickBookPriceConfigurations.controls[i].get('price')?.errors"
                  class="invalid-feedback">
                  <div *ngIf="submitted && quickBookPriceConfigurations.controls[i].get('price')?.errors?.required">
                    Rate is required</div>
                </div>
              </div>
              <div class="form-group col-md-1">
                <i class="fa fa-trash text-danger" (click)="removeItem(i)"
                  *ngIf="quickBookPriceConfigurations.controls.length > 1"></i>
              </div>
              <div class="form-group col-md-1">
                <i *ngIf="quickBookPriceConfigurations.controls.length === i +1 " type="button" (click)="addItem()"
                  class="fa fa-plus text-success"></i>
              </div>
            </div>
          </div>
          <div class="form-row row">
            <div class="form-group col-md-11">
              <div class="text-right">
                <button [ngStyle]="{'cursor': disabledButton?'not-allowed':'pointer'}" [disabled]="disabledButton"
                  type="submit" class="btn btn-custom">
                  <i class="fa fa-plus"></i> Add</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div [ngStyle]="{'display':displayStyle2}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel2">Edit Quick Book Configuration</h5>
        <button (click)="ClosePopupForUpdate()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form autocomplete="off" [formGroup]="registerForm2" (ngSubmit)="Update()">
          <div class="form-row row">
            <div class="form-group col-md-3">
              <label>Facility/Community</label>
            </div>
            <div class="form-group col-md-8">
              <input type="text" formControlName="siteName" class="form-control" [readonly]="true">
              <!-- <select formControlName="siteName" class="form-control" [readonly]="true"
                [ngClass]="{ 'is-invalid': submitted2 && u.siteName.errors }">
                <option value="" selected>Select Facility/Community Name</option>
                <option *ngFor="let item of siteNameList" value={{item.communityName}}>{{item.communityName}}</option>
              </select> -->
              <div *ngIf="submitted2 && u.siteName.errors" class="invalid-feedback">
                <div *ngIf="u.siteName.errors.required">Facility/Community Name is required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="form-group  col-md-3">
              <label>Term <span>(In days)</span></label>
            </div>
            <div class="form-group col-md-8">
              <input type="number" formControlName="term" class="form-control" placeholder="Please Enter Term"
                [ngClass]="{ 'is-invalid': submitted2 && u.term.errors }" />
              <div *ngIf="submitted2 && u.term.errors" class="invalid-feedback">
                <div *ngIf="u.term.errors.required">Term is required</div>
              </div>
            </div>
          </div>
          <div class="form-row row">
            <div class="form-group  col-md-3">
              <label>Enable Online Payment</label>
            </div>
            <div class="form-group col-md-8">
              <input type="checkbox" formControlName="allowOnlinePayment" style="margin-left: 0px;" class="form-check-input"
                [ngClass]="{ 'is-invalid': submitted && u.allowOnlinePayment.errors }" />
              <div *ngIf="submitted && u.allowOnlinePayment.errors" class="invalid-feedback">
                <div *ngIf="u.allowOnlinePayment.errors.required">Allow Online Payment is required</div>
              </div>
            </div>
          </div>
          <!-- <div class="form-row row"> -->
          <div class="addlist" id="row_field" formArrayName="quickBookPriceConfigurations"
            *ngFor="let item of registerForm2.get('quickBookPriceConfigurations')['controls']; let i = index;">
            <div [formGroupName]="i" class="form-row row">
              <div class="form-group  col-md-3">
                <label>Role / Type / Rate</label>
              </div>
              <div class="form-group col-md-2">
                <select formControlName="type" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted2 && quickBookPriceConfigurations.controls[i].get('type')?.errors }">
                  <option value="" selected>Select Type</option>
                  <option value="CNA">CNA</option>
                  <option value="CNA SAME DAY REQUEST">CNA - Same Day Request</option>
                  <option value="CNA COVID">CNA - COVID</option>
                  <option value="CNA - Training / Orientation">CNA - Training / Orientation</option>
                  <option value="Med Tech">MEDTECH</option>
                  <option value="MedTech SAME DAY REQUEST">MEDTECH - Same Day Request</option>
                  <option value="Shift Cancel < 2 Hours - Employee on site (MEDTECH)">Shift Cancel < 2 Hours - Employee on site (MEDTECH)</option>
                  <option value="MedTech COVID">MEDTECH - COVID</option>
                  <option value="MedTech - Training / Orientation">MEDTECH - Training / Orientation</option>
                  <option value="LPN">LPN</option>
                  <option value="LPN SAME DAY REQUEST">LPN - SAME DAY REQUEST</option>
                  <option value="LNP - Training / Orientation">LNP - Training / Orientation</option>
                  <option value="RN">RN</option>
                  <option value="RN - Training / Orientation">RN - Training / Orientation</option>
                  <option value="Home Care Services">Home Care Services</option>
                  <option value="VA Home Care Services">VA Home Care Services</option>
                  <option value="HomeCare -  Training / Orientation">Home Care -  Training / Orientation</option>
                  <option value="Live In">Live In</option>
                  <option value="Live In - Training / Orientation">Live In - Training / Orientation</option>
                  <option value="InSuite Service">InSuite Service</option>
                </select>
                <div class="invalid-feedback"
                  *ngIf="submitted2 && quickBookPriceConfigurations.controls[i].get('type')?.errors?.required">
                  Type is required
                </div>
              </div>
              <div class="form-group col-md-2">
                <!-- (change)="FindDuplicate($event.target.value)" -->
                <select  formControlName="subType" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted2 && quickBookPriceConfigurations.controls[i].get('subType')?.errors }">
                  <option value="" selected>Select Sub Type</option>
                  <option value="WeekDay">Week Day</option>
                  <option value="Weekend">Weekend</option>
                  <option value="Cancel">Cancel</option>
                  <option value="HomeCare">Home Care</option>
                  <option value="Live In">Live In</option>
                  <option value="InSuite">InSuite</option>
                </select>
                <div class="invalid-feedback"
                  *ngIf="submitted2 && quickBookPriceConfigurations.controls[i].get('subType')?.errors?.required">
                  Sub Type is required
                </div>
              </div>
              <div class="form-group col-md-2">
                <input type="number" formControlName="price" class="form-control" placeholder="Enter Rate"
                  [ngClass]="{ 'is-invalid': submitted2 && quickBookPriceConfigurations.controls[i].get('price')?.errors }" />
                <div *ngIf="submitted2 && quickBookPriceConfigurations.controls[i].get('price')?.errors"
                  class="invalid-feedback">
                  <div *ngIf="submitted2 && quickBookPriceConfigurations.controls[i].get('price')?.errors?.required">
                    Rate is required</div>
                </div>
              </div>
              <div class="form-group col-md-1">
                <i class="fa fa-trash text-danger" (click)="removeItemUpdate(i)"
                  *ngIf="quickBookPriceConfigurations.controls.length > 1"></i>
              </div>
              <div class="form-group col-md-1">
                <i *ngIf="quickBookPriceConfigurations.controls.length === i +1 " type="button"
                  (click)="addItemUpdate()" class="fa fa-plus text-success"></i>
              </div>
            </div>
          </div>
          <div class="form-row row">
            <div class="form-group col-md-11">
              <div class="text-right">
                <button type="submit" class="btn btn-custom">Update</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
