import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ReviewexcelsheetComponent } from './reviewexcelsheet/reviewexcelsheet.component';
import { LoginComponent } from './user/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { CreateuserComponent } from './user/createuser/createuser.component';
import { UserlistComponent } from './user/userlist/userlist.component';
import { UserType } from './enum/usertype.enum';
import { HolidayconfigurationComponent } from './setting/holidayconfiguration/holidayconfiguration.component';
import { SettingComponent } from './setting/setting/setting.component';
import { ShiftrequestconfigurationComponent } from './setting/shiftrequestconfiguration/shiftrequestconfiguration.component';
import { EmployeetimesheetComponent } from './reports/employeetimesheet/employeetimesheet.component';
import { FacilitylinkgeneratorComponent } from './setting/facilitylinkgenerator/facilitylinkgenerator.component';
import { PayrollemployeetimesheetComponent } from './reports/payrollemployeetimesheet/payrollemployeetimesheet.component';
import { EmployeefileconfigurationComponent } from './setting/employeefileconfiguration/employeefileconfiguration.component';
import { GettokenComponent } from './gettoken/gettoken.component';
import { ForgotpasswordComponent } from './user/forgotpassword/forgotpassword.component';
import { QuickbookconfigurationComponent } from './setting/quickbookconfiguration/quickbookconfiguration.component';
import { EventconfigurationComponent } from './setting/eventconfiguration/eventconfiguration.component';
import { ChecklistconfigurationComponent } from './setting/checklistconfiguration/checklistconfiguration.component';
import { ChecklistComponent } from './reports/checklist/checklist.component';
import { JobconfigurationComponent } from './setting/jobconfiguration/jobconfiguration.component';
import { JoblocationconfigurationComponent } from './setting/joblocationconfiguration/joblocationconfiguration.component';
import { JobapplicantComponent } from './jobapplicant/jobapplicant.component';
import { ServiceconfigurationComponent } from './setting/serviceconfiguration/serviceconfiguration.component';
import { ClientbillingconfigurationComponent } from './setting/clientbillingconfiguration/clientbillingconfiguration.component';
import { ChangerequestComponent } from './changerequest/changerequest.component';
import { VaemployeetimesheetComponent } from './reports/vaemployeetimesheet/vaemployeetimesheet.component';
import { PmclientemployeeestComponent } from './reports/pmclientemployeeest/pmclientemployeeest.component';
import { InitialnursingevaluationComponent } from './reports/initialnursingevaluation/initialnursingevaluation.component';
import { MedicationformComponent } from './nurse/medicationform/medicationform.component';
import { ChecklistformComponent } from './nurse/checklistform/checklistform.component';
import { AideevaluationComponent } from './nurse/aideevaluation/aideevaluation.component';


const routes: Routes = [
  {
    path: '', component: HomeComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin, UserType.Admin, UserType.User] }
  },
  { path: 'review', component: ReviewexcelsheetComponent, canActivate: [AuthGuard] },
  {
    path: 'create-new-user', component: CreateuserComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'users-list', component: UserlistComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'holiday-configuration', component: HolidayconfigurationComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'settings', component: SettingComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'facility-link-generator', component: FacilitylinkgeneratorComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'shift-request-configuration', component: ShiftrequestconfigurationComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'employee-file-configuration', component: EmployeefileconfigurationComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'quickbook-configuration', component: QuickbookconfigurationComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'job-configuration', component: JobconfigurationComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'job-location-configuration', component: JoblocationconfigurationComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'event-configuration', component: EventconfigurationComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'checklist-configuration', component: ChecklistconfigurationComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'service-configuration', component: ServiceconfigurationComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'client-billing-configuration', component: ClientbillingconfigurationComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin] }
  },
  {
    path: 'employee-timesheet-report', component: EmployeetimesheetComponent, canActivate: [AuthGuard]
  },
  {
    path: 'employee-payroll-report', component: PayrollemployeetimesheetComponent, canActivate: [AuthGuard]
  },
  {
    path: 'va-employee-billing-report', component: VaemployeetimesheetComponent, canActivate: [AuthGuard]
  },
  {
    path: 'pm-client-employee-report', component: PmclientemployeeestComponent, canActivate: [AuthGuard]
  },
  {
    path: 'initial-nursing-evaluation-report', component: InitialnursingevaluationComponent, canActivate: [AuthGuard]
  },
  {
    path: 'employee-checklist-report', component: ChecklistComponent, canActivate: [AuthGuard]
  },
  {
    path: 'travel-applicants', component: JobapplicantComponent, canActivate: [AuthGuard]
  },
  {
    path: 'change-request', component: ChangerequestComponent, canActivate: [AuthGuard]
  },
  {
    path: 'medication-form-request', component: MedicationformComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin, UserType.Nurse] }
  },
  {
    path: 'checklist-form-request', component: ChecklistformComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin, UserType.Nurse] }
  },
  {
    path: 'aide-evaluation-request', component: AideevaluationComponent, canActivate: [AuthGuard],
    data: { roles: [UserType.SuperAdmin, UserType.Nurse] }
  },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotpasswordComponent },
  { path: 'get-token', component: GettokenComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
