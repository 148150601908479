<style>
  label span {
    color: red;
  }

  p span {
    color: red;
  }

  .section {
    margin: 20px 0px 20px 0px;
    font-weight: bold;
  }

  .form-check-inline .form-check-input {
    margin-left: 7px !important;
  }
</style>
<div style="opacity: 0.9;
background-color: white;
 margin: 1% 2%;
padding: 3%;
box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
">
  <div class="row target">
    <div class="col-12 center" style="margin-bottom: 2%">
      <h4 class="section">IN HOME CLIENT - CHECKLIST FORM</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-row row">
          <div class="form-group col-md-3 col-sm-6">
            <label>Client Name<span>*</span></label>
            <select (change)="GetNewClientDetailById($event.target.value)" formControlName="homeClientProfileId" class="form-control"
              type="text" [ngClass]="{ 'is-invalid': submitted && f.homeClientProfileId.errors }">
              <option value="" selected disabled>Please Select</option>
              <option *ngFor="let item of evaluatedClients" value="{{item.id}}">{{item.name}}</option>
            </select>
            <div *ngIf="submitted && f.homeClientProfileId.errors" class="invalid-feedback">
              <div *ngIf="f.homeClientProfileId.errors.required">required</div>
            </div>
          </div>
          <div *ngIf="evaluatedClient" class="form-group col-md-2 col-sm-6">
            <label>Date of Birth</label>
            <input disabled class="form-control" value="{{evaluatedClient.dob  | date : 'MMM dd yyyy' }}" />
          </div>
          <div *ngIf="evaluatedClient" class="form-group col-md-2 col-sm-6">
            <label>Phone</label>
            <p class="form-control" >{{"(" + this.evaluatedClient.phone.substring(0,3) + ") " +
            this.evaluatedClient.phone.substring(3,6) + "-" + this.evaluatedClient.phone.substring(6,10)}} </p>
          </div>
          <div *ngIf="evaluatedClient" class="form-group col-md-5 col-sm-6">
            <label>Address</label>
            <input disabled class="form-control" value="{{evaluatedClient.address}}" />
          </div>
        </div>

        <div class="form-row row">
          <div class="col-12">
            <h6 class="section">Check List Section</h6>
          </div>
          <ng-container *ngFor="let item of checkListCategories">
            <div class="col-4">
              <p class="section"> {{item.category}}</p>
              <div *ngFor="let i of item.names" class="form-row row">
                <div class="form-group col-md-10">
                  {{i.name.split(':')[1]}}
                </div>
                <div class="form-group col-md-1">
                  <input name="checklistCheckbox" type="checkbox" class="form-control" (change)="UpdateCheckListChange(i.id, $event.target.checked)" />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div style="margin-top: 50px;" class="form-row row">
          <div class="form-group col-md-12">
            <label>Comments</label>
            <textarea rows="2" placeholder="Please Enter Comments...." class="form-control"
              formControlName="comments" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.comments.errors }">
            </textarea>
            <div *ngIf="submitted && f.comments.errors" class="invalid-feedback">
              <div *ngIf="f.comments.errors.required">required</div>
              <div *ngIf="f.comments.errors.maxlength">max character {{f.comments.value.length}} / 200</div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label>Care Plan Date Initated <span>*</span></label>
            <input type="date" class="form-control" formControlName="initiatedDate" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.initiatedDate.errors }" />
            <div *ngIf="submitted && f.initiatedDate.errors" class="invalid-feedback">
              <div *ngIf="f.initiatedDate.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label>Agency Administrator/Designee Printed Name <span>*</span></label>
            <input type="text" placeholder="Please Enter...." class="form-control"
              formControlName="agencyAdministratorName" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.agencyAdministratorName.errors }" />
            <div *ngIf="submitted && f.agencyAdministratorName.errors" class="invalid-feedback">
              <div *ngIf="f.agencyAdministratorName.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-12">
            <label>Individuals Participating In DevelopmentOf This Care Plan <span>*</span></label>
            <textarea rows="2" type="text" placeholder="Please Enter...." class="form-control"
              formControlName="individualParticipatingCarePlan" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.individualParticipatingCarePlan.errors }"></textarea>
            <div *ngIf="submitted && f.individualParticipatingCarePlan.errors" class="invalid-feedback">
              <div *ngIf="f.individualParticipatingCarePlan.errors.required">required</div>
            </div>
          </div>
        </div>
        <div class="form-row row">
          <div class="form-group col-md-12">
            <p>Schedule of services to be provided by comfort makers staff <span>*</span></p>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="inlineCheckbox1">Monday</label>
              <input [ngClass]="{ 'is-invalid': submitted && f.weekdays.errors }" formControlName="weekdays"
                class="form-check-input" type="checkbox" id="inlineCheckbox1" value="Monday">
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="inlineCheckbox2">Tuesday</label>
              <input [ngClass]="{ 'is-invalid': submitted && f.weekdays.errors }" formControlName="weekdays"
                class="form-check-input" type="checkbox" id="inlineCheckbox2" value="Tuesday">
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="inlineCheckbox3">Wednesday</label>
              <input [ngClass]="{ 'is-invalid': submitted && f.weekdays.errors }" formControlName="weekdays"
                class="form-check-input" type="checkbox" id="inlineCheckbox3" value="Wednesday">
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="inlineCheckbox3">Thursday</label>
              <input [ngClass]="{ 'is-invalid': submitted && f.weekdays.errors }" formControlName="weekdays"
                class="form-check-input" type="checkbox" id="inlineCheckbox4" value="Thursday">
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="inlineCheckbox3">Friday</label>
              <input [ngClass]="{ 'is-invalid': submitted && f.weekdays.errors }" formControlName="weekdays"
                class="form-check-input" type="checkbox" id="inlineCheckbox5" value="Friday">
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="inlineCheckbox3">Saturday</label>
              <input [ngClass]="{ 'is-invalid': submitted && f.weekdays.errors }" formControlName="weekdays"
                class="form-check-input" type="checkbox" id="inlineCheckbox6" value="Saturday">
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="inlineCheckbox3">Sunday</label>
              <input [ngClass]="{ 'is-invalid': submitted && f.weekdays.errors }" formControlName="weekdays"
                class="form-check-input" type="checkbox" id="inlineCheckbox7" value="Sunday">
            </div>
            <div *ngIf="submitted && f.weekdays.errors" style="display: block;" class="invalid-feedback">
              <div *ngIf="f.weekdays.errors.required">required</div>
            </div>
          </div>
        </div>
        <div class="form-row row">
          <div class="form-group col-md-2">
            <label>Total Hours Per Week <span>*</span></label>
            <input type="number" placeholder="Please Enter...." class="form-control" formControlName="totalHours"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.totalHours.errors }" />
            <div *ngIf="submitted && f.totalHours.errors" class="invalid-feedback">
              <div *ngIf="f.totalHours.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-3">
            <label>Request Change In Care Plan Date <span>*</span></label>
            <input type="date" placeholder="Please Enter...." class="form-control" formControlName="requestChangeDate"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.requestChangeDate.errors }" />
            <div *ngIf="submitted && f.requestChangeDate.errors" class="invalid-feedback">
              <div *ngIf="f.requestChangeDate.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-2">
            <label>Requested By</label>
            <input disabled="disabled" type="text" placeholder="Please Enter...." class="form-control" formControlName="requestedBy"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.requestedBy.errors }" />
            <div *ngIf="submitted && f.requestedBy.errors" class="invalid-feedback">
              <div *ngIf="f.requestedBy.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-5">
            <label>Requested Change In Plan <span>*</span></label>
            <input type="text" placeholder="Please Enter...." class="form-control" formControlName="requestChangeInPlan"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.requestChangeInPlan.errors }" />
            <div *ngIf="submitted && f.requestChangeInPlan.errors" class="invalid-feedback">
              <div *ngIf="f.requestChangeInPlan.errors.required">required</div>
            </div>
          </div>
          <div class="form-group col-md-12">
            <label>Specify If Change Is Temporary Suspension/ Addtion Or Permanent</label>
            <p>Suspension Addtion</p>
            <textarea rows="2" type="number" placeholder="Please Enter...." class="form-control"
              formControlName="suspensionAddtion" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.suspensionAddtion.errors }">
            </textarea>
            <div *ngIf="submitted && f.suspensionAddtion.errors" class="invalid-feedback">
              <div *ngIf="f.suspensionAddtion.errors.required">required</div>
              <div *ngIf="f.suspensionAddtion.errors.maxlength">max character {{f.suspensionAddtion.value.length}} / 400</div>

            </div>
          </div>
        </div>
        <div class="row mt15">
          <div class="col-md-4 offset-md-4 col-xs-12">
            <button type="submit" class="btn btn-block btn-custom">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
