<style>
  h6,
  h5 {
    font-weight: bold;
  }
</style>

<div class="row noprint">
  <div class="col-md-12 ">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <div class="row">
          <div class="col">
            <h5 style="font-weight: normal;float: left;padding: .75rem 1.25rem">Providence Meadows Client Employee Hour
              Estimate Report</h5>
          </div>
        </div>
        <div class="card-body">
          <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-row row">
              <div class="col-md-4 col-lg-4">
                <label>Report Date Range</label>
                <select formControlName="definedDate" (change)="ChangeDateType(this.registerForm.value.definedDate)"
                  id="definedDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.definedDate.errors }">
                  <option *ngFor="let item of dateList" value="{{item.date}}">
                    {{item.showDate}}
                  </option>

                </select>
                <div class="row mt5" *ngIf="showCustomDateRange">
                  <div class="col-md-6">
                    <input id="datetimepicker1" autocomplete="off" placeholder="Select Start Date" type="text"
                      formControlName="startDate" class="form-control datetimepicker"
                      [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }" />
                  </div>
                  <div class="col-md-6">
                    <input id="datetimepicker2" autocomplete="off" placeholder="Select End Date" type="text"
                      formControlName="endDate" class="form-control datetimepicker"
                      [ngClass]="{ 'is-invalid': submitted && f.endDate.errors }" />
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-lg-2">
                <label>Employees</label>
                <select formControlName="employees" id="employees" class="form-control js-example-basic-single select2"
                  [ngClass]="{ 'is-invalid': submitted && f.employees.errors }">

                  <option value="0" selected>All Employees</option>
                  <option *ngFor="let emp of employeeList"
                    value="{{emp.employee_number}}">
                    {{emp.employee_first_name}} {{emp.employee_last_name}} - {{emp.employee_number}}
                  </option>

                </select>
                <div *ngIf="submitted && f.employees.errors" class="invalid-feedback">
                  <div *ngIf="f.employees.errors.required">Employee is required</div>
                </div>
              </div>
              <div class="col-md-3 col-lg-3">
                <label>Providence Meadows Client</label>
                <select formControlName="sites" id="sites" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.sites.errors }">
                  <option value="138" [selected]="true" disabled>Providence Meadows - 138</option>
                  <!-- <ng-container *ngFor="let job of jobList">
                    <option *ngIf="job.job_number == 138" value="{{job.job_number}}">
                      {{job.job_name}} - {{job.job_number}}
                    </option>
                  </ng-container> -->
                </select>
                <div *ngIf="submitted && f.sites.errors" class="invalid-feedback">
                  <div *ngIf="f.sites.errors.required">Site is required</div>
                </div>
              </div>
              <div class="col-md-1 col-lg-1">
                <button type="submit" class="btn btn-custom" style="position: absolute;top: 30px;">Run Report</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- difference Daily Data -->
<div class="row">
  <div class="col-md-12">
    <div *ngIf="pmClientEmployeeHours" class="card m-3 beautifyBox">
      <div style="background-color: white">
        <div class="row" style="padding: 17px;">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 text-center">
                <h6>PM Billable Hours VS Time Sheet <span class="vs-color">(Schedule)</span> Daily Report</h6>
              </div>
            </div>
            <div class="row mt15">
              <div class="col-md-12">
                <ng-container *ngIf="pmClientEmployeeHours.dailyDifference">
                  <table class="table">
                    <thead class="custom-table-header-color">
                      <th></th>
                      <th>Shift</th>
                      <th>Sun</th>
                      <th>Mon</th>
                      <th>Tue</th>
                      <th>Wed</th>
                      <th>Thu</th>
                      <th>Fri</th>
                      <th>Sat</th>
                      <th>Total</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Providence Meadows Billable</td>
                        <td style="vertical-align:middle;font-weight: bold" [attr.rowspan]="2">AM + Noon</td>
                        <td>{{pmClientEmployeeHours.ourDaily.dailyTotal.totalAM.sunday}}</td>
                        <td>{{pmClientEmployeeHours.ourDaily.dailyTotal.totalAM.monday}}</td>
                        <td>{{pmClientEmployeeHours.ourDaily.dailyTotal.totalAM.tuesday}}</td>
                        <td>{{pmClientEmployeeHours.ourDaily.dailyTotal.totalAM.wednesday}}</td>
                        <td>{{pmClientEmployeeHours.ourDaily.dailyTotal.totalAM.thursday}}</td>
                        <td>{{pmClientEmployeeHours.ourDaily.dailyTotal.totalAM.friday}}</td>
                        <td>{{pmClientEmployeeHours.ourDaily.dailyTotal.totalAM.saturday }}</td>
                        <td>{{pmClientEmployeeHours.ourDaily.dailyTotal.totalAM.total }}</td>
                      </tr>

                      <tr>
                        <td style="border: none;">Time Sheet <span class="vs-color">(Schedule)</span></td>
                        <!-- <td>AM</td> -->
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalAM.sunday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalAM.monday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalAM.tuesday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalAM.wednesday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalAM.thursday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalAM.friday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalAM.saturday }}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalAM.total }}</td>
                      </tr>

                      <tr style="font-weight: bold;border-top: 2px solid;">
                        <td>Variance</td>
                        <td></td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalAM.sunday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalAM.sunday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalAM.monday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalAM.monday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalAM.tuesday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalAM.tuesday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalAM.wednesday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalAM.wednesday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalAM.thursday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalAM.thursday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalAM.friday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalAM.friday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalAM.saturday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalAM.saturday }}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalAM.total > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalAM.total }}</td>
                      </tr>

                      <!-- PM Difference -->
                      <tr>
                        <td class="padding-top70">Providence Meadows Billable</td>
                        <td class="padding-top70" style="vertical-align:middle;font-weight: bold;" [attr.rowspan]="2">PM</td>
                        <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalPM.sunday}}</td>
                        <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalPM.monday}}</td>
                        <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalPM.tuesday}}</td>
                        <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalPM.wednesday}}</td>
                        <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalPM.thursday}}</td>
                        <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalPM.friday}}</td>
                        <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalPM.saturday }}</td>
                        <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalPM.total }}</td>
                      </tr>

                      <tr>
                        <td style="border: none;">Time Sheet <span class="vs-color">(Schedule)</span></td>
                        <!-- <td>AM</td> -->
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalPM.sunday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalPM.monday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalPM.tuesday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalPM.wednesday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalPM.thursday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalPM.friday}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalPM.saturday }}</td>
                        <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalPM.total }}</td>
                      </tr>

                      <tr style="font-weight: bold;border-top: 2px solid;">
                        <td>Variance</td>
                        <td></td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalPM.sunday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalPM.sunday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalPM.monday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalPM.monday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalPM.tuesday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalPM.tuesday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalPM.wednesday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalPM.wednesday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalPM.thursday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalPM.thursday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalPM.friday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalPM.friday}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalPM.saturday > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalPM.saturday }}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.dailyDifference.totalPM.total > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.dailyDifference.totalPM.total }}</td>
                      </tr>

                        <!-- Misc Difference -->
                        <tr>
                          <td class="padding-top70">Providence Meadows Billable</td>
                          <td class="padding-top70" style="vertical-align:middle;font-weight: bold;" [attr.rowspan]="2">Misc</td>
                          <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalMisc.sunday}}</td>
                          <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalMisc.monday}}</td>
                          <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalMisc.tuesday}}</td>
                          <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalMisc.wednesday}}</td>
                          <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalMisc.thursday}}</td>
                          <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalMisc.friday}}</td>
                          <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalMisc.saturday }}</td>
                          <td class="padding-top70">{{pmClientEmployeeHours.ourDaily.dailyTotal.totalMisc.total }}</td>
                        </tr>

                        <tr>
                          <td style="border: none;">Time Sheet <span class="vs-color">(Schedule)</span></td>
                          <!-- <td>AM</td> -->
                          <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalMisc.sunday}}</td>
                          <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalMisc.monday}}</td>
                          <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalMisc.tuesday}}</td>
                          <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalMisc.wednesday}}</td>
                          <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalMisc.thursday}}</td>
                          <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalMisc.friday}}</td>
                          <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalMisc.saturday }}</td>
                          <td>{{pmClientEmployeeHours.timeSheetDailySchedule.dailyTotal.totalMisc.total }}</td>
                        </tr>

                        <tr style="font-weight: bold;border-top: 2px solid;">
                          <td>Variance</td>
                          <td></td>
                          <td
                            [ngClass]="pmClientEmployeeHours.dailyDifference.totalMisc.sunday > 0 ? 'text-green':'text-danger'">
                            {{pmClientEmployeeHours.dailyDifference.totalMisc.sunday}}</td>
                          <td
                            [ngClass]="pmClientEmployeeHours.dailyDifference.totalMisc.monday > 0 ? 'text-green':'text-danger'">
                            {{pmClientEmployeeHours.dailyDifference.totalMisc.monday}}</td>
                          <td
                            [ngClass]="pmClientEmployeeHours.dailyDifference.totalMisc.tuesday > 0 ? 'text-green':'text-danger'">
                            {{pmClientEmployeeHours.dailyDifference.totalMisc.tuesday}}</td>
                          <td
                            [ngClass]="pmClientEmployeeHours.dailyDifference.totalMisc.wednesday > 0 ? 'text-green':'text-danger'">
                            {{pmClientEmployeeHours.dailyDifference.totalMisc.wednesday}}</td>
                          <td
                            [ngClass]="pmClientEmployeeHours.dailyDifference.totalMisc.thursday > 0 ? 'text-green':'text-danger'">
                            {{pmClientEmployeeHours.dailyDifference.totalMisc.thursday}}</td>
                          <td
                            [ngClass]="pmClientEmployeeHours.dailyDifference.totalMisc.friday > 0 ? 'text-green':'text-danger'">
                            {{pmClientEmployeeHours.dailyDifference.totalMisc.friday}}</td>
                          <td
                            [ngClass]="pmClientEmployeeHours.dailyDifference.totalMisc.saturday > 0 ? 'text-green':'text-danger'">
                            {{pmClientEmployeeHours.dailyDifference.totalMisc.saturday }}</td>
                          <td
                            [ngClass]="pmClientEmployeeHours.dailyDifference.totalMisc.total > 0 ? 'text-green':'text-danger'">
                            {{pmClientEmployeeHours.dailyDifference.totalMisc.total }}</td>
                        </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- difference Data -->
<div class="row">
  <div class="col-md-12">
    <div *ngIf="pmClientEmployeeHours" class="card m-3 beautifyBox">
      <div style="background-color: white">
        <div class="row" style="padding: 17px;">
          <div class="col-md-6">
            <ng-container *ngIf="pmClientEmployeeHours && pmClientEmployeeHours.difference">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h6>PM Billable Hours VS Time Sheet <span class="vs-color">(Schedule)</span></h6>
                </div>
              </div>
              <div class="row mt15">
                <div class="col-md-12">
                  <table class="table">
                    <thead>
                      <tr class="custom-table-header-color">
                        <td>Type</td>
                        <td>AM Total</td>
                        <!-- <td>Noon Total</td> -->
                        <td>PM Total</td>
                        <td>Misc Total</td>
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>PM Hours</td>
                        <td>{{pmClientEmployeeHours.our.totalAM + pmClientEmployeeHours.our.totalNoon}}</td>
                        <!-- <td>{{pmClientEmployeeHours.our.totalNoon}}</td> -->
                        <td>{{pmClientEmployeeHours.our.totalPM}}</td>
                        <td>{{pmClientEmployeeHours.our.totalMisc}}</td>
                        <td>{{pmClientEmployeeHours.our.total}}</td>
                      </tr>
                      <tr>
                        <td>TimeSheet <span class="vs-color">(Schedule)</span>
                        </td>
                        <td>{{pmClientEmployeeHours.timeSheetSchedule.totalAM}}</td>
                        <!-- <td>{{pmClientEmployeeHours.timeSheetSchedule.totalNoon}}</td> -->
                        <td>{{pmClientEmployeeHours.timeSheetSchedule.totalPM}}</td>
                        <td>0</td>
                        <td>{{pmClientEmployeeHours.timeSheetSchedule.total}}</td>
                      </tr>
                      <tr style="font-weight: bold;border-top: 2px solid;">
                        <td></td>
                        <td [ngClass]="pmClientEmployeeHours.difference.totalAM > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.difference.totalAM + pmClientEmployeeHours.difference.totalNoon}}</td>
                        <!-- <td [ngClass]="pmClientEmployeeHours.difference.totalNoon > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.difference.totalNoon}}</td> -->
                        <td [ngClass]="pmClientEmployeeHours.difference.totalPM > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.difference.totalPM}}</td>
                        <td [ngClass]="pmClientEmployeeHours.difference.totalMisc > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.difference.totalMisc}}</td>
                        <td [ngClass]="pmClientEmployeeHours.difference.total > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.difference.total}}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-md-6">
            <!-- TimeSheet Difference Data -->
            <ng-container *ngIf="pmClientEmployeeHours && pmClientEmployeeHours.timeSheetDifference">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h6>Time Sheet Hours <span class="vs-color">(Schedule VS Acutal)</span></h6>
                </div>
              </div>
              <div class="row mt15">
                <div class="col-md-12">
                  <table class="table">
                    <tbody>
                      <tr class="custom-table-header-color">
                        <td>TimeSheet Hours</td>
                        <td>AM Total</td>
                        <!-- <td>Noon Total</td> -->
                        <td>PM Total</td>
                        <td>Total</td>
                      </tr>
                      <tr>
                        <td>Actual</td>
                        <td>{{pmClientEmployeeHours.timeSheetActual.totalAM}}</td>
                        <!-- <td>{{pmClientEmployeeHours.timeSheetActual.totalNoon}}</td> -->
                        <td>{{pmClientEmployeeHours.timeSheetActual.totalPM}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetActual.total}}</td>
                      </tr>
                      <tr>
                        <td>Schedule</td>
                        <td>{{pmClientEmployeeHours.timeSheetSchedule.totalAM}}</td>
                        <!-- <td>{{pmClientEmployeeHours.timeSheetSchedule.totalNoon}}</td> -->
                        <td>{{pmClientEmployeeHours.timeSheetSchedule.totalPM}}</td>
                        <td>{{pmClientEmployeeHours.timeSheetSchedule.total}}</td>
                      </tr>
                      <tr style="font-weight: bold;border-top: 2px solid;">
                        <td></td>
                        <td
                          [ngClass]="pmClientEmployeeHours.timeSheetDifference.totalAM > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.timeSheetDifference.totalAM}}</td>
                        <!-- <td
                          [ngClass]="pmClientEmployeeHours.timeSheetDifference.totalNoon > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.timeSheetDifference.totalNoon}}</td> -->
                        <td
                          [ngClass]="pmClientEmployeeHours.timeSheetDifference.totalPM > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.timeSheetDifference.totalPM}}</td>
                        <td
                          [ngClass]="pmClientEmployeeHours.timeSheetDifference.total > 0 ? 'text-green':'text-danger'">
                          {{pmClientEmployeeHours.timeSheetDifference.total}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- timesheet Schedule hour Data -->
<div class="row">
  <div class="col-md-12">
    <div *ngIf="pmClientEmployeeHours" class="card m-3 beautifyBox">
      <div style="background-color: white">
        <div class="row" style="margin-top: 15px;padding: 17px;">
          <div class="col-md-12 text-center">
            <h5 *ngIf="pmClientEmployeeHours && pmClientEmployeeHours.timeSheetSchedule">
              Time Sheet {{pmClientEmployeeHours.timeSheetSchedule.clientName}} <span class="vs-color">(Schedule vs
                Actual)</span></h5>
          </div>
        </div>
        <div class="row" style="padding: 17px;">
          <div class="col-md-6">
            <ng-container *ngIf="pmClientEmployeeHours && pmClientEmployeeHours.timeSheetSchedule">
              <div class="row">
                <div class="col-md-12">
                  <div class="row" style="margin-top: 15px">
                    <div class="col-md-6">
                      <h6 class="bold">Schedule</h6>
                    </div>
                    <!-- <div class="col-md-4">
                      <h5>{{pmClientEmployeeHours.timeSheetSchedule.clientName}}</h5>
                    </div> -->
                    <div class="col-md-6 text-right">
                      <ng-container *ngIf="pmClientEmployeeHours.timeSheetSchedule.subTotal.length > 0">
                        <h6>Total Employees: {{pmClientEmployeeHours.timeSheetSchedule.subTotal.length}}</h6>
                      </ng-container>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table table-striped">
                        <thead class="custom-table-header-color">
                          <tr>
                            <th>Employee Name</th>
                            <th>AM Shift</th>
                            <!-- <th>Noon Shift</th> -->
                            <th>PM Shift</th>
                            <th>Total Hours</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of pmClientEmployeeHours.timeSheetSchedule.subTotal">
                            <td>{{item.employeeName}}</td>
                            <td>{{item.subTotalAM}}</td>
                            <!-- <td>{{item.subTotalNoon}}</td> -->
                            <td>{{item.subTotalPM}}</td>
                            <td style="border-left: 2px solid;">{{item.subTotal}}</td>
                          </tr>
                          <tr *ngIf="pmClientEmployeeHours.timeSheetSchedule.subTotal.length > 0"
                            style="font-weight: bold;border-top: 2px solid;">
                            <td></td>
                            <td>{{pmClientEmployeeHours.timeSheetSchedule.totalAM}}</td>
                            <!-- <td>{{pmClientEmployeeHours.timeSheetSchedule.totalNoon}}</td> -->
                            <td>{{pmClientEmployeeHours.timeSheetSchedule.totalPM}}</td>
                            <td>{{pmClientEmployeeHours.timeSheetSchedule.total}}</td>
                          </tr>
                          <tr *ngIf="pmClientEmployeeHours.timeSheetSchedule.subTotal.length == 0">
                            <td colspan="5" class="text-center">No Record Found</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-md-6">
            <!-- timesheet Actual hour Data -->
            <ng-container *ngIf="pmClientEmployeeHours && pmClientEmployeeHours.timeSheetActual">
              <div class="row">
                <div class="col-md-12">
                  <div class="row" style="margin-top: 15px">
                    <div class="col-md-6">
                      <h6 class="bold">Actual</h6>
                    </div>
                    <!-- <div class="col-md-4">
                      <h5>{{pmClientEmployeeHours.timeSheetActual.clientName}}</h5>
                    </div> -->
                    <div class="col-md-6 text-right">
                      <ng-container *ngIf="pmClientEmployeeHours.timeSheetActual.subTotal.length > 0">
                        <h6>Total Employees: {{pmClientEmployeeHours.timeSheetActual.subTotal.length}}</h6>
                      </ng-container>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table table-striped">
                        <thead class="custom-table-header-color">
                          <tr>
                            <th>Employee Name</th>
                            <th>AM Shift</th>
                            <!-- <th>Noon Shift</th> -->
                            <th>PM Shift</th>
                            <th>Total Hours</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of pmClientEmployeeHours.timeSheetActual.subTotal">
                            <td>{{item.employeeName}}</td>
                            <td>{{item.subTotalAM}}</td>
                            <!-- <td>{{item.subTotalNoon}}</td> -->
                            <td>{{item.subTotalPM}}</td>
                            <td style="border-left: 2px solid;">{{item.subTotal}}</td>
                          </tr>
                          <tr *ngIf="pmClientEmployeeHours.timeSheetActual.subTotal.length > 0"
                            style="font-weight: bold;border-top: 2px solid;">
                            <td></td>
                            <td>{{pmClientEmployeeHours.timeSheetActual.totalAM}}</td>
                            <!-- <td>{{pmClientEmployeeHours.timeSheetActual.totalNoon}}</td> -->
                            <td>{{pmClientEmployeeHours.timeSheetActual.totalPM}}</td>
                            <td>{{pmClientEmployeeHours.timeSheetActual.total}}</td>
                          </tr>
                          <tr *ngIf="pmClientEmployeeHours.timeSheetActual.subTotal.length == 0">
                            <td colspan="5" class="text-center">No Record Found</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- our hour data -->
<ng-container *ngIf="pmClientEmployeeHours && pmClientEmployeeHours.our">
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="pmClientEmployeeHours" class="card m-3 beautifyBox">
        <div style="background-color: white">
          <div class="row" style="padding-top: 17px;">
            <div class="col-md-12 text-center">
              <h5>Providence Meadows Billable Hours Weekly Report</h5>
            </div>
          </div>
          <div class="row" style="padding: 17px;">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 text-right">
                  <h6>Total Clients: {{pmClientEmployeeHours.our.subTotal.length}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-striped">
                    <thead class="custom-table-header-color">
                      <tr>
                        <th>Client Name</th>
                        <th>AM Hours</th>
                        <th>Noon Hours</th>
                        <th>PM Hours</th>
                        <th>Misc Hours</th>
                        <th>Total Hours</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of pmClientEmployeeHours.our.subTotal">
                        <td>{{item.client}}</td>
                        <td>{{item.totalAM}}</td>
                        <td>{{item.totalNoon}}</td>
                        <td>{{item.totalPM}}</td>
                        <td>{{item.totalMisc}}</td>
                        <td style="border-left: 2px solid;">{{item.total}}</td>
                      </tr>
                      <tr style="font-weight: bold;border-top: 2px solid;">
                        <td></td>
                        <td>{{pmClientEmployeeHours.our.totalAM}}</td>
                        <td>{{pmClientEmployeeHours.our.totalNoon}}</td>
                        <td>{{pmClientEmployeeHours.our.totalPM}}</td>
                        <td>{{pmClientEmployeeHours.our.totalMisc}}</td>
                        <td>{{pmClientEmployeeHours.our.total}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>


<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>
