import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../shared/services/data.service';
import { RepositoryService } from '../shared/services/repository.service';
import * as FileSaver from 'file-saver';
import { Excel } from '../model/excel.model';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { SpinnerService } from '../shared/services/spinner.service';


@Component({
  selector: 'app-reviewexcelsheet',
  templateUrl: './reviewexcelsheet.component.html',
  styleUrls: ['./reviewexcelsheet.component.css']
})
export class ReviewexcelsheetComponent implements OnInit {
  showOpacity = false;
  displayStyle = 'none';
  BreakException = {};
  updatedList: Excel[] = [];
  excelData: Excel[] = [];
  constructor(private auth: AuthService, public data: DataService, private toastr: ToastrService, private spinner: SpinnerService,
    private repo: RepositoryService) { }

  ngAfterViewInit(): void { }

  ngOnInit(): void {
    this.GetExcelData();
  }

  public GetExcelData(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/SpreadSheet/GetAll', this.auth.GetUserId())
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.excelData = data['data'];
          }
          this.spinner.hideSpinner();

        },
        error => {
          this.spinner.hideSpinner();
          // this.toastr.error('Please contact to admin', 'Error');
        });
  }
  public DownloadExcel(): void {
    this.spinner.showSpinner();
    if (this.excelData.length === 0) {
      this.showError('List is Empty');
      return;
    }
    // this.updatedList = JSON.parse(JSON.stringify(this.data.excelData));
    // this.updatedList.forEach(element => {
    //   element.startDate = this.ConvertSpecificDate(element.startDate.split(" ")[0]) + " " + this.convertTime12to24(element.startDate.split(" ")[1] + " " + element.startDate.split(" ")[2])
    //   element.endDate = this.ConvertSpecificDate(element.endDate.split(" ")[0]) + " " + this.convertTime12to24(element.endDate.split(" ")[1] + " " + element.endDate.split(" ")[2])
    // });
    this.repo.downloadFile('api/SpreadSheet/ScheduleExcel', this.auth.GetUserId())
      .subscribe(res => {
        this.spinner.hideSpinner();
        this.downloadExcel(res);
      });
  }

  downloadExcel(buffer: any) {
    const data: Blob = this.convertBase64StringToBlob(buffer.data.file, 'text/csv');
    // const data: Blob = new Blob([buffer.data.file],
    // { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(data, buffer.data.fileName);
  }
  showError(val): void {
    this.toastr.error(val);
  }
  public ClearExcelData(): void {
    this.spinner.showSpinner();
    this.repo.delete('api/SpreadSheet/DeleteAll', this.auth.GetUserId())
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.ClosePopup();
            this.showSuccess('Deleted Successfully');
            this.excelData = [];
          }

          else {
            this.toastr.error(data['ReturnMessage'], 'Error');
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.toastr.error('Please contact to admin', 'Error');
          this.spinner.hideSpinner();
        });
    if (localStorage.getItem('excelData') != null) {
      localStorage.removeItem('excelData');
    }
    this.data.excelData = [];
  }
  UpdateList(newValue: string, body: Excel, index: number, fieldName: string): void {
    if (this.getPropertyName(function () { body.empName }) === fieldName) {
      body.empName = newValue;
    }
    if (this.getPropertyName(function () { body.clientName }) === fieldName) {
      body.clientName = newValue;
    }
    if (this.getPropertyName(function () { body.serviceName }) === fieldName) {
      body.serviceName = newValue;
    }
    if (this.getPropertyName(function () { body.startDate }) === fieldName) {
      body.startDate = newValue;
    }
    if (this.getPropertyName(function () { body.endDate }) === fieldName) {
      body.endDate = newValue;
    }
    if (this.getPropertyName(function () { body.eventInfo }) === fieldName) {
      body.eventInfo = newValue;
    }
    this.repo.update('api/SpreadSheet/update', body.id, body)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.showSuccess(data['returnMessage'][0]);
            this.excelData.forEach((ele, i) => {
              if (ele.id === body.id) {
                this.excelData[i] = body;
                throw this.BreakException;
              }
            });
          }
          else {
            this.toastr.error(data['returnMessage'][0], 'Error');
          }
        },
        error => {
          this.toastr.error('Please contact to admin', 'Error');
        });

  }
  // UpdateList(value: string, index: number, fieldName: string): void {
  //   if (this.data.excelData.length > 0) {
  //     this.data.excelData.forEach((ele, i) => {
  //       if (i === index) {
  //         if (this.getPropertyName(function () { ele.Employee_Name }) === fieldName) {
  //           ele.Employee_Name = value;
  //           this.showSuccess('List is Updated');
  //         }
  //         else if (this.getPropertyName(function () { ele.Employee_Number }) === fieldName) {
  //           ele.Employee_Number = value;
  //           this.showSuccess('List is Updated');
  //         }
  //         else if (this.getPropertyName(function () { ele.Task_Name }) === fieldName) {
  //           ele.Task_Name = value;
  //           this.showSuccess('List is Updated');
  //         }
  //         else if (this.getPropertyName(function () { ele.Task_Number }) === fieldName) {
  //           ele.Task_Number = value;
  //           this.showSuccess('List is Updated');
  //         }
  //         else if (this.getPropertyName(function () { ele.Job_Name }) === fieldName) {
  //           ele.Job_Name = value;
  //           this.showSuccess('List is Updated');
  //         }
  //         else if (this.getPropertyName(function () { ele.Job_Number }) === fieldName) {
  //           ele.Job_Number = value;
  //           this.showSuccess('List is Updated');
  //         }
  //         else if (this.getPropertyName(function () { ele.StartDate }) === fieldName) {
  //           ele.StartDate = value;
  //           this.showSuccess('List is Updated');
  //         }
  //         else if (this.getPropertyName(function () { ele.EndDate }) === fieldName) {
  //           ele.EndDate = value;
  //           this.showSuccess('List is Updated');
  //         }
  //         else if (this.getPropertyName(function () { ele.event_info }) === fieldName) {
  //           ele.event_info = value;
  //           this.showSuccess('List is Updated');
  //         }
  //         localStorage.setItem('excelData', JSON.stringify(this.data.excelData));
  //         throw this.BreakException;
  //       }
  //     });
  //   }
  // }
  DeleteExcelRow(index: number, id: number): void {
    this.spinner.showSpinner();
    this.repo.delete('api/SpreadSheet/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.excelData.forEach((ele, i) => {
              if (i === index) {
                this.excelData.splice(index, 1);
                localStorage.setItem('excelData', JSON.stringify(this.excelData));
                this.showSuccess('Deleted');
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
          else {
            this.spinner.hideSpinner();
            this.toastr.error(data['ReturnMessage'], 'Error');
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error('Please contact to admin', 'Error');
        });
  }

  ClosePopup(): void {
    this.displayStyle = 'none';
    this.showOpacity = false;
  }
  OpenPopup(): void {
    this.displayStyle = 'block';
    this.showOpacity = true;
  }
  // propName(prop, value): string {
  //   for (const i in prop) {
  //     if (typeof prop[i] === 'object') {
  //       if (this.propName(prop[i], value)) {
  //         return this.res;
  //       }
  //     } else {
  //       if (prop[i] === value) {
  //         this.res = i;
  //         return this.res;
  //       }
  //     }
  //   }
  //   return undefined;
  // }
  getPropertyName(propertyFunction): string {
    console.log(propertyFunction);
    return /\.([^\.;]+);?\s*\}$/.exec(propertyFunction.toString())[1];

    // const propName = (obj,type) => Object.keys(obj).find(key => obj[key] === type)
    // propName(body, body.eventInfo) == "eventInfo"
  }

  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
  ConvertSpecificDate(date: string): string {
    const year = date.slice(6, 10);
    const month = date.slice(0, 2);
    const day = date.slice(3, 5);
    // const time = date.split('T')[1];
    return month + '/' + day + '/' + year;
  }
  showSuccess(val): void {
    this.toastr.success(val);
  }

  private convertBase64StringToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }
}
