import { DOCUMENT } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { Job } from '../model/job.model';
import { ServiceConfiguration } from '../model/serviceconfiguration.model';
import { RepositoryService } from '../shared/services/repository.service';
import { SpinnerService } from '../shared/services/spinner.service';

declare let $: any;

@Component({
  selector: 'app-changerequest',
  templateUrl: './changerequest.component.html',
  styleUrls: ['./changerequest.component.css']
})
export class ChangerequestComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  services: FormArray;
  serviceConfigurationList: ServiceConfiguration[] = [];
  public jobList: Job[];
  public room: number;

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private spinner: SpinnerService,
    private repo: RepositoryService, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      requestorName: ['', [Validators.required]],
      requestorDate: ['', [Validators.required]],
      clientName: ['', [Validators.required]],
      clientRoom: ['', [Validators.required]],
      changeDate: ['', [Validators.required]],
      comments: [''],
      services: this.formBuilder.array([])
    });
    this.GetJobs();
    this.GetServiceConfigurations();

    var self = this;
    $('.js-example-basic-single').on("change", function () {
      self.GetRoomNumberByClientName($(this).val());
    });
  }
  ngAfterViewInit(): void {
    this.InitSelect2();
  }
  InitSelect2(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {

            // Initialize select2
            $("select").select2({    dropdownPosition: 'below'});
          });
          (function($) {
            var Defaults = $.fn.select2.amd.require('select2/defaults');
            $.extend(Defaults.defaults, {
              dropdownPosition: 'auto'
            });
            var AttachBody = $.fn.select2.amd.require('select2/dropdown/attachBody');
            var _positionDropdown = AttachBody.prototype._positionDropdown;
            AttachBody.prototype._positionDropdown = function() {
              var $window = $(window);
              var isCurrentlyAbove = this.$dropdown.hasClass('select2-dropdown--above');
              var isCurrentlyBelow = this.$dropdown.hasClass('select2-dropdown--below');
              var newDirection = null;
              var offset = this.$container.offset();
              offset.bottom = offset.top + this.$container.outerHeight(false);
              var container = {
                  height: this.$container.outerHeight(false)
              };
              container.top = offset.top;
              container.bottom = offset.top + container.height;
              var dropdown = {
                height: this.$dropdown.outerHeight(false)
              };
              var viewport = {
                top: $window.scrollTop(),
                bottom: $window.scrollTop() + $window.height()
              };
              var enoughRoomAbove = viewport.top < (offset.top - dropdown.height);
              var enoughRoomBelow = viewport.bottom > (offset.bottom + dropdown.height);
              var css = {
                left: offset.left,
                top: container.bottom
              };
              // Determine what the parent element is to use for calciulating the offset
              var $offsetParent = this.$dropdownParent;
              // For statically positoned elements, we need to get the element
              // that is determining the offset
              if ($offsetParent.css('position') === 'static') {
                $offsetParent = $offsetParent.offsetParent();
              }
              var parentOffset = $offsetParent.offset();
              css.top -= parentOffset.top
              css.left -= parentOffset.left;
              var dropdownPositionOption = this.options.get('dropdownPosition');
              if (dropdownPositionOption === 'above' || dropdownPositionOption === 'below') {
                newDirection = dropdownPositionOption;
              } else {
                if (!isCurrentlyAbove && !isCurrentlyBelow) {
                  newDirection = 'below';
                }
                if (!enoughRoomBelow && enoughRoomAbove && !isCurrentlyAbove) {
                  newDirection = 'above';
                } else if (!enoughRoomAbove && enoughRoomBelow && isCurrentlyAbove) {
                  newDirection = 'below';
                }
              }
              if (newDirection == 'above' ||
              (isCurrentlyAbove && newDirection !== 'below')) {
                  css.top = container.top - parentOffset.top - dropdown.height;
              }
              if (newDirection != null) {
                this.$dropdown
                  .removeClass('select2-dropdown--below select2-dropdown--above')
                  .addClass('select2-dropdown--' + newDirection);
                this.$container
                  .removeClass('select2-container--below select2-container--above')
                  .addClass('select2-container--' + newDirection);
              }
              this.$dropdownContainer.css(css);
            };
          })(window.jQuery);
        }
    `;
    this._renderer2.appendChild(this._document.body, script);
  }
  get f() { return this.registerForm.controls; }
  get d() { return this.services; }

  createItem(ele: ServiceConfiguration): FormGroup {
    return this.formBuilder.group({
      service: [ele.name, [Validators.required]],
      value: [''],
      type: ['']
    });
  }
  addItem(): void {
    this.services = this.registerForm.get('services') as FormArray;
    if (this.serviceConfigurationList) {
      this.serviceConfigurationList.forEach(ele => {
        this.services.push(this.createItem(ele));
      });
    }
  }
  removeItem(index: number): void {
    this.services.removeAt(index);
  }
  GetServiceConfigurations(): void {
    if (this.serviceConfigurationList.length > 0)
      this.serviceConfigurationList = [];
    this.spinner.showSpinner();
    this.repo.getData('api/ServiceConfiguration/GetAll')
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.serviceConfigurationList = res['data'];
            this.addItem();
            this.spinner.hideSpinner();
          }
        },
        error: err => {
          this.spinner.hideSpinner();
        }
      });
  }
  GetRoomNumberByClientName(val: string): void {
    this.spinner.showSpinner();
    this.repo.getData('api/ChangeRequest/GetRoomNumberByClientName', val)
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            if (res['data'] == 0)
              this.registerForm.controls.clientRoom.setValue('');
            else
              this.registerForm.controls.clientRoom.setValue(res['data']);
            // this.room = res['data'];
            this.spinner.hideSpinner();
          }
        },
        error: err => {
          this.spinner.hideSpinner();
        }
      });
  }
  onSubmit(): void {
    this.submitted = true;
    this.registerForm.controls.clientName.setValue((<HTMLInputElement>document.getElementById('clientName')).value);
    if (this.registerForm.invalid) {
      return;
    }
    this.spinner.showSpinner();
    this.repo.create('api/ChangeRequest/Sent', this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.toastr.success(data['returnMessage'][0], 'Success');
            this.onReset();
          }
          else {
            this.toastr.error(data['returnMessage'][0], 'Error');
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  public GetJobs(): void {

    this.spinner.showSpinner();
    this.repo.getData('api/Job/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] === true) {
            this.jobList = res['data'];
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.spinner.hideSpinner();
        }
      });
  }
  onReset() {
    this.submitted = false;
    this.f.clientName.setValue('Select Client');
    this.f.clientName.setValue('');
    this.services.clear();
    this.services = new FormArray([]);
    // this.serviceConfigurationList.forEach((e, i) => {
    //   this.removeItem(i);
    // });
    this.registerForm.reset();
    this.addItem();
  }
}
