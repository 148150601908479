import { DOCUMENT, formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Employee } from 'src/app/model/employee.model';
import { Job } from 'src/app/model/job.model';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { TimeSheetTokenService } from 'src/app/shared/services/timesheettoken.service';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css']
})



export class ChecklistComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  public employeeList: Employee[];
  public jobList: Job[];
  dateList: any;
  html: string = '';
  date;
  currentDate;
  priorDate;
  currentWeek;
  showCustomDateRange: boolean;


  constructor(@Inject(LOCALE_ID) private locale: string,
    private timesheet: TimeSheetTokenService, private formBuilder: FormBuilder, private repo: RepositoryService,
    private toastr: ToastrService, private spinner: SpinnerService,
    // tslint:disable-next-line: variable-name
    private _renderer2: Renderer2,
    // tslint:disable-next-line: variable-name
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {
    this.DateDropDowns();
    this.GetEmployees();
    this.GetJobs();
    this.registerForm = this.formBuilder.group({
      employees: ['0'],
      sites: ['0', [Validators.required]],
      startDate: [''],
      endDate: [''],
      access_Token: [''],
      definedDate: [formatDate(this.currentDate = new Date(), 'MM/dd/yyyy', this.locale)]
    });
  }
  get f() { return this.registerForm.controls; }

  onSubmit(): void {
    this.submitted = true;
    if (this.showCustomDateRange && !this.CheckDateTimeCondition((document.getElementById('datetimepicker1') as HTMLInputElement).value,
      (document.getElementById('datetimepicker2') as HTMLInputElement).value)) {
      return;
    }
    this.registerForm.controls.employees.setValue((document.getElementById('employees') as HTMLInputElement).value);
    if ((document.getElementById('sites') as HTMLInputElement).value === '0') {
      this.registerForm.controls.sites.setValue('');
    }
    else {
      this.registerForm.controls.sites.setValue((document.getElementById('sites') as HTMLInputElement).value);
    }
    if (this.registerForm.invalid) {
      return;
    }
    this.RunReport();

  }
  CheckDateTimeCondition(start: string, end: string): boolean {
    if (start === '') {
      this.toastr.warning('Start Date Required', 'Required');
      return false;
    }
    else {
      this.registerForm.controls.startDate.setValue((document.getElementById('datetimepicker1') as HTMLInputElement).value);
    }
    if (end === '') {
      this.toastr.warning('End Date Required', 'Oops');
      return false;
    }
    else {
      this.registerForm.controls.endDate.setValue((document.getElementById('datetimepicker2') as HTMLInputElement).value);
    }
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (endDate < startDate) {
      this.toastr.warning('End Date Must be greater then Start Date', 'Oops');
      return false;
    }
    else {
      return true;
    }

  }
  ngAfterViewInit(): void {
    // this.RunDateTimePicker();
    this.InitSelect2();
  }

  onReset(): void {
    this.submitted = false;
    this.registerForm.reset();
  }
  public GetEmployees(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/Employee/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.employeeList = res['data'];
          }

          this.spinner.hideSpinner();
        },
        error: err => {
          this.spinner.hideSpinner();
        }
      });
  }
  public GetJobs(): void {
    this.repo.getData('api/Job/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.jobList = res['data'];
          }
        },
        error: err => {
        }
      });
  }
  InitSelect2(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $(".select2").select2();
          });
        }
    `;
    this._renderer2.appendChild(this._document.body, script);
  }
  DateDropDowns(): void {
    this.date = new Date();
    this.currentWeek = new Date(this.date.setDate(this.date.getDate() - this.date.getDay()));
    const currentWeek2 = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 6));
    const priorWeek2 = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() - 1));
    const priorWeek = new Date(this.date.setDate(this.date.getDate() - this.date.getDay()));

    this.dateList = [
      {
        date: formatDate(this.currentDate = new Date(), 'MM/dd/yyyy', this.locale),
        showDate: 'Current day ' + formatDate(this.currentDate = new Date(), '(MMM dd)', this.locale)
      },
      {
        date: formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), 'MM/dd/yyyy', this.locale),
        showDate: 'Prior day ' + formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), '(MMM dd)', this.locale)
      },
      {
        date: formatDate(this.currentWeek, 'MM/dd/yyyy', this.locale) + '-' +
          formatDate(currentWeek2, 'MM/dd/yyyy', this.locale),
        showDate: 'Current week ' + formatDate(this.currentWeek, '(MMM dd', this.locale) + ' - ' +
          formatDate(currentWeek2, 'MMM dd)', this.locale)
      },
      {
        date: formatDate(priorWeek, 'MM/dd/yyyy', this.locale) + '-' +
          formatDate(priorWeek2, 'MM/dd/yyyy', this.locale),
        showDate: 'Prior week ' + formatDate(priorWeek, '(MMM dd', this.locale) + ' - ' +
          formatDate(priorWeek2, 'MMM dd)', this.locale)
      },
      {
        date: 'Custom date range',
        showDate: 'Custom date range'
      }

    ];
  }
  RunDateTimePicker(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('.datetimepicker').datetimepicker({
              format: 'MM/DD/YYYY',
              keepOpen:false,
              stepping: 5,
              //sideBySide:true
             // debug: true
            });
          });
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
  DestroyDateTimePicker(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('.datetimepicker').data("DateTimePicker").destroy();
          });
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
  ChangeDateType(value: string): void {
    if (value === 'Custom date range') {
      this.RunDateTimePicker();
      this.showCustomDateRange = true;
    } else {
      this.DestroyDateTimePicker();
      this.showCustomDateRange = false;
    }
  }

  RunReport(): void {
    this.spinner.showSpinner();
    this.repo.create('api/Report/GetEmployeeChecklistReport', this.registerForm.value)
      .subscribe({
        next: res => {
          if (res['returnStatus'] === true) {
            this.html = res['data'].html;
            let child = window.open("about:blank", "myChild");
            child.document.write(this.html);
            child.document.close();
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.toastr.error(err.error.returnMessage[0]);
          this.spinner.hideSpinner();
        }
      });
  }
}


