<style>
  table thead tr th{
    font-weight: 500 !important;
  }
</style>
<div class="row" [ngClass]="{'opacity4': showOpacity}">
  <div class="col-md-12">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <h5 style="float: left;padding: .75rem 1.25rem">Review Schedule</h5>
        <button *ngIf="excelData.length > 0" (click)="OpenPopup()" style="float: right; margin-top: 5px;" type="button" class="btn btn-outline-danger mr-1">
          <i class="fa fa-trash"></i> Delete  Spreadsheet</button>
        <button *ngIf="excelData.length > 0" (click)="DownloadExcel()" style="    float: right; margin-top: 5px;" type="button" class="btn btn-custom mr-1">
          <i class="fa fa-file-excel-o" aria-hidden="true"></i> Download Completed Schedule</button>
      </div>
        <div class="">
          <table class="table table-striped">
            <thead class="custom-table-header-color">
              <tr>
                <th scope="col">Emp Name</th>
                <!-- <th scope="col">Emp Number</th> -->
                <th scope="col">Client Name</th>
                <!-- <th scope="col">Client Number</th> -->
                <th scope="col">Service Name</th>
                <!-- <th scope="col">Service Number</th> -->
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Event Info</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="excelData.length > 0; else elseBlock">
                <tr *ngFor="let d of excelData; index as i">
                  <td width="18%"> <input type="text" (change)="UpdateList($event.target.value,d,i,'empName')" class="form-control" value="{{ d.empName }}"> </td>
                  <!-- <td style="width: 500px;"> <input type="text" (change)="UpdateList($event.target.value,i,'Employee_Number')" class="form-control" value="{{ d.Employee_Number }}"></td> -->
                  <td width="15%"> <input type="text" (change)="UpdateList($event.target.value,d,i,'clientName')" class="form-control" value="{{ d.clientName }}"></td>
                  <!-- <td style="width: 500px;"> <input type="text" (change)="UpdateList($event.target.value,i,'Job_Number')" class="form-control" value="{{ d.Job_Number }}"></td> -->
                  <td width="16%"> <input type="text" (change)="UpdateList($event.target.value,d,i,'serviceName')" class="form-control" value="{{ d.serviceName }}"></td>
                  <!-- <td style="width: 500px;"> <input type="text" (change)="UpdateList($event.target.value,i,'Task_Number')" class="form-control" value="{{ d.Task_Number }}"></td> -->
                  <td width="16%"> <input type="text" (change)="UpdateList($event.target.value,d,i,'startDate')" class="form-control" value="{{ d.startDate  }}"></td>
                  <!-- | time24to12Transform -->
                  <td width="16%"> <input type="text" (change)="UpdateList($event.target.value,d,i,'endDate')" class="form-control" value="{{ d.endDate }}"></td>
                  <td width="30%"> <input type="text" (change)="UpdateList($event.target.value,d,i,'eventInfo')" class="form-control" value="{{ d.eventInfo }}"></td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-sliders"></i>
                      </button>
                      <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button class="dropdown-item" (click)="DeleteExcelRow(i,d.id)">Delete this record</button>
                      </div>
                    </div>
                    <!-- <button type="button" (click)="DeleteExcelRow(i,d.id)" class="btn btn-danger"><i class="fa fa-trash"></i></button> -->
                  </td>
                </tr>
              </ng-container>
              <ng-template #elseBlock>
                <tr>
                  <td colspan="10"></td>
                </tr>
                <tr>
                  <td colspan="10" class="text-center">No Record Found......</td>
                </tr>
                <tr>
                  <td colspan="10"></td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div [ngStyle]="{'display':displayStyle}" class="modal" tabindex="-1">
    <div class="modal-dialog">
      <!-- modal-dialog-centered -->
      <div class="modal-content beautifyBox">
        <div class="modal-header">
          <h5 class="modal-title text-danger" id="staticBackdropLabel">Delete Spread Sheet</h5>
          <button (click)="ClosePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-8">
              Are you sure to <b>Delete</b>?
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-md-5">
              <button (click)="ClearExcelData()" type="button" class="btn btn-primary">Yes</button>
            </div>
            <div class="col-md-5">
              <button (click)="ClosePopup()" type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
    </ngx-spinner>
