import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gettoken',
  templateUrl: './gettoken.component.html',
  styleUrls: ['./gettoken.component.css']
})
export class GettokenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
