import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { JobStatus } from 'src/app/enum/jobstatus.enum';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { StatusType } from '../enum/statustype.enum';
import { EmployeeJob } from '../model/employeejob.model';
import { JobConfiguration } from '../model/jobconfiguration.model';

@Component({
  selector: 'app-jobapplicant',
  templateUrl: './jobapplicant.component.html',
  styleUrls: ['./jobapplicant.component.css']
})
export class JobapplicantComponent implements OnInit {

  employeeJobList: EmployeeJob[] = [];
  employeeJob: EmployeeJob;
  BreakException = {};
  jobStatus = JobStatus;
  jobMainStatus = StatusType;
  jobConfiguration: JobConfiguration;
  displayStyle = 'none';
  displayStyle2 = 'none';
  displayStyle3 = 'none';

  updateModel: { id: number, jobStatus: any } = {
    id: 0,
    jobStatus: ''
  };


  constructor(private toastr: ToastrService, private spinner: SpinnerService,
    private repo: RepositoryService) {

  }
  ngOnInit(): void {
    this.GetAllApplicatans();
  }

  GetAllApplicatans(): void {

    this.spinner.showSpinner();
    this.repo.getData('api/EmployeeJob/GetAll')
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.employeeJobList = data['data'];
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }

  Delete(index: number, id: number): void {
    this.spinner.showSpinner();
    this.repo.delete('api/EmployeeJob/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.employeeJobList.forEach((ele, i) => {
              if (ele.id === id) {
                this.employeeJobList.splice(index, 1);
                this.spinner.hideSpinner();
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  GetJobStatus(jobStatus: JobStatus) {
    return JobStatus[jobStatus]
  }
  OpenPopup(employeeJob: EmployeeJob): void {
    this.employeeJob = employeeJob;
    this.displayStyle = 'block';
  }
  OpenPopupJob(job:JobConfiguration ): void {
    this.jobConfiguration  = job;
    this.displayStyle2 = 'block';
  }
  OpenPopupStatus(id: number, jobStatus: JobStatus): void {
    this.updateModel.jobStatus= jobStatus;
    this.updateModel.id = id;
    this.displayStyle3 = 'block';
  }
  ClosePopup(): void {
    this.displayStyle = 'none';
  }
  ClosePopupJob(): void {
    this.displayStyle2 = 'none';
  }
  ClosePopupStatus(): void{
    this.displayStyle3 = 'none';
  }
  isEnumName(val){
    return !isNaN(Number(val.key));
  }
  UpdateStatus(): void{
      if (this.updateModel.id === 0) {
        this.toastr.error('Something went wrong');
        return;
      }
      this.spinner.showSpinner();
      this.repo.update('api/EmployeeJob/Update', this.updateModel.id, this.updateModel)
        .pipe(first())
        .subscribe(
          data => {
            if (data['returnStatus'] == true) {
              this.employeeJobList.forEach((ele, i) => {
                if (ele.id === this.updateModel.id) {
                  ele.jobStatus = this.updateModel.jobStatus;
                  this.toastr.success('Updated Successfully');
                  this.ClosePopupStatus();
                  this.spinner.hideSpinner();
                  throw this.BreakException;
                }
              });
            }
          },
          error => {
            this.spinner.hideSpinner();
            this.toastr.error(error.error.returnMessage[0]);
          });
    }
}
