import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RepositoryService } from '../shared/services/repository.service';
import { Employee } from '../model/employee.model';
import { Task } from '../model/task.model';
import { Job } from '../model/job.model';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import * as FileSaver from 'file-saver';
import { DataService } from '../shared/services/data.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { first } from 'rxjs/operators';
import { TimeSheetTokenService } from '../shared/services/timesheettoken.service';
import { SpinnerService } from '../shared/services/spinner.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;

  public employeeList: Employee[];
  public taskList: Task[];
  public jobList: Job[];
  public starttimee;

  constructor(private timesheet: TimeSheetTokenService, private auth: AuthService, private formBuilder: FormBuilder, private repo: RepositoryService,
    private route: ActivatedRoute, private toastr: ToastrService, private spinner: SpinnerService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    public data: DataService) {
    // this.timesheet.GetToken()
    // if (localStorage.getItem('access_token') != null) {
    //   this.GetEmployees();
    //   this.GetJobs();
    //   this.GetTask();
    // }
    // else {
    //   this.timesheet.GetToken()
    // }
  }

  ngOnInit(): void {
    this.runDateTimePicker();
    // this.route.queryParams
    //   .subscribe(params => {
    //     if (params.code != null && localStorage.getItem('access_token') == null) {
    //       localStorage.setItem('auth-code', params.code);
    //       this.repo.getData('api/Schedule/GetAccessCode?AuthCode=' + params.code)
    //         .subscribe({
    //           next: res => {
    //             if (res['returnStatus'] === true) {
    //               localStorage.setItem('access_token', res['data']['access_token']);
    //               localStorage.setItem('refresh_token', res['data']['refresh_token']);
    //               this.GetEmployees();
    //               this.GetJobs();
    //               this.GetTask();
    //             }
    //             else {
    //               // this.timesheet.GetToken();
    //             }
    //           },
    //           error: err => {
    //             // this.timesheet.GetToken();
    //           }
    //         });
    //     }
    //     else if (params.code == null && localStorage.getItem('access_token') == null) {
    //       // this.timesheet.GetToken();
    //     }
    //     else {
    //       this.GetEmployees();
    //       this.GetJobs();
    //       this.GetTask();
    //     }
    //   }
    //   );
    this.GetEmployees();
    this.GetJobs();
    this.GetTask();

    this.registerForm = this.formBuilder.group({
      // type: ['', Validators.required],
      starttime: ['', [Validators.required]], //,Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]
      endtime: ['', [Validators.required]],
      punchIn: [true],
      punchOut: [true],
      client: ['', Validators.required],
      employee: ['', Validators.required],
      service: ['', Validators.required],
      eventInfo: ['']
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit(): void {
    this.submitted = true;

    if ((<HTMLInputElement>document.getElementById('employee')).value === '') {
      this.registerForm.controls.employee.setValue('');
      this.restSelect2('employee');
    }
    else {
      this.registerForm.controls.employee.setValue((<HTMLInputElement>document.getElementById('employee')).value);
    }
    if ((<HTMLInputElement>document.getElementById('service')).value === '') {
      this.registerForm.controls.service.setValue('');
      this.restSelect2('service');
    }
    else {
      this.registerForm.controls.service.setValue((<HTMLInputElement>document.getElementById('service')).value);
    }
    if ((<HTMLInputElement>document.getElementById('client')).value === '') {
      this.registerForm.controls.client.setValue('');
      this.restSelect2('client');
    }
    else {
      this.registerForm.controls.client.setValue((<HTMLInputElement>document.getElementById('client')).value);
    }

    if ((<HTMLInputElement>document.getElementById('datetimepicker1')).value === '') {
      return;
    }
    else {
      this.registerForm.controls.starttime.setValue((<HTMLInputElement>document.getElementById('datetimepicker1')).value);
    }
    if ((<HTMLInputElement>document.getElementById('datetimepicker2')).value === '') {
      return;
    }
    else {
      this.registerForm.controls.endtime.setValue((<HTMLInputElement>document.getElementById('datetimepicker2')).value);
    }
    if (!this.CheckDateTimeCondition((<HTMLInputElement>document.getElementById('datetimepicker1')).value,
      (<HTMLInputElement>document.getElementById('datetimepicker2')).value)) {
      this.showError('End DateTime Must be greater then Start DateTime');
      return;
    }
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    let excel: any = {};
    excel.EmpName = this.registerForm.value.employee.toString().split('-')[1];
    excel.EmpNumber = this.registerForm.value.employee.toString().split('-')[0];
    excel.EmpPhoneNumber = this.registerForm.value.employee.toString().split('-')[2];
    excel.ClientName = this.registerForm.value.client.toString().split('-')[1];
    excel.ClientNumber = this.registerForm.value.client.toString().split('-')[0];
    excel.ServiceName = this.registerForm.value.service.toString().split('-')[1];
    excel.ServiceNumber = this.registerForm.value.service.toString().split('-')[0];
    excel.StartDate = this.registerForm.value.starttime; // ConvertSpecificDate( )
    excel.EndDate = this.registerForm.value.endtime; // ConvertSpecificDate( )
    excel.punch_in_reminder = this.registerForm.value.punchIn === true ? '1' : '0';
    excel.punch_out_reminder = this.registerForm.value.punchOut === true ? '1' : '0';
    excel.EventInfo = this.registerForm.value.eventInfo;
    excel.UserId = this.auth.GetUserId();
    this.spinner.showSpinner();
    this.repo.create('api/SpreadSheet/Insert', excel)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.showSuccess();
            this.onReset();
            this.data.excelData.push(excel);
            localStorage.setItem('excelData', JSON.stringify(this.data.excelData));
            this.registerForm.controls.punchIn.setValue(true);
            this.registerForm.controls.punchOut.setValue(true);
            this.restSelect2('select');
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error('Please contact to admin', 'Error');
        });

    // this.registerForm.controls.client.setValue('');
    // this.registerForm.controls.service.setValue('');
    // this.registerForm.controls.employee.setValue('');


  }
  CheckDateTimeCondition(start: string, end: string): boolean {
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (endDate < startDate) {
      return false;
    }
    else {
      return true;
    }

  }
  onReset(): void {
    this.submitted = false;
    this.registerForm.reset();
    this.restSelect2('select');
  }
  ngAfterViewInit(): void {
    this.InitSelect2();
  }
  public GetEmployees(): void {
    this.spinner.showSpinner();

    this.repo.getData('api/Employee/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.employeeList = res['data'];
          }
          else {
            // this.timesheet.GetToken();
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.spinner.hideSpinner();
          // this.timesheet.GetToken();
        }
      });
  }
  public GetTask(): void {
    this.repo.getData('api/Task/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.taskList = res['data'];
          }
          else {
            // this.timesheet.GetToken();
          }
        },
        error: err => {
          // this.timesheet.GetToken();
        }
      });
  }
  public GetJobs(): void {
    this.repo.getData('api/Job/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.jobList = res['data'];
          }
          else {
            // this.timesheet.GetToken();
          }
        },
        error: err => {
          // this.timesheet.GetToken();
        }
      });
  }
  // public DownloadExcel(): void {
  //   if (this.data.excelData.length === 0) {
  //     this.showError('Oops', 'List is Empty');
  //     return;
  //   }
  //   this.repo.downloadFile('api/Schedule/ScheduleExcel', this.data.excelData)
  //     .subscribe(res => {
  //       this.downloadExcel(res);
  //     });
  // }
  // downloadExcel(buffer: any) {
  //   const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
  //   FileSaver.saveAs(data, 'Scheduler' + '_export_' + new Date().getTime() + '.xlsx');
  // }
  ClearLocalStorage(): void {
    if (localStorage.getItem('access_token') != null) {
      localStorage.removeItem('access_token');
    }
    if (localStorage.getItem('auth-code') != null) {
      localStorage.removeItem('auth-code');
    }
    if (localStorage.getItem('refresh_token') != null) {

      localStorage.removeItem('refresh_token');
    }
  }
  showSuccess(): void {
    this.toastr.success('Added In List');
  }
  showError(message): void {
    this.toastr.error(message);
  }
  public ClearExcelData(): void {
    if (localStorage.getItem('excelData') != null) {
      localStorage.removeItem('excelData');
    }
    this.data.excelData = [];
  }
  public runDateTimePicker() {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('#datetimepicker1').datetimepicker({
              stepping: 5,
              sideBySide:true
              // debug: true
            });
            $('#datetimepicker2').datetimepicker({
              stepping: 5,
              sideBySide:true
            });
          });
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
  InitSelect2(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {

            // Initialize select2
            $("select").select2({    dropdownPosition: 'below'});
          });
          (function($) {
            var Defaults = $.fn.select2.amd.require('select2/defaults');
            $.extend(Defaults.defaults, {
              dropdownPosition: 'auto'
            });
            var AttachBody = $.fn.select2.amd.require('select2/dropdown/attachBody');
            var _positionDropdown = AttachBody.prototype._positionDropdown;
            AttachBody.prototype._positionDropdown = function() {
              var $window = $(window);
              var isCurrentlyAbove = this.$dropdown.hasClass('select2-dropdown--above');
              var isCurrentlyBelow = this.$dropdown.hasClass('select2-dropdown--below');
              var newDirection = null;
              var offset = this.$container.offset();
              offset.bottom = offset.top + this.$container.outerHeight(false);
              var container = {
                  height: this.$container.outerHeight(false)
              };
              container.top = offset.top;
              container.bottom = offset.top + container.height;
              var dropdown = {
                height: this.$dropdown.outerHeight(false)
              };
              var viewport = {
                top: $window.scrollTop(),
                bottom: $window.scrollTop() + $window.height()
              };
              var enoughRoomAbove = viewport.top < (offset.top - dropdown.height);
              var enoughRoomBelow = viewport.bottom > (offset.bottom + dropdown.height);
              var css = {
                left: offset.left,
                top: container.bottom
              };
              // Determine what the parent element is to use for calciulating the offset
              var $offsetParent = this.$dropdownParent;
              // For statically positoned elements, we need to get the element
              // that is determining the offset
              if ($offsetParent.css('position') === 'static') {
                $offsetParent = $offsetParent.offsetParent();
              }
              var parentOffset = $offsetParent.offset();
              css.top -= parentOffset.top
              css.left -= parentOffset.left;
              var dropdownPositionOption = this.options.get('dropdownPosition');
              if (dropdownPositionOption === 'above' || dropdownPositionOption === 'below') {
                newDirection = dropdownPositionOption;
              } else {
                if (!isCurrentlyAbove && !isCurrentlyBelow) {
                  newDirection = 'below';
                }
                if (!enoughRoomBelow && enoughRoomAbove && !isCurrentlyAbove) {
                  newDirection = 'above';
                } else if (!enoughRoomAbove && enoughRoomBelow && isCurrentlyAbove) {
                  newDirection = 'below';
                }
              }
              if (newDirection == 'above' ||
              (isCurrentlyAbove && newDirection !== 'below')) {
                  css.top = container.top - parentOffset.top - dropdown.height;
              }
              if (newDirection != null) {
                this.$dropdown
                  .removeClass('select2-dropdown--below select2-dropdown--above')
                  .addClass('select2-dropdown--' + newDirection);
                this.$container
                  .removeClass('select2-container--below select2-container--above')
                  .addClass('select2-container--' + newDirection);
              }
              this.$dropdownContainer.css(css);
            };
          })(window.jQuery);
        }
    `;
    this._renderer2.appendChild(this._document.body, script);
  }
  restSelect2(val: string): void {
    const script = this._renderer2.createElement('script');
    if (val === 'select') {
      script.text = `
      {
        $(function () {
          $("select").val('').trigger('change')
        });
      }
      `;
    }
    if (val === 'employee') {
      script.text = `
      {
        $(function () {
          $('#employee').val('').trigger('change')
        });
      }
      `;
    }
    if (val === 'service') {
      script.text = `
      {
        $(function () {
          $('#service').val('').trigger('change')
        });
      }
      `;
    }
    if (val === 'client') {
      script.text = `
      {
        $(function () {
          $('#client').val('').trigger('change')
        });
      }
      `;
    }
    this._renderer2.appendChild(this._document.body, script);
  }
}
function ConvertSpecificDate(date: string): string {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  const time = date.split('T')[1];
  return month + '/' + day + '/' + year + ' ' + time;
}


