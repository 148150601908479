<style>
  .conTable tbody td {
    vertical-align: middle !important;
  }

  table thead tr th {
    font-weight: 500 !important;
  }

  .modal-dialog {
    max-width: 1300px !important;
  }

  .modal-body {
    height: 456px;
    overflow: scroll;
  }

  .modal-content {
    width: 1300px !important;
    height: 580px !important;
  }
</style>
<div class="row noprint">
  <div class="col-md-12 ">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <div class="row">
          <div class="col">
            <h5 style="float: left;padding: .75rem 1.25rem">Client Billing Configuration</h5>
            <a [routerLink]="[ '/service-configuration']" target="_blank"
              style="float: right;margin-top: 8px;margin-right: 8px;" class="btn btn-custom">Service Configuration</a>

            <button type="button" (click)="OpenPopup()" style="float: right;margin-top: 8px;margin-right: 8px;"
              class="btn btn-custom">Add Client Billing Configuration</button>

          </div>
        </div>
        <div class="card-body">
          <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-row row">
              <div class="col-md-3 col-lg-3">
                <label>Clients</label>
                <select formControlName="sites" id="sites" class="form-control select2 js-example-basic-single"
                  [ngClass]="{ 'is-invalid': submitted && f.sites.errors }">
                  <option value="" selected>All Clients</option>
                  <option *ngFor="let job of jobList" value="{{job.job_name}}">
                    {{job.job_name}}
                  </option>
                </select>
                <div *ngIf="submitted && f.sites.errors" class="invalid-feedback">
                  <div *ngIf="f.sites.errors.required">Client is required</div>
                </div>
              </div>
              <div class="col-md-1 col-lg-1">
                <button type="submit" class="btn btn-custom" style="position: absolute;top: 30px;">Get Client</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="clientBillingConfigurations && clientBillingConfigurations.length > 0" class="row">
  <div class="offset-md-1 col-md-10">
    <h6 style="font-weight: bold">Total Clients: {{clientBillingConfigurations.length}}</h6>
  </div>
</div>
<div class="row">
  <div class="offset-md-1 col-md-10">
    <ng-container *ngFor="let clientBillingConfiguration of clientBillingConfigurations; index as j">
      <div class="accordion mt10" id="accordionExample1">
        <div class="card">
          <div class="card-header" [id]="'heading' + (j + clientBillingConfigurations.length)">
            <h2 class="mb-0">
              <a role="button" class="btn btn-link btn-block text-left" data-toggle="collapse" data-parent="#accordion"
                [href]="'#collapse' + (j + clientBillingConfigurations.length)" aria-expanded="true"
                [attr.aria-controls]="'collapse' + (j + clientBillingConfigurations.length)">
                <!-- <div class="row">
                  <div style="margin-bottom: -13px" class="col-md-4">
                    <p class="text-bold btn-color-black">{{clientBillingConfiguration.client}}
                      {{clientBillingConfiguration.room}}</p>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-md-5">
                    <h6 class="bold">Client Name: {{clientBillingConfiguration.client}}</h6>
                  </div>
                  <div class="col-md-3">
                    <h6 class="bold">Room# {{clientBillingConfiguration.room}}</h6>
                  </div>
                  <div class="col-md-3 text-center">
                    <p class="text-bold"
                      [ngClass]="{ 'text-green': clientBillingConfiguration.status == statusType.Active, 'text-danger': clientBillingConfiguration.status == statusType.Disabled }">
                      {{ clientBillingConfiguration.status == statusType.Active? 'Active' : 'Suspended' }}</p>
                  </div>
                  <div class="col-md-1 text-right">
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-sliders"></i>
                      </button>
                      <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button style="float: right;margin-left: 10px;"
                          (click)="ChangeStatus(clientBillingConfiguration.status,clientBillingConfiguration.id)"
                          class="dropdown-item">Change Status to {{ clientBillingConfiguration.status ==
                          statusType.Active?
                          'Suspended' : 'Active' }}</button>
                        <button style="float: right;margin-left: 10px;"
                          (click)="Delete(j,clientBillingConfiguration.id)" class="dropdown-item">Delete</button>
                        <button style="float: right;" (click)="OpenPopup(clientBillingConfiguration)"
                          class="dropdown-item">Edit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </h2>
          </div>
          <div [id]="'collapse' + (j + clientBillingConfigurations.length)"
            [ngClass]="{'show': clientBillingConfigurations.length === 1,'hide': clientBillingConfigurations.length > 1}"
            class="collapse" [attr.aria-labelledby]="'heading' + (j + clientBillingConfigurations.length)"
            data-parent="#accordionExample1">
            <div class="card-body">
              <ng-container *ngIf="clientBillingConfiguration">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row mt5">
                      <div class="col-md-12">
                        <table style="border: 1px solid #eee" class="table">
                          <thead>
                            <th>Service</th>
                            <!-- <th>Service Price</th> -->
                            <th>Meridiem Type</th>
                            <th>Sunday</th>
                            <th>Monday</th>
                            <th>Tuesday</th>
                            <th>Wednesday</th>
                            <th>Thursday</th>
                            <th>Friday</th>
                            <th>Saturday</th>
                          </thead>
                          <tbody *ngFor="let items of GroupData(clientBillingConfiguration)">
                            <tr *ngFor="let item of items; let i=index">
                              <ng-container *ngIf=" i == 0">
                                <td style="vertical-align:middle;font-weight: bold;" [attr.rowspan]="items.length">
                                  {{item.serviceConfiguration.name}}
                                </td>
                              </ng-container>
                              <td>{{GetMeridiemType(item.type)}}</td>
                              <td><span [ngClass]="{'qt-visible': item.sunday >=1}">{{item.sunday}}</span></td>
                              <td><span [ngClass]="{'qt-visible': item.monday >=1}">{{item.monday}}</span></td>
                              <td><span [ngClass]="{'qt-visible': item.tuesday >=1}">{{item.tuesday}}</span></td>
                              <td><span [ngClass]="{'qt-visible': item.wednesday >=1}">{{item.wednesday}}</span></td>
                              <td><span [ngClass]="{'qt-visible': item.thursday >=1}">{{item.thursday}}</span></td>
                              <td><span [ngClass]="{'qt-visible': item.friday >=1}">{{item.friday}}</span></td>
                              <td><span [ngClass]="{'qt-visible': item.saturday >=1}">{{item.saturday}}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div [ngStyle]="{'display':displayStyle}" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <!-- modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{isUpdate?'Edit':'Add'}} Client Billing Configuration</h5>
        <button (click)="ClosePopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form autocomplete="off" [formGroup]="registerForm2" (ngSubmit)="AddRecord()">
        <div class="modal-body">
          <div class="form-row row">
            <div *ngIf="false" class="col-md-4 col-lg-4">
              <label>Date Range</label>
              <select formControlName="definedDate" (change)="ChangeDateTypeAdd(this.registerForm2.value.definedDate)"
                id="definedDate" class="form-control" [ngClass]="{ 'is-invalid': submitted2 && a.definedDate.errors }">
                <option *ngFor="let item of dateList" value="{{item.date}}">
                  {{item.showDate}}
                </option>

              </select>
              <div class="row mt5" *ngIf="showCustomDateRange2">
                <div class="col-md-6">
                  <input id="datetimepicker3" autocomplete="off" placeholder="Select Start Date" type="text"
                    formControlName="startDate" class="form-control datetimepicker"
                    [ngClass]="{ 'is-invalid': submitted2 && a.startDate.errors }" />
                </div>
                <div class="col-md-6">
                  <input id="datetimepicker4" autocomplete="off" placeholder="Select End Date" type="text"
                    formControlName="endDate" class="form-control datetimepicker"
                    [ngClass]="{ 'is-invalid': submitted2 && a.endDate.errors }" />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="row">
                <div class="col-md-12">
                  <label>Client</label>
                </div>
                <div class="col-md-12">
                  <select formControlName="client" id="client" class="clientDropDwn form-control select2"
                    [ngClass]="{ 'is-invalid': submitted2 && a.client.errors }">
                    <option value="" selected>Select Client</option>
                    <option *ngFor="let job of jobList" value="{{job.job_name}}">
                      {{job.job_name}}
                    </option>
                  </select>
                  <div *ngIf="submitted2 && a.client.errors" class="invalid-feedback">
                    <div *ngIf="a.client.errors.required">Client is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="row">
                <div class="col-md-12">
                  <label>Room #</label>
                </div>
                <div class="col-md-12">
                  <input placeholder="Please enter room #" formControlName="room"
                    [ngClass]="{ 'is-invalid': submitted2 && a.room.errors }" class="form-control" />
                  <div *ngIf="submitted2 && a.room.errors" class="invalid-feedback">
                    <div *ngIf="a.room.errors.required">Room # is required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-row row">
            <div class="col-md-12">
              <table class="table conTable">
                <thead>
                  <th colspan="4">Service </th>
                  <th>Sunday</th>
                  <th>Monday</th>
                  <th>Tuesday</th>
                  <th>Wednesday</th>
                  <th>Thursday</th>
                  <th>Friday</th>
                  <th>Saturday</th>
                </thead>
                <tbody class="addlist" id="row_field" formArrayName="clientBillingDayConfigurations"
                  *ngFor="let service of registerForm2.get('clientBillingDayConfigurations')['controls']; let i = index;">
                  <ng-container [formGroupName]="i" class="form-row row">
                    <tr
                      [ngClass]="{'bg-eee': (d.controls[i].get('sunday').value >= 1 || d.controls[i].get('monday').value >= 1 || d.controls[i].get('tuesday').value >= 1 || d.controls[i].get('wednesday').value >= 1 || d.controls[i].get('thursday').value >= 1 || d.controls[i].get('friday').value >= 1 || d.controls[i].get('saturday').value >= 1)}">
                      <td colspan="3"><span>{{ d.controls[i].get('name').value}}</span></td>
                      <td colspan="1"><ng-container *ngIf="d.controls[i].get('isMeridiem').value">
                          <select disabled style="width: 84%" formControlName="type" class="form-control">
                            <ng-container *ngFor="let item of meridiemType| keyvalue">
                              <option *ngIf="isEnumName(item)" [value]="item.key">
                                {{item.value}}
                              </option>
                            </ng-container>
                          </select>
                        </ng-container>
                      </td>
                      <td>
                        <span (click)="QTMinusPlus('-',i,'sunday')" class="qt-minus">-</span>
                        <span [ngClass]="{'qt-visible': d.controls[i].get('sunday').value >= 1}"
                          class="qt">{{d.controls[i].get('sunday').value}}</span>
                        <span (click)="QTMinusPlus('+',i,'sunday')" class="qt-minus">+</span>
                      </td>
                      <td>
                        <span (click)="QTMinusPlus('-',i,'monday')" class="qt-minus">-</span>
                        <span [ngClass]="{'qt-visible': d.controls[i].get('monday').value >= 1}"
                          class="qt">{{d.controls[i].get('monday').value}}</span>
                        <span (click)="QTMinusPlus('+',i,'monday')" class="qt-plus">+</span>
                      </td>
                      <td>
                        <span (click)="QTMinusPlus('-',i,'tuesday')" class="qt-minus">-</span>
                        <span [ngClass]="{'qt-visible': d.controls[i].get('tuesday').value >= 1}"
                          class="qt">{{d.controls[i].get('tuesday').value}}</span>
                        <span (click)="QTMinusPlus('+',i,'tuesday')" class="qt-minus">+</span>
                      </td>
                      <td>
                        <span (click)="QTMinusPlus('-',i,'wednesday')" class="qt-minus">-</span>
                        <span [ngClass]="{'qt-visible': d.controls[i].get('wednesday').value >= 1}"
                          class="qt">{{d.controls[i].get('wednesday').value}}</span>
                        <span (click)="QTMinusPlus('+',i,'wednesday')" class="qt-minus">+</span>
                      </td>
                      <td>
                        <span (click)="QTMinusPlus('-',i,'thursday')" class="qt-minus">-</span>
                        <span [ngClass]="{'qt-visible': d.controls[i].get('thursday').value >= 1}"
                          class="qt">{{d.controls[i].get('thursday').value}}</span>
                        <span (click)="QTMinusPlus('+',i,'thursday')" class="qt-minus">+</span>
                      </td>
                      <td>
                        <span (click)="QTMinusPlus('-',i,'friday')" class="qt-minus">-</span>
                        <span [ngClass]="{'qt-visible': d.controls[i].get('friday').value >= 1}"
                          class="qt">{{d.controls[i].get('friday').value}}</span>
                        <span (click)="QTMinusPlus('+',i,'friday')" class="qt-minus">+</span>
                      </td>
                      <td>
                        <span (click)="QTMinusPlus('-',i,'saturday')" class="qt-minus">-</span>
                        <span [ngClass]="{'qt-visible': d.controls[i].get('saturday').value >= 1}"
                          class="qt">{{d.controls[i].get('saturday').value}}</span>
                        <span (click)="QTMinusPlus('+',i,'saturday')" class="qt-minus">+</span>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-custom">{{isUpdate?'Update':'Add'}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>
