import { DOCUMENT, formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { EventType } from 'src/app/enum/eventtype.enum';
import { StatusType } from 'src/app/enum/statustype.enum';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { EventConfiguration } from '../../model/eventconfiguration.model';

@Component({
  selector: 'app-eventconfiguration',
  templateUrl: './eventconfiguration.component.html',
  styleUrls: ['./eventconfiguration.component.css']
})
export class EventconfigurationComponent implements OnInit {

  eventConfigurationList: EventConfiguration[] = [];
  BreakException = {};
  eventStatus = StatusType;
  eventType = EventType;
  displayStyle = 'none';
  displayStyle2 = 'none';
  updateModel: { id: number, date: Date, place: string, location: string, type: EventType } = {
    id: 0,
    date: null,
    place: null,
    location: null,
    type: null
  };
  createModel: { date: Date, place: string, location: string, type: EventType } = {
    date: null,
    place: null,
    location: null,
    type: null
  };
  constructor(private auth: AuthService, private toastr: ToastrService, private spinner: SpinnerService,
    private repo: RepositoryService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(LOCALE_ID) private locale: string) { }


  ngOnInit(): void {
      this.RunDateTimePicker();
    this.GetAlleventConfiguration();
  }

  OpenPopup(id: number, date: Date, place: string, location: string, type: EventType ): void {
    this.updateModel.date = date;
    this.updateModel.place = place;
    this.updateModel.location = location;
    this.updateModel.type = type;
    this.updateModel.id = id;
    this.displayStyle = 'block';
  }
  ClosePopup(): void {
    this.displayStyle = 'none';
  }

  OpenPopupForCreate(): void {
    this.displayStyle2 = 'block';
  }
  ClosePopupForCreate(): void {
    this.displayStyle2 = 'none';
  }

  GetAlleventConfiguration(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/EventConfiguration/GetAll')
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.eventConfigurationList = data['data'];
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  Update(): void {
    if (this.updateModel.id === 0 || this.updateModel.date === null || this.updateModel.place === '' || this.updateModel.location === '' || this.updateModel.type == null) {
      this.toastr.error('All fields are required');
      return;
    }
    this.spinner.showSpinner();
    this.repo.update('api/EventConfiguration/Update', this.updateModel.id, this.updateModel)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.eventConfigurationList.forEach((ele, i) => {
              if (ele.id === this.updateModel.id) {
                ele.date = this.updateModel.date;
                ele.place = this.updateModel.place;
                ele.location = this.updateModel.location;
                ele.type = this.updateModel.type;
                this.toastr.success('Updated Successfully');
                this.ClosePopup();
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  AddNewEventConfiguration(): void {
    this.createModel.date =  new Date(((document.getElementById('datetimepicker1') as HTMLInputElement).value).toString());

    if (this.createModel.date === null || this.createModel.place === '' || this.createModel.location === '' || this.createModel.type === null) {
      this.toastr.error('All fields are required');
      return;
    }
    this.spinner.showSpinner();
    this.repo.create('api/EventConfiguration/insert', this.createModel)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.eventConfigurationList.push(data['data']);
            this.toastr.success('Added Successfully');
            this.createModel = { date: null, place: '', location: '', type: null };
            this.ClosePopupForCreate();
            this.spinner.hideSpinner();
          }
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }


  Delete(index: number, id: number): void {
    this.spinner.showSpinner();
    this.repo.delete('api/EventConfiguration/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.eventConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.eventConfigurationList.splice(index, 1);
                this.spinner.hideSpinner();
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  ChangeStatus(status: StatusType, id: number): void {
    this.spinner.showSpinner();
    this.repo.getData('api/EventConfiguration/Disable', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.eventConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.eventConfigurationList[i].status = (status === this.eventStatus.Disabled ?
                  this.eventStatus.Active : this.eventStatus.Disabled);
                this.toastr.success(data['returnMessage'][0]);
                this.spinner.hideSpinner();
                throw this.BreakException;
              }
            });
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  RunDateTimePicker(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('.datetimepicker').datetimepicker({
              stepping: 15,
              sideBySide:true
             // debug: true
            });
          });
        }
    `;
    this._renderer2.appendChild(this._document.body, script);
  }
  DestroyDateTimePicker(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('.datetimepicker').data("DateTimePicker").destroy();
          });
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
  isEnumName(val){
    return !isNaN(Number(val.key));
  }
  getEventType(val){
    return this.eventType[val];
  }
}
