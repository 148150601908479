<div style="    opacity: 0.9;
background-color: white;
 margin: 1% 5%;
padding: 3%;
box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
">
  <div class="row">
    <div class="col-12 center" style="margin-bottom: 2%">
      <h3>Agreement Change Request Form</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 offset-md-1">
      <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-6">
            Requestor Name: <input formControlName="requestorName" class="form-control" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.requestorName?.errors }"
              placeholder="Please Enter Requestor Name" />

            <div *ngIf="submitted && f.requestorName.errors" class="invalid-feedback">
              <div *ngIf="f.requestorName.errors.required">Requestor Name is required</div>
            </div>
          </div>
          <div class="col-md-6">
            Request Date: <input formControlName="requestorDate" class="form-control" type="date"
              [ngClass]="{ 'is-invalid': submitted && f.requestorDate.errors }"
              placeholder="Please Enter Requestor Date" />

            <div *ngIf="submitted && f.requestorDate.errors" class="invalid-feedback">
              <div *ngIf="f.requestorDate.errors.required">Requestor Date is required</div>
            </div>
          </div>
        </div>
        <div class="row mt15">
          <div class="col-md-6">
            Client Name
            <select formControlName="clientName" id="clientName" class="form-control js-example-basic-single"
              [ngClass]="{ 'is-invalid': submitted && f.clientName.errors }">
              <option value="" [attr.selected]="true">Select Client</option>
              <option *ngFor="let job of jobList" value="{{job.job_name}}">
                {{job.job_name}} - {{job.job_number}}
              </option>
            </select>
            <div *ngIf="submitted && f.clientName.errors" class="invalid-feedback">
              <div *ngIf="f.clientName.errors.required">Client Name is required</div>
            </div>
          </div>
          <div class="col-md-6">
            Client Room #: <input formControlName="clientRoom" class="form-control" type="number"
              [ngClass]="{ 'is-invalid': submitted && f.clientRoom.errors }" placeholder="Please Enter Client Room #" />

            <div *ngIf="submitted && f.clientRoom.errors" class="invalid-feedback">
              <div *ngIf="f.clientRoom.errors.required">Client Room # is required</div>
            </div>
          </div>
        </div>
        <div class="row mt15">
          <!-- <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <h5>Change Request Detail</h5>
              </div>
            </div>
            <div class="form-check">
              <input formControlName="type" value="I Want to Add Service" class="form-check-input" type="radio"
                name="type" id="RadioDefault1" [attr.checked]="true" />
              <label class="form-check-label" for="RadioDefault1">
                I Want to <b>Add</b> Service
              </label>
            </div>
            <div class="form-check">
              <input formControlName="type" class="form-check-input" type="radio" name="type" id="RadioDefault2"
                value="I Want to Remove Service" />
              <label class="form-check-label" for="RadioDefault2">
                I Want to <b>Remove</b> Service
              </label>
            </div>
          </div> -->
          <div class="col-md-6">
            When would you like this change to take effect
            <input formControlName="changeDate" class="form-control" type="date"
              [ngClass]="{ 'is-invalid': submitted && f.changeDate.errors }" placeholder="Please Enter Change Date" />

            <div *ngIf="submitted && f.changeDate.errors" class="invalid-feedback">
              <div *ngIf="f.changeDate.errors.required">Change Date is required</div>
            </div>
          </div>
        </div>
        <div class="row mt15">
          <div class="col-12">
            <h4>Services</h4>
          </div>
        </div>
        <div class="row mt15">
          <div class="col-12" style="Overflow-y: scroll;height: 250px;">
            <div class="addlist" id="row_field" formArrayName="services"
              *ngFor="let item of registerForm.get('services')['controls']; let i = index;">
              <div [formGroupName]="i" class="form-row row">
                <div class="form-group col-md-4">
                  <span class="bold">{{ d.controls[i].get('service').value}}</span>
                </div>
                <div class="form-group col-md-3">
                  <select formControlName="type" class="form-control">
                    <option value="" selected>Please Select Type</option>
                    <option value="I Want to Add Service">I Want to Add Service</option>
                    <option value="I Want to Remove Service">I Want to Remove Service</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <select formControlName="value" class="form-control">
                    <option value="" selected>Please Select Value</option>
                    <option value="15 min">15 min</option>
                    <option value="30 min">30 min</option>
                    <option value="60 min">60 min</option>
                    <option value="90 min">90 min</option>
                    <option value="120 min">120 min</option>
                  </select>
                  <!-- <input type="number" formControlName="value" class="form-control" placeholder="Enter value"
                    [ngClass]="{ 'is-invalid': submitted && d.controls[i].get('value')?.errors }" /> -->
                  <div *ngIf="submitted && d.controls[i].get('value')?.errors" class="invalid-feedback">
                    <div *ngIf="submitted && d.controls[i].get('value')?.errors?.required">
                      Value is required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt15">
          <div class="col-12">
            Comments: <textarea class="form-control" formControlName="comments" rows="3" placeholder="Comments">
          </textarea>
          </div>
        </div>
        <div class="row mt15">
          <div class="col-12">
            <button type="submit" class="btn btn-custom">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="fire" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
