<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-3">
        <div class="card m-3 beautifyBox beautifySettingBox p-30">
          <i class="fa fa-cogs btn-link i-style"></i>
          <a href="" class="btn btn-link" routerLink="/holiday-configuration"
          routerLinkActive="active">Holiday Configuration</a>
        </div>
      </div>
      <div class="col-md-3">
        <div routerLink="/shift-request-configuration" class="card m-3 beautifyBox beautifySettingBox p-30">
          <i class="fa fa-cogs btn-link i-style"></i>
          <a href="" class="btn btn-link" routerLink="/shift-request-configuration"
          routerLinkActive="active">Shift Request Configuration</a>
        </div>
      </div>
      <div class="col-md-3">
        <div routerLink="/facility-link-generator" class="card m-3 beautifyBox beautifySettingBox p-30">
          <i class="fa fa-cogs btn-link i-style"></i>
          <a href="" class="btn btn-link" routerLink="/facility-link-generator"
          routerLinkActive="active">Facility Link Generator</a>
        </div>
      </div>
      <div class="col-md-3">
        <div routerLink="/employee-file-configuration" class="card m-3 beautifyBox beautifySettingBox p-30">
          <i class="fa fa-cogs btn-link i-style"></i>
          <a href="" class="btn btn-link" routerLink="/employee-file-configuration"
          routerLinkActive="active">Employee File Configuration</a>
        </div>
      </div>
      <div class="col-md-3">
        <div routerLink="/quickbook-configuration" class="card m-3 beautifyBox beautifySettingBox p-30">
          <i class="fa fa-cogs btn-link i-style"></i>
          <a href="" class="btn btn-link" routerLink="/quickbook-configuration"
          routerLinkActive="active">Quick Book Configuration</a>
        </div>
      </div>
      <div class="col-md-3">
        <div routerLink="/event-configuration" class="card m-3 beautifyBox beautifySettingBox p-30">
          <i class="fa fa-cogs btn-link i-style"></i>
          <a href="" class="btn btn-link" routerLink="/event-configuration"
          routerLinkActive="active">Event Configuration</a>
        </div>
      </div>
      <div class="col-md-3">
        <div routerLink="/checklist-configuration" class="card m-3 beautifyBox beautifySettingBox p-30">
          <i class="fa fa-cogs btn-link i-style"></i>
          <a href="" class="btn btn-link" routerLink="/checklist-configuration"
          routerLinkActive="active">Check List Configuration</a>
        </div>
      </div>
      <div class="col-md-3">
        <div routerLink="/job-configuration" class="card m-3 beautifyBox beautifySettingBox p-30">
          <i class="fa fa-cogs btn-link i-style"></i>
          <a href="" class="btn btn-link" routerLink="/job-configuration"
          routerLinkActive="active">Job Configuration</a>
        </div>
      </div>
      <div class="col-md-3">
        <div routerLink="/job-location-configuration" class="card m-3 beautifyBox beautifySettingBox p-30">
          <i class="fa fa-cogs btn-link i-style"></i>
          <a href="" class="btn btn-link" routerLink="/job-location-configuration"
          routerLinkActive="active">Job Location Configuration</a>
        </div>
      </div>
      <div class="col-md-3">
        <div routerLink="/service-configuration" class="card m-3 beautifyBox beautifySettingBox p-30">
          <i class="fa fa-cogs btn-link i-style"></i>
          <a href="" class="btn btn-link" routerLink="/service-configuration"
          routerLinkActive="active">Service Configuration</a>
        </div>
      </div>
      <div class="col-md-3">
        <div routerLink="/client-billing-configuration" class="card m-3 beautifyBox beautifySettingBox p-30">
          <i class="fa fa-cogs btn-link i-style"></i>
          <a href="" class="btn btn-link" routerLink="/client-billing-configuration"
          routerLinkActive="active">Client Billing Configuration</a>
        </div>
      </div>
    </div>
  </div>
</div>
