<div class="row">
  <div class="col-md-12 ">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <div class="row">
          <div class="col">
            <h5 style="float: left;padding: .75rem 1.25rem">Facility Link Generator</h5>
          </div>
        </div>
        <div class="card-body">
          <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class=" row">
              <div class="col-md-3">
                <!-- <label>Shift Type</label> -->
                <select formControlName="communityName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.communityName.errors }">
                  <option value="" selected>Select Community Name</option>
                  <option *ngFor="let item of communityNameList" value={{item.communityName}}>{{item.communityName}}</option>
                </select>
                <div *ngIf="submitted && f.communityName.errors" class="invalid-feedback">
                  <div *ngIf="f.communityName.errors.required">Community Name is required</div>
                </div>
              </div>
              <div class="col-md-2">
                <button type="submit" class="btn btn-custom">Generate</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="facilityLink" class="row">
  <div class="col-md-8 offset-md-2">
    <div class="card m-3 beautifyBox text-center" style="padding: 50px;">
        <h5>Click on link to verify:</h5>
        <a href="{{facilityLink}}" target="_blank" class="btn-link link-hover-underline">{{facilityLink}}</a>
    </div>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "fire" [fullScreen] = "true">
  <p style="color: white" > Loading... </p>
</ngx-spinner>
