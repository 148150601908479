import { Component, Input, OnInit } from '@angular/core';
import { VAClientEmployeeHourReport } from 'src/app/model/vaclientemployeehourreport.model';

@Component({
  selector: 'app-vaclientemployeehourslist',
  templateUrl: './vaclientemployeehourslist.component.html',
  styleUrls: ['./vaclientemployeehourslist.component.css']
})
export class VaclientemployeehourslistComponent implements OnInit {
  @Input() vaClientEmployeeHours: VAClientEmployeeHourReport[];
  constructor() { }

  ngOnInit(): void {
  }

  GetTotal(val: VAClientEmployeeHourReport, isUnit?, isCharge?): Number {
    if (isUnit)
      return val.subTotal.reduce((sum, el) => sum += el.duration * 4, 0);
    else if (isCharge)
      return val.subTotal.reduce((sum, el) => sum += el.duration * 4 * 7.88, 0);
    return val.subTotal.reduce((sum, el) => sum += el.duration, 0);
  }
}
