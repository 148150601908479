import { DOCUMENT, formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Employee } from 'src/app/model/employee.model';
import { EmployeePayrollHourReport } from 'src/app/model/employeepayrollhours.model';
import { Job } from 'src/app/model/job.model';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { TimeSheetTokenService } from 'src/app/shared/services/timesheettoken.service';
import * as FileSaver from 'file-saver';
@Component({
  selector: 'app-payrollemployeetimesheet',
  templateUrl: './payrollemployeetimesheet.component.html',
  styleUrls: ['./payrollemployeetimesheet.component.css']
})
export class PayrollemployeetimesheetComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  public employeeList: Employee[];
  public jobList: Job[];
  dateList: any;
  employeePayrollHoursList: EmployeePayrollHourReport[];
  date;
  currentDate;
  priorDate;
  currentWeek;
  showCustomDateRange: boolean;
  employeeTotalFullDays = 0;
  employeeTotalFullDayHoliday = 0;
  employeeTotalRegular = 0;
  employeeTotalRegularHoliday = 0;
  employeeTotalOT = 0;
  employeeStartDate = '';
  employeeEndDate = '';
  employeeTotalRegularHolidayOT = 0;
  conflictNames: string[];
  displayStyle = 'none';
  constructor(@Inject(LOCALE_ID) private locale: string,
    private timesheet: TimeSheetTokenService, private formBuilder: FormBuilder, private repo: RepositoryService,
    private toastr: ToastrService, private spinner: SpinnerService,
    // tslint:disable-next-line: variable-name
    private _renderer2: Renderer2,
    // tslint:disable-next-line: variable-name
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {

    this.DateDropDowns();
    this.GetEmployees();
    this.GetJobs();
    this.registerForm = this.formBuilder.group({
      employees: ['0'],
      sites: ['0'],  // [Validators.required]
      startDate: [''],
      endDate: [''],
      access_Token: [''],
      definedDate: [formatDate(this.currentDate = new Date(), 'MM/dd/yyyy', this.locale)]
    });
  }
  get f() { return this.registerForm.controls; }

  onSubmit(): void {
    this.submitted = true;
    if (this.showCustomDateRange && !this.CheckDateTimeCondition((document.getElementById('datetimepicker1') as HTMLInputElement).value,
      (document.getElementById('datetimepicker2') as HTMLInputElement).value)) {
      return;
    }
    this.registerForm.controls.employees.setValue((document.getElementById('employees') as HTMLInputElement).value);
    this.registerForm.controls.sites.setValue((document.getElementById('sites') as HTMLInputElement).value);
    if (this.registerForm.invalid) {
      return;
    }
    this.RunReport();

  }
  CheckDateTimeCondition(start: string, end: string): boolean {
    if (start === '') {
      this.toastr.warning('Start Date Required', 'Required');
      return false;
    }
    else {
      this.registerForm.controls.startDate.setValue((document.getElementById('datetimepicker1') as HTMLInputElement).value);
    }
    if (end === '') {
      this.toastr.warning('End Date Required', 'Oops');
      return false;
    }
    else {
      this.registerForm.controls.endDate.setValue((document.getElementById('datetimepicker2') as HTMLInputElement).value);
    }
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (endDate < startDate) {
      this.toastr.warning('End Date Must be greater then Start Date', 'Oops');
      return false;
    }
    else {
      return true;
    }

  }
  ngAfterViewInit(): void {
    // this.RunDateTimePicker();
    this.InitSelect2();
  }

  onReset(): void {
    this.submitted = false;
    this.registerForm.reset();
  }
  public GetEmployees(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/Employee/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.employeeList = res['data'];
          }
          else {
            //  this.timesheet.GetToken();
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.spinner.hideSpinner();
          //  this.timesheet.GetToken();
        }
      });
  }
  public GetJobs(): void {
    this.repo.getData('api/Job/GetAll?Access_Token=' + localStorage.getItem('access_token'))
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.jobList = res['data'];
          }
          else {
            //  this.timesheet.GetToken();
          }
        },
        error: err => {
          //  this.timesheet.GetToken();
        }
      });
  }
  InitSelect2(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $(".select2").select2();
          });
        }
    `;
    this._renderer2.appendChild(this._document.body, script);
  }
  DateDropDowns(): void {
    this.date = new Date();
    this.currentWeek = new Date(this.date.setDate(this.date.getDate() - this.date.getDay()));
    const currentWeek2 = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 6));

    const priorWeek2 = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() - 1));
    const priorWeek = new Date(this.date.setDate(this.date.getDate() - this.date.getDay()));

    const currentPeriod = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 7));
    const currentPeriod2 = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 13));

    const priorPeriod = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() - 21));
    const priorPeriod2 = new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 13));

    this.dateList = [
      {
        date: formatDate(this.currentDate = new Date(), 'MM/dd/yyyy', this.locale),
        showDate: 'Current day ' + formatDate(this.currentDate = new Date(), '(MMM dd)', this.locale)
      },
      {
        date: formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), 'MM/dd/yyyy', this.locale),
        showDate: 'Prior day ' + formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), '(MMM dd)', this.locale)
      },
      {
        date: formatDate(this.currentWeek, 'MM/dd/yyyy', this.locale) + '-' +
          formatDate(currentWeek2, 'MM/dd/yyyy', this.locale),
        showDate: 'Current week ' + formatDate(this.currentWeek, '(MMM dd', this.locale) + ' - ' +
          formatDate(currentWeek2, 'MMM dd)', this.locale)
      },
      {
        date: formatDate(priorWeek, 'MM/dd/yyyy', this.locale) + '-' +
          formatDate(priorWeek2, 'MM/dd/yyyy', this.locale),
        showDate: 'Prior week ' + formatDate(priorWeek, '(MMM dd', this.locale) + ' - ' +
          formatDate(priorWeek2, 'MMM dd)', this.locale)
      },
      {
        date: formatDate(currentPeriod, 'MM/dd/yyyy', this.locale) + '-' +
          formatDate(currentPeriod2, 'MM/dd/yyyy', this.locale),
        showDate: 'Current Period ' + formatDate(currentPeriod, '(MMM dd', this.locale) + ' - ' +
          formatDate(currentPeriod2, 'MMM dd)', this.locale)
      },
      {
        date: formatDate(priorPeriod, 'MM/dd/yyyy', this.locale) + '-' +
          formatDate(priorPeriod2, 'MM/dd/yyyy', this.locale),
        showDate: 'Prior Period ' + formatDate(priorPeriod, '(MMM dd', this.locale) + ' - ' +
          formatDate(priorPeriod2, 'MMM dd)', this.locale)
      },
      {
        date: 'Custom date range',
        showDate: 'Custom date range'
      }

    ];
  }
  RunDateTimePicker(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('.datetimepicker').datetimepicker({
              format: 'MM/DD/YYYY',
              keepOpen:false,
              stepping: 5,
              //sideBySide:true
             // debug: true
            });
          });
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
  DestroyDateTimePicker(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('.datetimepicker').data("DateTimePicker").destroy();
          });
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
  ChangeDateType(value: string): void {
    if (value === 'Custom date range') {
      this.RunDateTimePicker();
      this.showCustomDateRange = true;
    } else {
      this.DestroyDateTimePicker();
      this.showCustomDateRange = false;
    }
  }

  RunReport(): void {
    this.spinner.showSpinner();
    this.registerForm.controls.access_Token.setValue(localStorage.getItem('access_token'));
    if (localStorage.getItem('access_token') === '') {
      this.toastr.warning('some thing went wrong');
      return;
    }
    this.repo.create('api/Report/GetEmployeePayrollHoursReport', this.registerForm.value)
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.employeePayrollHoursList = res['data'];
            this.employeeTotalFullDays = 0;
            this.employeeTotalFullDayHoliday = 0;
            this.employeeTotalRegular = 0;
            this.employeeTotalRegularHoliday = 0;
            this.employeeTotalRegularHolidayOT = 0;
            this.employeeTotalOT = 0;
            this.employeeStartDate = '';
            this.employeeEndDate = '';
            if (this.employeePayrollHoursList !== undefined && this.employeePayrollHoursList.length > 0) {
              this.employeePayrollHoursList.forEach(ele => {
                this.employeeTotalFullDayHoliday = this.employeeTotalFullDayHoliday + ele.totalFullDayHoliday;
                this.employeeTotalFullDays = this.employeeTotalFullDays + ele.totalFullDay;
                this.employeeTotalRegular = this.employeeTotalRegular + ele.totalRegular;
                this.employeeTotalRegularHoliday = this.employeeTotalRegularHoliday + ele.totalHoliday;
                this.employeeTotalRegularHolidayOT = this.employeeTotalRegularHolidayOT + ele.totalHolidayOT;
                this.employeeTotalOT = this.employeeTotalOT + ele.totalOT;
                this.employeeStartDate = ele.startDate;
                this.employeeEndDate = ele.endDate;
              });
            }
          }
          else if (res['returnStatus'] == false && res['returnMessage'][0] == 'Unauthorized') {
            // this.timesheet.GetToken();
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.spinner.hideSpinner();
          if (err.error.returnMessage[0] === 'Unauthorized') {
            //  this.timesheet.GetToken();
          } else {
            this.toastr.error('Something went worng');
          }
          // this.timesheet.GetToken();
        }
      });
  }

  GeneratePayrollCSV(t: any): void {
    this.spinner.showSpinner();
    this.registerForm.controls.access_Token.setValue(localStorage.getItem('access_token'));
    if (localStorage.getItem('access_token') === '') {
      this.toastr.warning('some thing went wrong');
      return;
    }
    this.repo.create('api/Report/GetPayrollHoursReportAsCSV', this.registerForm.value)
      .subscribe({
        next: res => {
          // if (res['returnStatus'] == true) {
          // }
          if(res['returnMessage'][1] != undefined){
            this.conflictNames = (String)(res['returnMessage'][1]).split(',');
            this.displayStyle = 'block';
          }
          this.downloadExcel(res['data']['file']);
          if (res['returnStatus'] == false && res['returnMessage'][0] == 'Unauthorized') {
            //   this.timesheet.GetToken();
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.downloadExcel(err.error.text);
          this.spinner.hideSpinner();
          if (err.error.returnMessage[0] === 'Unauthorized') {
            //   this.timesheet.GetToken();
          } else {
            this.toastr.error(err.error.returnMessage);
          }
          // this.timesheet.GetToken();
        }
      });
  }
  downloadExcel(buffer: any): void {
    if (buffer === undefined) {
      return;
    }
    const data: Blob = new Blob([atob(buffer)], { type: 'text/csv' });
    FileSaver.saveAs(data, 'EPIIY7AA.csv');
    // let startDate: string;
    // let endDate: string;
    // if (this.f.definedDate.value === 'Custom date range') {
    //   startDate = this.f.startDate.value;
    //   endDate = this.f.endDate.value;
    // }
    // else {
    //   startDate = (String)(this.f.definedDate.value).split('-')[0];
    //   endDate = (String)(this.f.definedDate.value).split('-')[1];
    // }
    // FileSaver.saveAs(data, 'ADP_Payroll_' + startDate.replace('/', '') + '_' + endDate.replace('/', '') + '.csv');
  }
  ClosePopup(): void {
    this.displayStyle = 'none';
  }
}


