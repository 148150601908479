import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from '../../shared/services/repository.service';
import { AuthService } from 'src/app/auth/auth.service';
import { first } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  returnUrl: string;
  constructor(public router: Router, public authService: AuthService, private formBuilder: FormBuilder, private repo: RepositoryService,
    private route: ActivatedRoute, private toastr: ToastrService, private spinner: SpinnerService) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    if (this.authService.isLoggedIn.value) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      this.router.navigate([this.returnUrl]);
    }
  }
  get f() { return this.registerForm.controls; }

  onSubmit(): void {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.spinner.showSpinner();
    this.repo.create('api/User/forgotpassword', this.registerForm.value)
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.toastr.success(res['returnMessage'][0]);
            this.registerForm.reset();
            this.submitted = false;
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.spinner.hideSpinner();
          this.toastr.error(err.error.returnMessage);
        }
      });

  }

}
