import { DOCUMENT, formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { HolidayConfiguration } from 'src/app/model/holidayconfiguration.model';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { UserStatus } from '../../enum/userstatus.enum';
import { WeekDays } from '../../enum/weekdays.enum';

@Component({
  selector: 'app-holidayconfiguration',
  templateUrl: './holidayconfiguration.component.html',
  styleUrls: ['./holidayconfiguration.component.css']
})
export class HolidayconfigurationComponent implements OnInit {

  holidayConfigurationList: HolidayConfiguration[] = [];
  BreakException = {};
  userStatus = UserStatus;
  weekDays = WeekDays;
  displayStyle = 'none';
  UpdateDate: any;
  UpdateDayOfWeek: any;
  updateModel: { id: number, date: any, dateOfWeek: string } = {
    id: 0,
    date: '',
    dateOfWeek: ''
  };
  constructor(private auth: AuthService, private toastr: ToastrService, private spinner: SpinnerService,
    private repo: RepositoryService, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(LOCALE_ID) private locale: string) { }


  ngOnInit(): void {
    this.GetAllHolidayConfiguration();
    this.RunDateTimePicker();
  }

  OpenPopup(id: number, date: string, dateofWeek: string): void {
    this.updateModel.date = formatDate(date, 'MM/dd/yyyy', this.locale);
    this.updateModel.dateOfWeek = dateofWeek;
    this.updateModel.id = id;
    this.displayStyle = 'block';
  }
  ClosePopup(): void {
    this.displayStyle = 'none';
  }

  GetAllHolidayConfiguration(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/HolidayConfiguration/GetAll')
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.holidayConfigurationList = data['data'];
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  Update(): void {
    if (this.updateModel.id === 0 ||
       (document.getElementById('datetimepicker') as HTMLInputElement).value === '' ||
       this.updateModel.dateOfWeek === '') {
      this.toastr.error('Date Or DayOfWeek must required');
    }
    this.updateModel.date = formatDate((document.getElementById('datetimepicker') as HTMLInputElement).value, 'MM/dd/yyyy', this.locale);
    this.repo.update('api/HolidayConfiguration/Update', this.updateModel.id, this.updateModel)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.holidayConfigurationList.forEach((ele, i) => {
              if (ele.id === this.updateModel.id) {
                ele.date = this.updateModel.date;
                ele.dateOfWeek = this.updateModel.dateOfWeek;
                this.toastr.success(data['returnMessage'][0]);
                this.ClosePopup();
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  onFileChanged(event): void {
    this.spinner.showSpinner();

    const selectedFile: File = (event.target.files[0] as File);
    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);
    this.repo.file('api/HolidayConfiguration/ImportHolidays', formData)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.holidayConfigurationList = data['data'];
            this.toastr.success(data['returnMessage'][0]);
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0]);
        });
  }

  Delete(index: number, id: number): void {
    this.repo.delete('api/HolidayConfiguration/Delete', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.holidayConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.holidayConfigurationList.splice(index, 1);
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.toastr.error(error.error.returnMessage[0]);
        });
  }
  ChangeStatus(status: UserStatus, id: number): void {
    this.repo.getData('api/HolidayConfiguration/Disable', id)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.holidayConfigurationList.forEach((ele, i) => {
              if (ele.id === id) {
                this.holidayConfigurationList[i].status = (status === this.userStatus.Disabled ?
                  this.userStatus.Active : this.userStatus.Disabled);
                this.toastr.success(data['returnMessage'][0]);
                throw this.BreakException;
              }
            });
          }
        },
        error => {
          this.toastr.error(error.error.returnMessage[0]);
        });

  }
  RunDateTimePicker(): void {
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          $(function () {
            $('.datetimepicker').datetimepicker({
              format: 'MM/DD/YYYY',
              keepOpen:false,
             // stepping: 5,
              //sideBySide:true
             // debug: true
            });
          });
        }
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
}

