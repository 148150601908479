<div class="row">
  <div class="col-md-10 offset-md-1">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <h5 style="float: left;padding: .75rem 1.25rem">Scheduler</h5>

        <!-- <button *ngIf="data.excelData.length > 0" (click)="ClearExcelData()" style="float: right; margin-top: 5px;" type="button" class="btn btn-outline-danger mr-1">
          <i class="fa fa-trash"></i> New Scheduler</button> -->
        <!-- <button *ngIf="data.excelData.length > 0" (click)="DownloadExcel()" style="    float: right; margin-top: 5px;" type="button" class="btn btn-custom mr-1">
          <i class="fa fa-file-excel-o" aria-hidden="true"></i> Download Schedule Excel</button> -->
      </div>
      <div class="card-body">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <!-- <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>Select Type</label>
            </div>
            <div class="form-group col-md-6">
              <select formControlName="type" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                <option value="" selected>Add Shift</option>
                <option value="1">First Time</option>
                <option value="2">Second Time</option>
                <option value="3">Third Time</option>

              </select>
              <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                <div *ngIf="f.type.errors.required">Type is required</div>
              </div>
            </div>
          </div> -->

          <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>Start Date/Time</label>
            </div>
            <div class="form-group col-md-3">
              <!-- <input id=""
                     class="form-control"
                     type="text"
                /> -->
                <input id="datetimepicker1" autocomplete="off" placeholder="Select Start Date" type="text"   formControlName="starttime" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.starttime.errors }" />
              <div *ngIf="submitted && f.starttime.errors" class="invalid-feedback">
                <div *ngIf="f.starttime.errors.required">Start Time is required</div>
                <div *ngIf="f.starttime.errors.pattern">Start Time must be a valid date in the format YYYY-MM-DD</div>
              </div>
              <!-- <div class="input-group date" data-target-input="nearest" NgTempusdominusBootstrap> -->

                <!-- <input type="text" (options)="{format: 'DD.MM.YYYY', locale: 'fr'}"
                NgTempusdominusBootstrapInput   formControlName="starttime" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.starttime.errors }" />
                <div *ngIf="submitted && f.starttime.errors" class="invalid-feedback">
                  <div *ngIf="f.starttime.errors.required">Start Time is required</div>
                  <div *ngIf="f.starttime.errors.pattern">Start Time must be a valid date in the format YYYY-MM-DD</div>
                </div>
              </div> -->
              <!-- <div class="input-group-append" NgTempusdominusBootstrapToggle>
                <div class="input-group-text"><i class="fa fa-calendar"></i></div>
            </div> -->
            <!-- </div> -->
            <!-- <div class="input-group date" data-target-input="nearest" NgTempusdominusBootstrap> -->
                <!-- (options)="options" -->
                <!-- NgTempusdominusBootstrapInput -->
              <!-- <div class="input-group-append" NgTempusdominusBootstrapToggle>
                  <div class="input-group-text"><i class="fa fa-calendar"></i></div>
              </div>
          </div> -->
            </div>

            <div class="form-group col-md-3">
              <input autocomplete="off" type="text" placeholder="Select End Date"  formControlName="endtime" class="form-control" id="datetimepicker2"
                [ngClass]="{ 'is-invalid': submitted && f.endtime.errors }" />
              <div *ngIf="submitted && f.endtime.errors" class="invalid-feedback">
                <div *ngIf="f.endtime.errors.required">End Time is required</div>
                <div *ngIf="f.endtime.errors.pattern">End Time must be a valid date in the format YYYY-MM-DD</div>
              </div>
            </div>
          </div>

          <!-- <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>Punch Reminder</label>
            </div>
            <div style="margin-left: 22px;" class="form-group col-md-3">
              <input type="checkbox" formControlName="punchIn" class="form-check-input"
                [ngClass]="{ 'is-invalid': submitted && f.punchIn.errors }" /> punch in
            </div>
            <div class="form-group col-md-3">
              <input value="punch out" title="punch out" type="checkbox" formControlName="punchOut"
                class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.punchOut.errors }" /> punch out

            </div>
          </div> -->

          <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>Clients</label>
            </div>
            <div class="form-group col-md-6">
              <select formControlName="client" id="client" class="form-control js-example-basic-single"
                [ngClass]="{ 'is-invalid': submitted && f.client.errors }">

                <option value="" selected>Select Client</option>
                <option *ngFor="let job of jobList" value="{{job.job_number}}-{{job.job_name}}">
                  {{job.job_name}} - {{job.job_number}}
                </option>

              </select>
              <div *ngIf="submitted && f.client.errors" class="invalid-feedback">
                <div *ngIf="f.client.errors.required">client is required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>Employee</label>
            </div>
            <div class="form-group col-md-6">
              <select formControlName="employee" id="employee" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.employee.errors }">

                <option value="" selected>Select Employee</option>
                <option *ngFor="let emp of employeeList" value="{{emp.employee_number}}-{{emp.employee_last_name}} {{emp.employee_first_name}}-{{emp.employee_phone}}">
                  {{emp.employee_first_name}} {{emp.employee_last_name}} - {{emp.employee_number}}
                </option>

              </select>
              <div *ngIf="submitted && f.employee.errors" class="invalid-feedback">
                <div *ngIf="f.employee.errors.required">Employee is required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>Services</label>
            </div>
            <div class="form-group col-md-6">
              <select formControlName="service" id="service" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.service.errors }">

                <option value="" selected>Select Service</option>
                <option *ngFor="let task of taskList" value="{{task.task_number}}-{{task.task_name}}">
                  {{task.task_name}} - {{task.task_number}}
                </option>

              </select>
              <div *ngIf="submitted && f.service.errors" class="invalid-feedback">
                <div *ngIf="f.service.errors.required">Service is required</div>
              </div>
            </div>
          </div>

          <div class="form-row row">
            <div class="form-group offset-md-2 col-md-2">
              <label>Instruction <span style="font-size: 10px;">(optional)</span> </label>
            </div>
            <div class="form-group col-md-6">
              <textarea rows="2" formControlName="eventInfo" class="form-control" type="texarea"
                placeholder="Enter Event Instruction" [ngClass]="{ 'is-invalid': submitted && f.eventInfo.errors }"></textarea>
            </div>
          </div>
          <div class="form-row row">
            <div class="form-group col-md-10">
              <div class="text-right">
                <button style="margin-right: 10px" class="btn btn-outline-danger" type="reset" (click)="onReset()">
                  <i class="fa fa-remove"></i>
                  Cancel</button>
                <button class="btn btn-custom mr-1" style="width: 90px;">
                  <i class="fa fa-plus"></i> Add</button>

                  <!-- <button (click)="DownloadExcel()" type="button" class="btn btn btn-success mr-1">
                    <i class="fa fa-download"></i> Download Schedule Excel</button> -->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "fire" [fullScreen] = "true">
  <p style="color: white" > Loading... </p>
</ngx-spinner>
