import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './layout/footer.component';
import { HeaderComponent } from './layout/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReviewexcelsheetComponent } from './reviewexcelsheet/reviewexcelsheet.component';
import { Time24to12Pipe } from './shared/pipes/time24to12.pipe';
import { TimeAgoPipe } from './shared/pipes/timeago.pipe';
import { LoginComponent } from './user/login/login.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { CreateuserComponent } from './user/createuser/createuser.component';
import { UserlistComponent } from './user/userlist/userlist.component';
import { HolidayconfigurationComponent } from './setting/holidayconfiguration/holidayconfiguration.component';
import { EmployeetimesheetComponent } from './reports/employeetimesheet/employeetimesheet.component';
import { EmployeehourslistComponent } from './shared/subcomponents/employeehourslist/employeehourslist.component';
import { SettingComponent } from './setting/setting/setting.component';
import { ShiftrequestconfigurationComponent } from './setting/shiftrequestconfiguration/shiftrequestconfiguration.component';
import { FacilitylinkgeneratorComponent } from './setting/facilitylinkgenerator/facilitylinkgenerator.component';
import { PayrollemployeetimesheetComponent } from './reports/payrollemployeetimesheet/payrollemployeetimesheet.component';
import { PayrollemployeehourlistComponent } from './shared/subcomponents/payrollemployeehourlist/payrollemployeehourlist.component';
import { EmployeefileconfigurationComponent } from './setting/employeefileconfiguration/employeefileconfiguration.component';
import { GettokenComponent } from './gettoken/gettoken.component';
import { ForgotpasswordComponent } from './user/forgotpassword/forgotpassword.component';
import { DisableControlDirective } from './shared/directives/disabledcontrol.directive';
import { QuickbookconfigurationComponent } from './setting/quickbookconfiguration/quickbookconfiguration.component';
import { EventconfigurationComponent } from './setting/eventconfiguration/eventconfiguration.component';
import { ChecklistconfigurationComponent } from './setting/checklistconfiguration/checklistconfiguration.component';
import { ChecklistComponent } from './reports/checklist/checklist.component';
import { JobconfigurationComponent } from './setting/jobconfiguration/jobconfiguration.component';
import { JoblocationconfigurationComponent } from './setting/joblocationconfiguration/joblocationconfiguration.component';
import { JobapplicantComponent } from './jobapplicant/jobapplicant.component';
import { ServiceconfigurationComponent } from './setting/serviceconfiguration/serviceconfiguration.component';
import { ClientbillingconfigurationComponent } from './setting/clientbillingconfiguration/clientbillingconfiguration.component';
import { ChangerequestComponent } from './changerequest/changerequest.component';
import { VaemployeetimesheetComponent } from './reports/vaemployeetimesheet/vaemployeetimesheet.component';
import { VaclientemployeehourslistComponent } from './shared/subcomponents/vaclientemployeehourslist/vaclientemployeehourslist.component';
import { PmclientemployeeestComponent } from './reports/pmclientemployeeest/pmclientemployeeest.component';
import { MedicationformComponent } from './nurse/medicationform/medicationform.component';
import { InitialnursingevaluationComponent } from './reports/initialnursingevaluation/initialnursingevaluation.component';
import { NgxMaskModule } from 'ngx-mask';
import { ChecklistformComponent } from './nurse/checklistform/checklistform.component';
import { AideevaluationComponent } from './nurse/aideevaluation/aideevaluation.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    ReviewexcelsheetComponent,
    Time24to12Pipe,
    TimeAgoPipe,
    LoginComponent,
    CreateuserComponent,
    UserlistComponent,
    HolidayconfigurationComponent,
    EmployeetimesheetComponent,
    EmployeehourslistComponent,
    SettingComponent,
    ShiftrequestconfigurationComponent,
    FacilitylinkgeneratorComponent,
    PayrollemployeetimesheetComponent,
    PayrollemployeehourlistComponent,
    EmployeefileconfigurationComponent,
    GettokenComponent,
    ForgotpasswordComponent,
    QuickbookconfigurationComponent,
    DisableControlDirective,
    EventconfigurationComponent,
    ChecklistconfigurationComponent,
    ChecklistComponent,
    JobconfigurationComponent,
    JoblocationconfigurationComponent,
    JobapplicantComponent,
    ServiceconfigurationComponent,
    ClientbillingconfigurationComponent,
    ChangerequestComponent,
    VaemployeetimesheetComponent,
    VaclientemployeehourslistComponent,
    PmclientemployeeestComponent,
    MedicationformComponent,
    InitialnursingevaluationComponent,
    ChecklistformComponent,
    AideevaluationComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(), // ToastrModule added
    NgxSpinnerModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthService,
    AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
