import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time24to12Transform'
})
export class Time24to12Pipe implements PipeTransform {

  transform(time: any): any {


    var time24To12 = function (a: string) {
      var tim = new Date("1955-11-05T" + a.split(" ")[1] + "Z").toLocaleTimeString("bestfit", {
        timeZone: "UTC",
        hour12: !0,
        hour: "numeric",
        minute: "numeric"
      });
      return a.split(" ")[0] +" "+ tim;
    };
    return time24To12(time);
  }
}
