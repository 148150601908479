import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { CheckListConfigurationByCategory } from 'src/app/model/checklistconfiguration.model';
import { RepositoryService } from '../../shared/services/repository.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { ScrolltoviewService } from '../../shared/services/scrolltoview.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-checklistform',
  templateUrl: './checklistform.component.html',
  styleUrls: ['./checklistform.component.css']
})
export class ChecklistformComponent implements OnInit {
  submitted = false;
  registerForm: FormGroup;
  checkListCategories: CheckListConfigurationByCategory[];
  evaluatedClients: any;
  evaluatedClient: any;
  constructor(public authService: AuthService,private formBuilder: FormBuilder, private toastr: ToastrService, private spinner: SpinnerService,
    private scroll: ScrolltoviewService, private repo: RepositoryService, private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {
    this.GetNewClientsEvaluatedByNurse();
    this.GetChecklistByCategory();
    this.registerForm = this.formBuilder.group({
      homeClientProfileId: ['', [Validators.required]],
      agencyAdministratorName: ['', [Validators.required]],
      individualParticipatingCarePlan: ['', [Validators.required]],
      initiatedDate: ['', [Validators.required]],
      totalHours: ['', [Validators.required]],
      requestedBy: [this.authService.userName.value, [Validators.required]],
      requestChangeDate: ['', [Validators.required]],
      requestChangeInPlan: ['', [Validators.required]],
      suspensionAddtion: ['',Validators.maxLength(400)],
      comments: ['',Validators.maxLength(200)],
      weekdays: ['', [Validators.required]],
      checklistIds: this.formBuilder.array([]),
      InitialClientChecklistWeekdays: this.formBuilder.group({
        monday: [''],
        tuesday: [''],
        wednesday: [''],
        thursday: [''],
        friday: [''],
        saturday: [''],
        sunday: ['']
      })
    });
  }
  get f() { return this.registerForm.controls; }
  get week() { return (<FormGroup>this.registerForm.controls.InitialClientChecklistWeekdays).controls; }

  GetNewClientsEvaluatedByNurse(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/InitialNursingClientEvaluation/GetAllClients')
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.evaluatedClients = res['data'];
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.spinner.hideSpinner();
        }
      });
  }
  GetNewClientDetailById(val: number): void {
    if (val == undefined)
      alert('Client is required');
    this.evaluatedClient = this.evaluatedClients.find(x => x.id == val);

  }
  GetChecklistByCategory(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/CheckListConfiguration/GetAllByCategory')
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.checkListCategories = res['data'];
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.spinner.hideSpinner();
        }
      });
  }
  onSubmit() {
    this.submitted = true;
    this.UpdateWeekDays();
    this.scroll.scrollToElement(document.getElementsByClassName("invalid-feedback")[0]);
    if (this.registerForm.invalid) {
      return;
    }
    if ((this.f.checklistIds as FormArray).length === 0) {
      this.toastr.error('At least 1 check list is required');
      return;
    }

    this.spinner.showSpinner();
    this.repo.create('api/InitialClientChecklistEvaluation/Insert', this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data['returnStatus'] == true) {
            this.toastr.success(data['returnMessage'][0], 'Success');
            this.onReset();
          }
          else {
            this.toastr.error(data['returnMessage'][0], 'Error');
          }
          this.spinner.hideSpinner();
        },
        error => {
          this.spinner.hideSpinner();
          this.toastr.error(error.error.returnMessage[0], 'Error');
        });
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.UnCheckAllCheckList();
    this.registerForm.get('homeClientProfileId').setValue('');
    this.evaluatedClient = null;
    this.scroll.scrollToElement(document.getElementsByClassName("target")[0]);
  }
  UpdateWeekDays() {
    if ((<HTMLInputElement>document.getElementById('inlineCheckbox1')) .checked)
      this.week.monday.setValue(true)
    else
      this.week.monday.setValue(false);
    if ((<HTMLInputElement>document.getElementById('inlineCheckbox2')).checked)
      this.week.tuesday.setValue(true)
    else
      this.week.tuesday.setValue(false);
    if ((<HTMLInputElement>document.getElementById('inlineCheckbox3')).checked)
      this.week.wednesday.setValue((true))
    else
      this.week.wednesday.setValue(false);
    if ((<HTMLInputElement>document.getElementById('inlineCheckbox4')).checked)
      this.week.thursday.setValue(true)
    else
      this.week.thursday.setValue(false);
    if ((<HTMLInputElement>document.getElementById('inlineCheckbox5')).checked)
      this.week.friday.setValue(true)
    else
      this.week.friday.setValue(false);
    if ((<HTMLInputElement>document.getElementById('inlineCheckbox6')).checked)
      this.week.saturday.setValue(true)
    else
      this.week.saturday.setValue(false);
    if ((<HTMLInputElement>document.getElementById('inlineCheckbox7')).checked)
      this.week.sunday.setValue(true)
    else
      this.week.sunday.setValue(false);
  }
  UpdateCheckListChange(id: number, isChecked: boolean) {
    const checklistArray = <FormArray>this.registerForm.controls.checklistIds;

    if (isChecked) {
      checklistArray.push(new FormControl(id));
    } else {
      let index = checklistArray.controls.findIndex(x => x.value == id)
      checklistArray.removeAt(index);
    }
  }
  public UnCheckAllCheckList() {
    this.f.checklistIds = this.formBuilder.array([]);
    const script = this._renderer2.createElement('script');
    script.text = `
        {
          document.getElementsByName('checklistCheckbox').forEach(x=>x.checked = false)
        }
    `;
    this._renderer2.appendChild(this._document.body, script);
  }
}
