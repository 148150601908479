import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeSheetTokenService {
  public getTokenURL: string = 'https://admin.timesheetmobile.com/gentleshep/oauth/connect.php?' +
  'client_id=gentleshep&' +
  // 'client_secret=871f76b4d75b166238efa1441cf344ec4d1c4fcc&' +
  'redirect_uri=https://gsctools.com&' +
  // 'redirect_uri=http://localhost:4200&' +
  'response_type=code&' +
  'state=GSLV';
  constructor() {

  }
  public GetToken(): void {
    if (localStorage.getItem('access_token') == null) {
      window.location.href = this.getTokenURL;
    }
    this.ClearLocalStorage();
  }

  ClearLocalStorage(): void {
    if (localStorage.getItem('access_token') != null) {
      localStorage.removeItem('access_token');
    }
    if (localStorage.getItem('auth-code') != null) {
      localStorage.removeItem('auth-code');
    }
    if (localStorage.getItem('refresh_token') != null) {

      localStorage.removeItem('refresh_token');
    }
  }
}
