<div class="row" style="margin-top: 20px;">
  <div class="col-md-10 col-sm-12 offset-md-1">
    <p class="print-only" style="display: none;">Billing Period: {{employeeStartDate}} - {{employeeEndDate}}</p>
    <div *ngIf="employeehours && employeehours.length > 1" class="row">
      <div class="col-3 text-bold">Total Sites: {{employeehours.length}} </div>
      <div class="col-3 text-bold">Total Hours: {{(totalRegular + totalHomeCare + totalFullDay + totalOT + totalHoliday
        + totalHolidayOT)}}</div>
      <div class="col-3 text-bold">Total Facility Regular: {{totalRegular}}</div>
      <div class="col-3 text-bold">Total OT: {{totalOT}}</div>
      <div class="col-3 text-bold">Total Live In: {{totalFullDay}}</div>
      <div class="col-3 text-bold">Total Home Care: {{totalHomeCare}}</div>
      <div class="col-3 text-bold">Total Holiday: {{totalHoliday}}</div>
      <div class="col-3 text-bold">Total Holiday OT: {{totalHolidayOT}}</div>
    </div>
    <ng-container *ngFor="let employees of employeehours; index as j">
      <!-- *ngIf="employeehours.siteName != null && employeehours.subTotal.length > 0; then thenTemplate; else elseTemplate"> -->
      <!-- </ng-container> -->
      <!-- <ng-template #thenTemplate> -->
      <div *ngIf="employees.siteName != null && employees.subTotal.length > 0" class="accordion mt10"
        id="accordionExample1">
        <div class="card">
          <div class="card-header" [id]="'heading' + j">
            <h2 class="mb-0">
              <a role="button" class="btn btn-link btn-block text-left" data-toggle="collapse" data-parent="#accordion"
                [href]="'#collapse' + j" aria-expanded="true" [attr.aria-controls]="'collapse' + j">
                <div class="row">
                  <div class="col-md-4">
                    <p class="text-bold btn-color-black">{{employees.siteName}}</p>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-2">
                        <p class="text-bold btn-color-black">Total:
                          {{employees.totalHours + employees.totalHolidayOT}}
                        </p>
                      </div>
                      <div class="col-md-3" style="border-left: 1px solid #222;">
                        <p class="text-bold btn-color-black">Regular:
                          <!-- <span style="font-size: 10px;">(Excluded OT)</span> -->
                          {{employees.totalHours - employees.totalOT - employees.totalHoliday}}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <!-- <p class="text-center text-bold">Total WeekDays OT: {{employeehours.totalWeekDayOT}}</p> -->
                        <p class="text-bold btn-color-black">Over Time: {{employees.totalOT -
                          employees.totalHolidayOT}}</p>
                      </div>
                      <div class="col-md-2">
                        <p class="text-bold btn-color-black">Holiday: {{employees.totalHoliday}}</p>
                      </div>
                      <div *ngIf="employees.totalHolidayOT" class="col-md-2">
                        <p class="text-bold btn-color-black">Holiday OT: {{employees.totalHolidayOT}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </a>
            </h2>
          </div>
          <div [id]="'collapse' + j" [ngClass]="{'show': employeehours.length === 1,'hide': employeehours.length > 1}"
            class="collapse" [attr.aria-labelledby]="'heading' + j" data-parent="#accordionExample1">
            <div class="card-body">
              <ng-container *ngFor="let subTotal of employees.subTotal; index as i">
                <div class="row mt5">
                  <div class="col-md-12">
                    <div class="accordion" id="accordionExample">
                      <div class="card">
                        <div class="card-header" [id]="'heading' + i">
                          <h2 class="mb-0">
                            <a role="button" class="btn-link btn-color-black" data-toggle="collapse"
                              data-parent="#accordion" [href]="'#collapse' + i" aria-expanded="true"
                              [attr.aria-controls]="'collapse' + i">
                              <div class="row">
                                <div class="col-md-2">
                                  <h5 class="text-bold">{{subTotal.roleName}}</h5>
                                </div>
                                <div class="col-md-2">
                                  <p class="font14 text-bold ">Regular Hours: {{subTotal.subTotalHours -
                                    subTotal.subTotalOT - subTotal.subTotalHoliday}}</p>
                                </div>
                                <div class="col-md-2">
                                  <p class="font14 text-bold">Overtime Hours: {{subTotal.subTotalOT -
                                    subTotal.subTotalHolidayOT}}</p>
                                </div>
                                <div class="col-md-2">
                                  <p class="font14 text-bold">Holiday Hours: {{subTotal.subTotalHoliday}}</p>
                                </div>
                                <div
                                  *ngIf="(subTotal.roleName != 'Live In' && subTotal.roleName != 'Home Care Services' && subTotal.roleName != 'VA Home Care Services')"
                                  class="col-md-2">
                                  <p class="font14 text-bold">Holiday OT: {{subTotal.subTotalHolidayOT}}</p>
                                </div>
                              </div>
                            </a>
                          </h2>
                        </div>
                        <div data-parent="#accordionExample" [id]="'collapsee' + i" class="collapse show"
                          [attr.aria-labelledby]="'headingg' + i">
                          <div class="card-body">
                            <table class="table" style="font-size: 14px;">
                              <thead class="">
                                <tr>
                                  <th>Employee Name</th>
                                  <ng-container
                                    *ngIf="(subTotal.roleName != 'Live In' && subTotal.roleName != 'Home Care Services' && subTotal.roleName != 'VA Home Care Services'); else elseTemplate1">
                                    <th>WeekDay</th>
                                    <th>WeekDay OT</th>
                                    <th>Weekend</th>
                                    <th>Weekend OT</th>
                                  </ng-container>
                                  <ng-template #elseTemplate1>
                                    <th>Home Care</th>
                                    <th>Live In</th>
                                  </ng-template>
                                  <th>Holiday</th>
                                  <ng-container
                                    *ngIf="(subTotal.roleName != 'Live In' && subTotal.roleName != 'Home Care Services' && subTotal.roleName != 'VA Home Care Services')">
                                    <th>Holiday OT</th>
                                  </ng-container>
                                  <th style="border-right: 1px solid #dee2e6;">Cancel</th>
                                  <th>Regular</th>
                                  <th>Over Time</th>
                                  <th style="border-left: 1px solid #dee2e6;">Total Hours</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let data of subTotal.data">
                                  <td>{{data.employeeName}}</td>
                                  <ng-container
                                    *ngIf="(subTotal.roleName != 'Live In' && subTotal.roleName != 'Home Care Services' && subTotal.roleName != 'VA Home Care Services'); else elseTemplate2">
                                    <td>{{data.weekDay}}</td>
                                    <td>{{data.weekDayOT}}</td>
                                    <td>{{data.weekend}}</td>
                                    <td>{{data.weekendOT}}</td>
                                  </ng-container>
                                  <ng-template #elseTemplate2>
                                    <td>{{data.homeCare}}</td>
                                    <td>{{data.fullDay}}</td>
                                  </ng-template>
                                  <td>{{data.holiday}}</td>
                                  <ng-container
                                    *ngIf="(subTotal.roleName != 'Live In' && subTotal.roleName != 'Home Care Services' && subTotal.roleName != 'VA Home Care Services')">
                                    <td>{{data.holidayOT}}</td>
                                  </ng-container>
                                  <td style="border-right: 1px solid #dee2e6;">{{data.cancel}}</td>
                                  <td>{{data.hours - data.ot}}</td>
                                  <td>{{data.ot}}</td>
                                  <td style="border-left: 1px solid #dee2e6;">{{data.hours}}</td>
                                </tr>
                                <tr class="colorRed" style="font-weight: bold; border-top: 5px solid #222;">
                                  <td></td>
                                  <ng-container
                                    *ngIf="(subTotal.roleName != 'Live In' && subTotal.roleName != 'Home Care Services' && subTotal.roleName != 'VA Home Care Services'); else elseTemplate3">
                                    <td>{{subTotal.subTotalWeekDay}}</td>
                                    <td>{{subTotal.subTotalWeekDayOT}}</td>
                                    <td>{{subTotal.subTotalWeekend}}</td>
                                    <td>{{subTotal.subTotalWeekendOT}}</td>
                                  </ng-container>
                                  <ng-template #elseTemplate3>
                                    <td>{{subTotal.subTotalHomeCare}}</td>
                                    <td>{{subTotal.subTotalFullDay}}</td>
                                  </ng-template>
                                  <td>{{subTotal.subTotalHoliday}}</td>
                                  <ng-container
                                    *ngIf="(subTotal.roleName != 'Live In' && subTotal.roleName != 'Home Care Services' && subTotal.roleName != 'VA Home Care Services')">
                                    <td>{{subTotal.subTotalHolidayOT}}</td>
                                  </ng-container>
                                  <td style="border-right: 1px solid #dee2e6;">{{subTotal.subTotalCancel}}</td>
                                  <td>{{subTotal.subTotalHours - subTotal.subTotalOT}}</td>
                                  <td>{{subTotal.subTotalOT}}</td>
                                  <td style="border-left: 1px solid #dee2e6;">{{subTotal.subTotalHours}}</td>
                                </tr>
                                <!-- <tr style="font-weight: bold;">
                           <td>Total WeekDays</td>
                           <td colspan="8" >{{employeehours.totalWeekday}}</td>
                           <tr style="font-weight: bold;">
                          <tr>
                           <td>Total OT</td>
                           <td colspan="8">{{employeehours.totalOT}}</td>
                         </tr> -->
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="clientBillingConfigurations">
      <h6 style="padding-top: 30px;font-weight: bold;">Providence Meadows Clients
        ({{clientBillingConfigurations.length}})</h6>
    </ng-container>

    <ng-container *ngFor="let clients of clientBillingConfigurations; index as j">
      <div class="accordion mt10" id="accordionExample1">
        <div class="card">
          <div class="card-header" [id]="'heading' + (j + employeehours.length)">
            <h2 class="mb-0">
              <a role="button" class="btn btn-link btn-block text-left" data-toggle="collapse" data-parent="#accordion"
                [href]="'#collapse' + (j + employeehours.length)" aria-expanded="true"
                [attr.aria-controls]="'collapse' + (j + employeehours.length)">
                <div class="row">
                  <div style="margin-bottom: -13px" class="col-md-4">
                    <p class="text-bold btn-color-black">{{clients.client}}</p>
                  </div>
                  <div style="margin-bottom: -13px" class="col-md-4">
                    <p class="text-bold btn-color-black">Room# {{clients.room}}</p>
                  </div>
                  <div *ngIf="clients.status == statusType.Disabled" style="margin-bottom: -13px"
                    class="col-md-4 text-right">
                    <p class="text-bold text-danger">{{clients.status == statusType.Active ? 'Active': 'Suspended' }}
                    </p>
                  </div>
                </div>
              </a>
            </h2>
          </div>
          <div [id]="'collapse' + (j + employeehours.length)"
            [ngClass]="{'show': clientBillingConfigurations.length === 1,'hide': clientBillingConfigurations.length > 1}"
            class="collapse" [attr.aria-labelledby]="'heading' + (j + employeehours.length)"
            data-parent="#accordionExample1">
            <div class="card-body">
              <table class="table" style="font-size: 14px;">
                <thead class="">
                  <tr>
                    <th>Service</th>
                    <th>Service Price</th>
                    <th>Meridiem Type</th>
                    <th>Sunday</th>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednesday</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                    <th>Saturday</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="newclientrow" *ngFor="let data of clients.clientBillingDayConfigurations; index as k">
                    <td>{{data.serviceConfiguration.name}}</td>
                    <td>{{data.serviceConfiguration.price | currency : 'USD' : true : '1.2-2'}}</td>
                    <td>{{GetMeridiemType(data.type)}}</td>
                    <td>
                      <input type="checkbox" [disabled]="data.sunday == 0"
                        (click)="UpdateData($event.target.checked,k,j,'sunday')"
                        [checked]="data.sunday > 0? true: false" />
                      <input *ngIf="data.sunday == 0" (change)="AddNewValue($event,k,j,'sunday')"
                        style="margin-left: 5px;width: 20%;">
                    </td>
                    <td>
                      <input type="checkbox" [disabled]="data.monday == 0"
                        (click)="UpdateData($event.target.checked,k,j,'monday')"
                        [checked]="data.monday > 0? true: false" />
                      <input *ngIf="data.monday == 0" (change)="AddNewValue($event,k,j,'monday')"
                        style="margin-left: 5px;width: 20%;">
                    </td>
                    <td>
                      <input type="checkbox" [disabled]="data.tuesday == 0"
                        (click)="UpdateData($event.target.checked,k,j,'tuesday')"
                        [checked]="data.tuesday > 0? true: false" />
                      <input *ngIf="data.tuesday == 0" (change)="AddNewValue($event,k,j,'tuesday')"
                        style="margin-left: 5px;width: 20%;">
                    </td>
                    <td>
                      <input type="checkbox" [disabled]="data.wednesday == 0"
                        (click)="UpdateData($event.target.checked,k,j,'wednesday')"
                        [checked]="data.wednesday > 0? true: false" />
                      <input *ngIf="data.wednesday == 0" (change)="AddNewValue($event,k,j,'wednesday')"
                        style="margin-left: 5px;width: 20%;">
                    </td>
                    <td>
                      <input type="checkbox" [disabled]="data.thursday == 0"
                        (click)="UpdateData($event.target.checked,k,j,'thursday')"
                        [checked]="data.thursday > 0? true: false" />
                      <input *ngIf="data.thursday == 0" (change)="AddNewValue($event,k,j,'thursday')"
                        style="margin-left: 5px;width: 20%;">
                    </td>
                    <td>
                      <input type="checkbox" [disabled]="data.friday == 0"
                        (click)="UpdateData($event.target.checked,k,j,'friday')"
                        [checked]="data.friday > 0? true: false" />
                      <input *ngIf="data.friday == 0" (change)="AddNewValue($event,k,j,'friday')"
                        style="margin-left: 5px;width: 20%;">
                    </td>
                    <td>
                      <input type="checkbox" [disabled]="data.saturday == 0"
                        (click)="UpdateData($event.target.checked,k,j,'saturday')"
                        [checked]="data.saturday > 0? true: false" />
                      <input *ngIf="data.saturday == 0" (change)="AddNewValue($event,k,j,'saturday')"
                        style="margin-left: 5px;width: 20%;">
                    </td>
                    <!-- <td><img width="16" src="{{data.sunday > 0 ? '../../../../../../assets/lmages/tick.png' : '../../../../../../assets/lmages/cross.png' }}" /> </td>
                    <td><img width="16" src="{{data.monday > 0 ? '../../../../../../assets/lmages/tick.png' : '../../../../../../assets/lmages/cross.png' }}" /> </td>
                    <td><img width="16" src="{{data.tuesday > 0 ? '../../../../../../assets/lmages/tick.png' : '../../../../../../assets/lmages/cross.png' }}" /> </td>
                    <td><img width="16" src="{{data.wednesday > 0 ? '../../../../../../assets/lmages/tick.png' : '../../../../../../assets/lmages/cross.png' }}" /> </td>
                    <td><img width="16" src="{{data.thursday > 0 ? '../../../../../../assets/lmages/tick.png' : '../../../../../../assets/lmages/cross.png' }}" /> </td>
                    <td><img width="16" src="{{data.friday > 0 ? '../../../../../../assets/lmages/tick.png' : '../../../../../../assets/lmages/cross.png' }}" /> </td>
                    <td><img width="16" src="{{data.saturday > 0 ? '../../../../../../assets/lmages/tick.png' : '../../../../../../assets/lmages/cross.png' }}" /> </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- </ng-template> -->
    <!-- <ng-template #elseTemplate>
      <button class="btn btn-link btn-block text-center" type="button" data-toggle="collapse">
        <div class="beautifyBox" style="padding: 50px;">
          <h4>Record Not Found</h4>
        </div>
      </button>
    </ng-template> -->
  </div>
</div>
