import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-facilitylinkgenerator',
  templateUrl: './facilitylinkgenerator.component.html',
  styleUrls: ['./facilitylinkgenerator.component.css']
})
export class FacilitylinkgeneratorComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  communityNameList: any = [];
  facilityLink: string;

  constructor(
    private formBuilder: FormBuilder, private repo: RepositoryService,
    private toastr: ToastrService, private spinner: SpinnerService) {

  }
  ngOnInit(): void {
    this.GetCommunityNames();
    this.registerForm = this.formBuilder.group({
      communityName: ['', [Validators.required]],
    });
  }


  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.copyToClipboard('https://shiftrequest.gsctools.com/?facilityname=' + this.registerForm.controls.communityName.value);
    this.facilityLink = 'https://shiftrequest.gsctools.com/?facilityname=' + this.registerForm.controls.communityName.value;
    this.toastr.success('Facility Link Copied!');
  }

  GetCommunityNames(): void {
    this.spinner.showSpinner();
    this.repo.getData('api/ShiftRequestConfiguration/GetCommunityNames', 0)
      .subscribe({
        next: res => {
          if (res['returnStatus'] == true) {
            this.communityNameList = res['data'];
          }
          this.spinner.hideSpinner();
        },
        error: err => {
          this.toastr.error(err.error.returnMessage[0]);
          this.spinner.hideSpinner();
        }
      });

  }
  copyToClipboard(item): void {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
}


