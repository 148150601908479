<style>
  table thead tr th {
    font-weight: 500 !important;
  }
</style>
<div class="row">
  <div class="col-md-12">
    <div class="card m-3 beautifyBox">
      <div style="background-color: rgba(0,0,0,.03)">
        <h5 style="float: left;padding: .75rem 1.25rem">Users List</h5>
      </div>
      <div class="">
        <table class="table table-striped">
          <thead class="custom-table-header-color">
            <tr>
              <th scope="col">Full Name</th>
              <th scope="col">Email</th>
              <th scope="col">User Name</th>
              <th scope="col">Type</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="usersList.length > 0; else elseBlock">
              <tr *ngFor="let d of usersList; index as i">
                <td>
                  <p class="">{{ d.name }}</p>
                </td>
                <td>
                  <p class="">{{ d.email }}</p>
                </td>
                <td>
                  <p class="">{{ d.userName }}</p>
                </td>
                <td>
                  <p class="text-bold"
                    [ngClass]="{ 'text-primary': d.type == userType.Admin, 'text-warning': d.type == userType.User, 'text-danger': d.type == userType.Nurse }">
                    {{ GetUserType(d.type)}}</p>
                </td>
                <td>
                  <p class="text-bold"
                    [ngClass]="{ 'text-green': d.status == userStatus.Active, 'text-danger': d.status == userStatus.Disabled }">
                    {{ d.status == userStatus.Active? 'Active' : 'Disabled' }}</p>
                </td>
                <td width="10%">
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-sliders"></i>
                    </button>
                    <div style="left: -93px !important" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <button *ngIf="d.type != userType.Nurse " class="dropdown-item" (click)="ChangeUserType(d.status,d.id)">Change Type to {{ d.type ==
                        userType.Admin ? 'User' : 'Admin' }}</button>
                      <button class="dropdown-item" (click)="ChangeUserStatus(d.status,d.id)">Change Status to {{
                        d.status == userStatus.Active? 'Disabled' : 'Active' }}</button>
                      <button class="dropdown-item" (click)="DeleteUser(i,d.id)">Delete User</button>
                      <button *ngIf="auth.isSuperAdminUser" class="dropdown-item" (click)="CopyPassword(i,d.id)">Copy
                        Password</button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #elseBlock>
              <tr>
                <td colspan="10"></td>
              </tr>
              <tr>
                <td colspan="10" class="text-center">No Record Found......</td>
              </tr>
              <tr>
                <td colspan="10"></td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
